import { useCallback, useEffect, useState } from "react";
import { getRoutersPackageById } from "../../api/routerDevice";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";

export default function ProfileSelect({ value, onChange, error, routerId, disabled, ...rest }) {
  const { profiles, statusFetch } = useProfileRouter({ routerId });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={statusFetch === "loading" || disabled}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value="">-- Pilih Profile --</option>
        {profiles.map((profile, index) => (
          <option key={index} value={profile}>
            {profile}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}

function useProfileRouter({ routerId }) {
  const [profiles, setProfiles] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRouterPackage = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRoutersPackageById(id);
      setProfiles(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (routerId) {
      fetchRouterPackage(routerId);
    }
  }, [routerId, fetchRouterPackage]);

  return {
    profiles,
    errorMsg,
    statusFetch,
  };
}
