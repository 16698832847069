import { useState } from "react";
import { Modal } from "react-bootstrap";
import { deleteConnectionVpnById } from "../../api/vpn";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";

export default function ModalDeleteVpn({ show, vpn, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleClose = () => {
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const deleteVpn = async () => {
    setLoading(true);
    try {
      let { data } = await deleteConnectionVpnById(vpn.id);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus VPN {vpn?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <p>Apakah Anda yakin menghapus wilayah "{vpn?.name}"?</p>
        <button className="btn btn-danger" disabled={loading} onClick={() => deleteVpn()}>
          Ya, Saya Yakin
        </button>
        <button className="btn btn-light" onClick={() => onClose()}>
          Batalkan
        </button>
      </Modal.Body>
    </Modal>
  );
}
