import { apiAuth } from "./base";

export function getRoles(params) {
  let api = apiAuth();
  return api.get("v1/roles", { params });
}

export function createRole(data) {
  let api = apiAuth();
  return api.post("v1/roles", data);
}

export function getRoleById(id) {
  let api = apiAuth();
  return api.get(`v1/roles/${id}`);
}

export function updateRoleById(id, data) {
  let api = apiAuth();
  return api.put(`v1/roles/${id}`, data);
}

export function deleteRoleById(id) {
  let api = apiAuth();
  return api.delete(`v1/roles/${id}`);
}

export function createUser(data) {
  let api = apiAuth();
  return api.post("v1/users", data);
}

export function getUsers(params) {
  let api = apiAuth();
  return api.get("v1/users", { params });
}

export function getUserById(id) {
  let api = apiAuth();
  return api.get(`v1/users/${id}`);
}

export function updateUserById(id, data) {
  let api = apiAuth();
  return api.put(`v1/users/${id}`, data);
}

export function deleteUserById(id) {
  let api = apiAuth();
  return api.delete(`v1/users/${id}`);
}
