import { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { useAuth } from "./AuthHooks";
import { getPermissionMenu } from "../api/permission";

export function usePermittedMenu() {
  const [permitted, setPermitted] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = useAuth();

  const getMenus = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getPermissionMenu({ ...filter });

      let permitTemp = {};

      data.data.forEach((menu) => {
        permitTemp[menu.key] = true;
      });

      setPermitted({ ...permitTemp });
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getMenus();
  // }, [])

  useEffect(() => {
    if (isAuthenticated === true) {
      getMenus();
    }

    if (isAuthenticated === false) {
      setPermitted({});
    }
  }, [isAuthenticated]);

  return {
    permitted,
    loading,
    errorMsg,
    getMenus,
  };
}

const permittedMenuContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvidePermittedMenu({ children }) {
  const menu = usePermittedMenu();
  return <permittedMenuContext.Provider value={menu}>{children}</permittedMenuContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const usePermittedMenuContext = () => {
  return useContext(permittedMenuContext);
};
