import moment from "moment";
import { useState } from "react";
import { syncLicence } from "../../api/dashboard";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { useAuth } from "../../utils/AuthHooks";
import AlertBox from "../AlertBox";

export default function AlertLicenseExpired() {
  const { user, loadUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const onSyncLicence = async () => {
    setLoading(true);
    try {
      await syncLicence();
      loadUser();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <div className="card border-left-danger">
      <div className="card-header text-danger font-weight-bold">
        <i className="fa fa-triangle-exclamation"></i> Perhatian
      </div>
      <div className="card-body">
        Harap segera selesaikan pembayaran anda. Akses dashboard akan ditangguhkan sementara.
        Lisensi dashboard Anda berakhir pada tanggal{" "}
        <b>
          {user.tenant?.licenceEndDate
            ? moment.unix(user.tenant?.licenceEndDate).format("DD MMMM YYYY")
            : "-"}
        </b>
        .
        <AlertBox {...alert} setAlert={setAlert} />
        {user.role?.superuser ? (
          <>
            <table className="table table-bordered mt-2">
              <tbody>
                <tr>
                  <td>No. Pelanggan</td>
                  <td>{user.tenant.accountNumber}</td>
                </tr>
                <tr>
                  <td>Tanggal Akhir Lisensi</td>
                  <td>
                    {user.tenant.licenceEndDate
                      ? moment.unix(user.tenant.licenceEndDate).format("DD MMMM YYYY")
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-center py-2" style={{ gap: "10px" }}>
              <button
                className="btn btn-primary"
                disabled={loading}
                onClick={() => onSyncLicence()}
              >
                <i className="fa fa-rotate"></i>{" "}
                {loading ? "Menyinkronasi Status Lisensi" : "Sinkronasi Status Lisensi"}
              </button>{" "}
              {/* <span>Pengecekan terakhir: 17 September 2023</span> */}
            </div>
          </>
        ) : (
          <p>Silahkan hubungi administrator anda</p>
        )}
      </div>
    </div>
  );
}
