import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useRolesHooks } from "../../hooks/employee/RoleHooks";
import FormNewPermission from "../../components/role/FormNewPermission";
import FormUpdateRolePermission from "../../components/role/FormUpdateRolePermission";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function RoleManagePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const [selected, setSelected] = useState(null);
  const { roles, statusFetch, fetchRoles } = useRolesHooks();
  const [mode, setMode] = useState(null); // null, create-new, detail-update

  const onSuccessUpdate = (message) => {
    setAlert({ show: true, message, type: "success" });
    fetchRoles();
  };

  const onSuccess = (message) => {
    setMode(null);
    setAlert({ show: true, message, type: "success" });
    fetchRoles();
  };

  const renderMode = () => {
    switch (mode) {
      case "create-new":
        return <FormNewPermission onSuccessCreate={onSuccess} />;
      case "detail-update":
        return (
          <FormUpdateRolePermission
            id={selected}
            onSuccessDelete={onSuccess}
            onSuccessUpdate={onSuccessUpdate}
          />
        );
      default:
        return <div className="p-4 text-center">Silahkan pilih salah satu jabatan</div>;
    }
  };

  return (
    <MainLayout permission_key={"role:permission"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola Jabatan dan Hak akses</h1>
            </div>
          </div>
        </div>

        <Box title="Jabatan dan Hak Akses" loading={statusFetch === "loading"}>
          <AlertBox {...alert} setAlert={setAlert} />

          <h4 className="mb-3">Jabatan</h4>
          <div className="row">
            <div className="col-12 col-md-3 pb-4">
              <div className="list-group">
                {roles.map((role) => (
                  <button
                    key={role.id}
                    className={`list-group-item list-group-item-action ${
                      selected === role.id ? "active" : ""
                    }`}
                    type="button"
                    onClick={() => {
                      setSelected(role.id);
                      setMode("detail-update");
                    }}
                  >
                    {role.name}{" "}
                    {role.superuser && <span className="badge badge-success">Superuser</span>}
                  </button>
                ))}
              </div>
              <hr />
              {allowedByPermission("create") && (
                <button
                  className="btn btn-sm btn-block btn-primary"
                  onClick={() => {
                    setSelected(null);
                    setMode("create-new");
                  }}
                >
                  <i className="fa fa-plus"></i> Tambah Jabatan
                </button>
              )}
            </div>
            <div className="col-12 col-md-9">{renderMode()}</div>
          </div>
        </Box>
      </div>
    </MainLayout>
  );
}
