import MainLayout from "../components/layouts/MainLayout";
import { useCustomersStatsHook } from "../hooks/DashboardHooks";
import BoxPreCustomers from "../components/home/BoxPreCustomers";
import BoxFinanceSummary from "../components/home/BoxFinanceSummary";
import ReminderExpiredLicence from "../components/system/ReminderExpiredLicence";

export default function HomePage() {
  return (
    <MainLayout permission_key={"dashboard"}>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
        <ReminderExpiredLicence />
        <BoxStatsCustomer />
        <div className="row">
          <div className="col-lg-8 col-12">
            <BoxFinanceSummary />
          </div>
          <div className="col-lg-8 col-12">
            <BoxPreCustomers />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

function BoxStatsCustomer() {
  const { statistic, errorMsg } = useCustomersStatsHook();
  return (
    <div className="row my-4">
      <div className="col-12">
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <div className="card border-left-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Total Pelanggan
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {statistic.totalCustomer}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-user-group fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <div className="card border-left-success shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                  Pelanggan Aktif
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {statistic.activeCustomer}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-user-check fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <div className="card border-left-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                  Pelanggan Isolir
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {statistic.inactiveCustomer}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-user-clock fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <div className="card border-left-danger shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                  Pelanggan Belum Aktif
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {statistic.preCustomer}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-user-slash fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
