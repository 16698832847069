export const convertToRupiah = (angka) => {
  var rupiah = "";
  var angkarev = angka.toString().split("").reverse().join("");
  for (var i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + ".";
  return rupiah
    .split("", rupiah.length - 1)
    .reverse()
    .join("");
};

export function convertSpeedWithUnit(speedKbps) {
  if (speedKbps >= 1000000) {
    return (speedKbps / 1000000).toFixed(2) + " Gbps";
  } else if (speedKbps >= 1000) {
    return (speedKbps / 1000).toFixed(2) + " Mbps";
  } else {
    return speedKbps + " kbps";
  }
}
