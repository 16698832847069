import { useCallback, useEffect, useState } from "react";
import { getUserById, getUsers } from "../../api/account";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";

export function useEmployeeHooks({ filter }) {
  const [employees, setEmployees] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchEmployees = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getUsers({ ...filter });
      setStatusFetch("success");
      setEmployees(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchEmployees(filter);
  }, [filter, fetchEmployees]);

  return {
    employees,
    meta,
    statusFetch,
    errorMsg,
    fetchEmployees,
  };
}

export function useEmployeeByIdHooks({ id }) {
  const [employee, setEmployee] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchEmployees = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getUserById(id);
      setEmployee(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchEmployees(id);
    }
  }, [id, fetchEmployees]);

  return {
    employee,
    statusFetch,
    errorMsg,
    fetchEmployees,
  };
}
