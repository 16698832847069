export const BillingStatusConstant = {
  paid: "Sudah Bayar",
  unpaid: "Belum Bayar",
  cancelled: "Dibatalkan",
};

export const PaymentMethodConstant = {
  cash: "Tunai",
  transfer: "Transfer Bank",
};

export const AddOnsTypeConstant = {
  additional: "Tambahan Biaya",
  discount: "Diskon Biaya",
};
