import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { useInternetPackageByIdHooks } from "../../hooks/internetPackage/InternetPackageHooks";
import { deleteInternetPackageById } from "../../api/internetPackage";

export default function ModalDeleteInternetPackage({ show, id, onSuccess, onClose }) {
  const { internetPackage, statusFetch, errorMsg } = useInternetPackageByIdHooks({ id });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleClose = () => {
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const deleteInternetPackage = async () => {
    setLoading(true);
    try {
      let { data } = await deleteInternetPackageById(id);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Paket Internet {internetPackage.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <AlertBox {...alert} setAlert={setAlert} />
        <p>Apakah Anda yakin menghapus paket internet "{internetPackage.name}"?</p>
        <button
          className="btn btn-danger"
          disabled={loading || statusFetch === "loading"}
          onClick={() => deleteInternetPackage()}
        >
          Ya, Saya Yakin
        </button>
        <button className="btn btn-light" onClick={() => onClose()}>
          Batalkan
        </button>
      </Modal.Body>
    </Modal>
  );
}
