import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createInternetPackage } from "../../api/internetPackage";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { InputText, RequiredAsterisk } from "../FormHelper";
import RouterSelect from "../odp/RouterSelect";
import ProfileSelect from "./ProfileSelect";

export default function ModalCreateInternetPackage({ show, onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({ name: "", price: 0, profile: "", period: 1, routerId: "" });
  const [errorForm, setErrorForm] = useState({
    name: "",
    price: "",
    period: "",
    profile: "",
    routerId: "",
  });

  const handleClose = () => {
    setForm({ name: "", price: 0, profile: "", period: 1, routerId: "" });
    setErrorForm({ name: "", price: "", period: "", profile: "", routerId: "" });
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const onSubmitNewInternetPackage = (event) => {
    event.preventDefault();
    submitInternetPackage(form);
  };

  const submitInternetPackage = async (form) => {
    setLoading(true);
    try {
      let { data } = await createInternetPackage(form);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Paket Internet Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={onSubmitNewInternetPackage}>
          <div className="form-group">
            <label>
              Nama Paket Internet <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="form-group">
            <label>
              Harga Paket Internet <RequiredAsterisk />
            </label>
            <InputText
              type="number"
              required
              value={form.price}
              onChange={(event) => setForm({ ...form, price: event.target.value })}
              error={errorForm.price}
            />
          </div>
          <div className="form-group">
            <label>
              Router <RequiredAsterisk />
            </label>
            <RouterSelect
              value={form.routerId}
              onChange={(value) => setForm({ ...form, profile: "", routerId: value })}
              error={errorForm.routerId}
            />
          </div>
          <div className="form-group">
            <label>
              Profile <RequiredAsterisk />
            </label>
            <ProfileSelect
              value={form.profile}
              routerId={form.routerId}
              onChange={(value) => setForm({ ...form, profile: value })}
              error={errorForm.profile}
            />
          </div>
          <div className="form-group">
            <label>
              Periode Layanan
              <RequiredAsterisk />
            </label>
            <div className="row">
              <div className="col-auto">
                <InputText
                  type="number"
                  required
                  min={1}
                  value={form.period}
                  onChange={(event) => setForm({ ...form, period: event.target.value })}
                  error={errorForm.period}
                />
              </div>
              <div className="col d-flex align-items-center">Hari</div>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Membuat paket baru ..." : "Buat Paket Internet Baru"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
