import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createRouter } from "../../api/routerDevice";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { InputSelectWrapper, InputText, RequiredAsterisk } from "../FormHelper";
import Toggle from "react-toggle";
import VpnSelect from "../vpn/VpnSelect";
import { RouterOsVersionConstant } from "../../constant/RouterConstant";

export default function ModalCreateRouter({ show, onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [usingVpn, setUsingVpn] = useState(false);
  const [form, setForm] = useState({
    name: "",
    ip: "",
    vpnId: "",
    username: "",
    password: "",
    port: "",
    osVersion: "",
  });
  const [errorForm, setErrorForm] = useState({
    name: "",
    ip: "",
    vpnId: "",
    username: "",
    password: "",
    port: "",
    osVersion: "",
  });

  const handleClose = () => {
    setForm({ name: "", ip: "", vpnId: "", username: "", password: "", port: "" });
    setErrorForm({ name: "", ip: "", vpnId: "", username: "", password: "", port: "" });
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const toggleVpn = (event) => {
    if (event.target.checked) {
      setForm({ ...form, ip: "" });
    } else {
      setForm({ ...form, vpnId: "" });
    }
    setUsingVpn(event.target.checked);
  };

  const onSubmitNewRouter = (event) => {
    event.preventDefault();
    submitRouter(form);
  };

  const submitRouter = async (form) => {
    setLoading(true);
    try {
      let { data } = await createRouter(form);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Tambah Router Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={onSubmitNewRouter}>
          <div className="form-group">
            <label>
              Nama Router <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="form-group d-flex items-center">
            <label>Gunakan IP VPN</label>
            <div className="ml-2">
              <Toggle checked={usingVpn} onChange={(event) => toggleVpn(event)} />
            </div>
          </div>
          {!usingVpn ? (
            <div className="form-group">
              <label>
                IP Publik Router <RequiredAsterisk />
              </label>
              <InputText
                type="text"
                required
                value={form.ip}
                onChange={(event) => setForm({ ...form, ip: event.target.value })}
                error={errorForm.ip}
              />
            </div>
          ) : (
            <div className="form-group">
              <label>
                IP VPN <RequiredAsterisk />
              </label>
              <VpnSelect
                value={form.vpnId}
                onChange={(value) => setForm({ ...form, vpnId: value })}
              />
            </div>
          )}
          <div className="form-group">
            <label>
              Username Mikrotik <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.username}
              onChange={(event) => setForm({ ...form, username: event.target.value })}
              error={errorForm.username}
            />
          </div>
          <div className="form-group">
            <label>
              Password Mikrotik
              <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.password}
              onChange={(event) => setForm({ ...form, password: event.target.value })}
              error={errorForm.password}
            />
          </div>
          <div className="form-group">
            <label>
              Port Mikrotik <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.port}
              onChange={(event) => setForm({ ...form, port: event.target.value })}
              error={errorForm.port}
            />
          </div>
          <div className="form-group">
            <label>
              Mikrotik RouterOS Version <RequiredAsterisk />
            </label>
            <InputSelectWrapper
              type="text"
              required
              value={form.osVersion}
              onChange={(event) => setForm({ ...form, osVersion: event.target.value })}
              error={errorForm.osVersion}
            >
              <option value="">-- Pilih Versi Router OS --</option>
              <option value=">=7.10">{RouterOsVersionConstant[">=7.10"]}</option>
              <option value="<7.10">{RouterOsVersionConstant["<7.10"]}</option>
            </InputSelectWrapper>
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Membuat router baru ..." : "Buat Router Baru"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
