import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
// import underconstruction from "../assets/images/under-construction.png";

function UnderConstrutionPage() {
  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="text-center">
          {/* <img
            src={underconstruction}
            className="img-fluid"
            alt="under construction"
            style={{ width: "100%", maxWidth: "350px" }}
          /> */}
          <p className="lead text-gray-800 mb-5">Halaman belum tersedia</p>
          <Link to="/" className="btn btn-primary">
            Kembali ke Dashboard
          </Link>
        </div>
      </div>
    </MainLayout>
  );
}

export default UnderConstrutionPage;
