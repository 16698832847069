import { apiAuth } from "./base";

export function getInternetPackages(params) {
  let api = apiAuth();
  return api.get("v1/packages", { params });
}

export function createInternetPackage(data) {
  let api = apiAuth();
  return api.post("v1/packages", data);
}

export function getInternetPackageById(id) {
  let api = apiAuth();
  return api.get(`v1/packages/${id}`);
}

export function updateInternetPackageById(id, data) {
  let api = apiAuth();
  return api.put(`v1/packages/${id}`, data);
}

export function deleteInternetPackageById(id) {
  let api = apiAuth();
  return api.delete(`v1/packages/${id}`);
}
