import { useState, useEffect } from "react";
import { getTenant } from "../api/user";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";

export function useTenantHook() {
  const [tenant, setTenant] = useState({
    id: null,
    companyName: null,
    companyAddress: null,
    companyPhone: null,
    companyEmail: null,
    companyWebsite: null,
    companyCity: null,
    companyLogo: null,
    accountNumber: null,
  });
  const [statusFetch, setStatusFetch] = useState(null); // loading, fetched, error
  const [error, setErrorMsg] = useState("");

  const fetchTenant = async () => {
    setStatusFetch("loading");
    try {
      let { data } = await getTenant();
      setTenant(data.data);
      setStatusFetch("success");
    } catch (error) {
      setStatusFetch("failed");
      if (error.response) {
        if (error.response?.data.message) {
          setErrorMsg(error.response?.data?.message);
        }
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
    }
  };

  useEffect(() => {
    fetchTenant();
  }, []);

  return {
    tenant,
    statusFetch,
    fetchTenant,
    error,
  };
}
