import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { InputText, RequiredAsterisk } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useODPByIdHooks } from "../../hooks/odp/OdpHooks";
import { updateODPById } from "../../api/odp";
import RouterSelect from "./RouterSelect";
import MapSelector from "./MapSelector";

export default function ModalUpdateOdp({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <FormUpdateODP onSuccess={onSuccess} onClose={onClose} id={id} />
    </Modal>
  );
}

function FormUpdateODP({ id, onSuccess, onClose }) {
  const { odp, statusFetch } = useODPByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    capacity: "",
    routerId: "",
    latitude: "",
    longitude: "",
  });
  const [errorForm, setErrorForm] = useState({
    name: "",
    capacity: "",
    routerId: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        name: odp.name,
        capacity: odp.capacity,
        routerId: odp.router?.id || "",
        latitude: odp.latitude,
        longitude: odp.longitude,
      }));
    }
  }, [odp, statusFetch]);

  const selectLocation = (marker) => {
    setForm({ ...form, latitude: marker.lat, longitude: marker.lng });
  };

  const onSubmitUpdateOdp = (event) => {
    event.preventDefault();
    submitUpdate(form);
  };

  const submitUpdate = async (form) => {
    setLoading(true);
    try {
      let { data } = await updateODPById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Data ODP {odp.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitUpdateOdp}>
          <AlertBox {...alert} setAlert={setAlert} />
          {statusFetch === "loading" && <div className="alert alert-warning">Loading...</div>}
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>
                  Nama ODP <RequiredAsterisk />
                </label>
                <InputText
                  type="text"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  error={errorForm.name}
                />
              </div>
              <div className="form-group">
                <label>
                  Kapasitas <RequiredAsterisk />
                </label>
                <InputText
                  type="text"
                  required
                  value={form.capacity}
                  onChange={(event) => setForm({ ...form, capacity: event.target.value })}
                  error={errorForm.capacity}
                />
              </div>
              <div className="form-group">
                <label>
                  Router <RequiredAsterisk />
                </label>
                <RouterSelect
                  value={form.routerId}
                  onChange={(value) => setForm({ ...form, routerId: value })}
                  error={errorForm.routerId}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>Latitude</label>
                <InputText
                  type="text"
                  value={form.latitude}
                  disabled
                  // onChange={(event) => setForm({ ...form, latitude: event.target.value })}
                  error={errorForm.latitude}
                />
              </div>
              <div className="form-group">
                <label>Longitude</label>
                <InputText
                  type="text"
                  value={form.longitude}
                  disabled
                  // onChange={(event) => setForm({ ...form, longitude: event.target.value })}
                  error={errorForm.longitude}
                />
              </div>
              {statusFetch === "success" && (
                <div className="form-group">
                  <MapSelector
                    latitude={form.latitude}
                    longitude={form.longitude}
                    center={
                      form.latitude && form.longitude ? [form.latitude, form.longitude] : null
                    }
                    selectLocation={(value) => selectLocation(value)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Menyimpan perubahan ..." : "Simpan Perubahan ODP"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
