import { useState } from "react";
import { Link } from "react-router-dom";
import { InputPassword, InputText, RequiredAsterisk } from "../components/FormHelper";
import { register } from "../api/base";
import { ClientErrorResponseMessage, ErrorResponse } from "../constant/ErrorResponseConstants";
import AlertBox from "../components/AlertBox";

function RegisterPage() {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    accountNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    retypePassword: "",
    phoneNumber: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: "",
    companyCity: "",
  });

  const [errorForm, setErrorForm] = useState({
    accountNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    retypePassword: "",
    phoneNumber: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: "",
    companyCity: "",
  });

  const resetForm = () => {
    setForm({
      accountNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      retypePassword: "",
      phoneNumber: "",
      companyName: "",
      companyPhone: "",
      companyEmail: "",
      companyWebsite: "",
      companyAddress: "",
      companyCity: "",
    });
    setErrorForm({
      accountNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      retypePassword: "",
      phoneNumber: "",
      companyName: "",
      companyPhone: "",
      companyEmail: "",
      companyWebsite: "",
      companyAddress: "",
      companyCity: "",
    });
  };

  const formRegisterSubmit = (event) => {
    event.preventDefault();

    let error = false;
    let errorTemp = {};

    if (form.password.length < 6) {
      error = true;
      errorTemp.password = "password must 6 character length";
    }

    if (form.password !== form.retypePassword) {
      error = true;
      errorTemp.retypePassword = "password and retype password not match";
    }

    if (error) {
      setErrorForm({ ...errorTemp });
    } else {
      const { retypePassword, ...formData } = form;
      submitRegistration(formData);
    }
  };

  const submitRegistration = async (form) => {
    setLoading(true);
    try {
      let { data } = await register(form);
      setAlert({ show: true, type: "success", message: data.message });
      resetForm();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <div
      className="login-page-wrapper bg-gradient-custom-purple"
      style={{ minHeight: "100vh", padding: "100px 0" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card o-hidden border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      {/* <img src={image} className="mb-3" style={{maxWidth: "200px"}}/> */}
                      <h1 className="h3 text-gray-900 mb-4">Registrasi Akun Dashboard</h1>
                    </div>
                  </div>
                  <div className="col-12">
                    <AlertBox {...alert} setAlert={setAlert} />
                    <form className="user" onSubmit={(event) => formRegisterSubmit(event)}>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <h5>Data Akun</h5>
                          <hr />
                          <div className="form-group">
                            <label>
                              Nomor Pelanggan <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.accountNumber}
                              onChange={(event) =>
                                setForm({ ...form, accountNumber: event.target.value })
                              }
                              error={errorForm.accountNumber}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Nama Depan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.firstName}
                              onChange={(event) =>
                                setForm({ ...form, firstName: event.target.value })
                              }
                              error={errorForm.firstName}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Nama Belakang
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.lastName}
                              onChange={(event) =>
                                setForm({ ...form, lastName: event.target.value })
                              }
                              error={errorForm.lastName}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Nomor Handphone
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.phoneNumber}
                              onChange={(event) =>
                                setForm({ ...form, phoneNumber: event.target.value })
                              }
                              error={errorForm.phoneNumber}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Email
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="email"
                              required
                              name="email"
                              value={form.email}
                              onChange={(event) => setForm({ ...form, email: event.target.value })}
                              error={errorForm.email}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Username
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              name="username"
                              value={form.username}
                              onChange={(event) =>
                                setForm({ ...form, username: event.target.value })
                              }
                              error={errorForm.username}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Password
                              <RequiredAsterisk />
                            </label>
                            <InputPassword
                              required
                              value={form.password}
                              onChange={(event) =>
                                setForm({ ...form, password: event.target.value })
                              }
                              error={errorForm.password}
                              autoComplete="new-password"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Ulang Password
                              <RequiredAsterisk />
                            </label>
                            <InputPassword
                              required
                              value={form.retypePassword}
                              onChange={(event) =>
                                setForm({ ...form, retypePassword: event.target.value })
                              }
                              error={errorForm.retypePassword}
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <h5>Data Perusahaan</h5>
                          <hr />
                          <div className="form-group">
                            <label>
                              Nama Perusahaan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.companyName}
                              onChange={(event) =>
                                setForm({ ...form, companyName: event.target.value })
                              }
                              error={errorForm.companyName}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Nomor Telepon Perusahaan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.companyPhone}
                              onChange={(event) =>
                                setForm({ ...form, companyPhone: event.target.value })
                              }
                              error={errorForm.companyPhone}
                            />
                          </div>
                          <div className="form-group">
                            <label>Website Perusahaan</label>
                            <InputText
                              type="text"
                              value={form.companyWebsite}
                              onChange={(event) =>
                                setForm({ ...form, companyWebsite: event.target.value })
                              }
                              error={errorForm.companyWebsite}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Alamat Email Perusahaan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="email"
                              required
                              value={form.companyEmail}
                              onChange={(event) =>
                                setForm({ ...form, companyEmail: event.target.value })
                              }
                              error={errorForm.companyEmail}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Alamat Perusahaan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.companyAddress}
                              onChange={(event) =>
                                setForm({ ...form, companyAddress: event.target.value })
                              }
                              error={errorForm.companyAddress}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Kota Perusahaan
                              <RequiredAsterisk />
                            </label>
                            <InputText
                              type="text"
                              required
                              value={form.companyCity}
                              onChange={(event) =>
                                setForm({ ...form, companyCity: event.target.value })
                              }
                              error={errorForm.companyCity}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                          disabled={loading}
                        >
                          {loading ? "Mendaftarkan akun ..." : "Daftar"}
                        </button>
                      </div>
                    </form>
                    <div className="text-muted text-center py-3">
                      Sudah punya akun? <Link to="/login">Masuk</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
