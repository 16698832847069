import { apiAuth } from "./base";

export function getRouters(params) {
  let api = apiAuth();
  return api.get("v1/routers", { params });
}

export function createRouter(data) {
  let api = apiAuth();
  return api.post("v1/routers", data);
}

export function getRouterById(id) {
  let api = apiAuth();
  return api.get(`v1/routers/${id}`);
}

export function getRoutersPackageById(id) {
  let api = apiAuth();
  return api.get(`v1/routers/${id}/profiles`);
}

export function getRouterInterfacesById(id) {
  let api = apiAuth();
  return api.get(`v1/routers/${id}/interfaces`);
}

export function getRouterInterfacesTrafficById(id, params) {
  let api = apiAuth();
  return api.get(`v1/routers/${id}/traffic`, { params });
}

export function getRouterSecretsById(id, params) {
  let api = apiAuth();
  return api.get(`v1/routers/${id}/secrets`, { params });
}

export function updateRouterById(id, data) {
  let api = apiAuth();
  return api.put(`v1/routers/${id}`, data);
}

export function updateConnectionRouterById(id) {
  let api = apiAuth();
  return api.put(`v1/routers/${id}/connection-status`);
}

export function deleteRouterById(id) {
  let api = apiAuth();
  return api.delete(`v1/routers/${id}`);
}
