import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalCreateRegion from "../../components/region/ModalCreateRegion";
import ModalDeleteRegion from "../../components/region/ModalDeleteRegion";
import ModalUpdateRegion from "../../components/region/ModalUpdateRegion";
import { useRegionHooks } from "../../hooks/region/RegionHooks";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function RegionPage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { regions, statusFetch, meta, errorMsg } = useRegionHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  return (
    <MainLayout permission_key={"region"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola Wilayah</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-area")}>
                  <i className="fa fa-plus"></i> Tambah Wilayah
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Wilayah" loading={statusFetch === "loading"}>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Wilayah</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {regions.length === 0 && (
                  <tr>
                    <td colSpan={2} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {regions.map((region) => (
                  <tr key={region.id}>
                    <td>{region.name}</td>
                    <td>
                      {allowedByPermission("update") && (
                        <button
                          className="btn btn-info mx-2"
                          onClick={() => selectModal("update-area", region.id)}
                        >
                          <i className="fa fa-pencil"></i> Ubah
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-danger"
                          onClick={() => selectModal("delete-area", region.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {meta.count > meta.limit && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.limit)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>

          <ModalCreateRegion
            show={modal === "new-area"}
            onSuccess={onSuccess}
            onClose={() => setModal(null)}
          />
          <ModalUpdateRegion
            show={modal === "update-area"}
            onSuccess={onSuccess}
            id={modal === "update-area" ? selectedId : null}
            onClose={() => setModal(null)}
          />
          <ModalDeleteRegion
            show={modal === "delete-area"}
            onSuccess={onSuccess}
            id={modal === "delete-area" ? selectedId : null}
            onClose={() => setModal(null)}
          />
        </Box>
      </div>
    </MainLayout>
  );
}
