export const ErrorResponse = {
  BadRequestError: "BadRequestError",
  ValidationError: "ValidationError",
  InternalServerError: "internalServerError",
  InactiveAccountError: "InactiveAccountError",
  AuthenticationError: "AuthenticationError",
};

export const ClientErrorResponseMessage = {
  SomethingWrong: "Terjadi kesalahan/gangguan",
  InactiveAccountError: "Akun anda sedang tidak aktif",
  NotFoundAccountError: "Akun anda tidak ditemukan",
};
