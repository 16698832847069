import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { InputText, RequiredAsterisk } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { createBank } from "../../api/bank";

export default function ModalCreateBank({ show, onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({ name: "", accountNumber: "", accountName: "" });
  const [errorForm, setErrorForm] = useState({ name: "", accountNumber: "", accountName: "" });

  const handleClose = () => {
    setForm({ name: "", accountNumber: "", accountName: "" });
    setErrorForm({ name: "", accountNumber: "", accountName: "" });
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const onSubmitBank = (event) => {
    event.preventDefault();
    submitBank(form);
  };

  const submitBank = async (form) => {
    setLoading(true);
    try {
      let { data } = await createBank(form);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Rekening Bank Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={onSubmitBank}>
          <div className="form-group">
            <label>
              Nama Bank <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="form-group">
            <label>
              Nomor Rekening Bank <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.accountNumber}
              onChange={(event) => setForm({ ...form, accountNumber: event.target.value })}
              error={errorForm.accountNumber}
            />
          </div>
          <div className="form-group">
            <label>
              Nama Rekening Bank <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.accountName}
              onChange={(event) => setForm({ ...form, accountName: event.target.value })}
              error={errorForm.accountName}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Membuat rekening baru ..." : "Buat Rekening Baru"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
