import { useRouterSecretsByIdHooks } from "../../hooks/router/RouterDeviceHooks";

// TODO: create component for Secret selector
export default function RouterSecretSelect({
  routerId,
  profileRouter,
  selectSecret,
  value,
  ...rest
}) {
  const { routerSecrets, statusFetch } = useRouterSecretsByIdHooks({
    id: routerId,
    profile: profileRouter,
  });

  const onChangeForm = (name) => {
    let selected = routerSecrets.find((arr) => arr.name === name);
    selectSecret({ ...selected });
  };

  return (
    <select
      className="form-control"
      value={value}
      onChange={(event) => onChangeForm(event.target.value)}
      disabled={statusFetch === "loading"}
      {...rest}
    >
      <option value="">-- Pilih Secrets --</option>
      {routerSecrets.map((secret, index) => (
        <option value={secret.name} key={index}>
          {secret.name}
        </option>
      ))}
    </select>
  );
}
