import React, { useEffect } from "react";
import { menu } from "../../menu";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import { useActionControl } from "../../utils/ActionControlUtils";
import { useAuth } from "../../utils/AuthHooks";
import AlertLicenseExpired from "../system/AlertExpiredLicense";

export default function MainLayout({ permission_key, children }) {
  const { permission, fetchPermission } = useActionControl();
  const { user } = useAuth();

  useEffect(() => {
    fetchPermission(permission_key);
    //  on Mount layout, each page mounting new layout
  }, [fetchPermission, permission_key]);

  // this costumize from ActionControlUtils,
  // because there is a state context not resetting after change page
  const allowRenderChild = () => {
    if (user.role?.superuser) {
      return true;
    }
    return permission[permission_key + ":read"] || null;
  };

  return (
    <div id="wrapper">
      <Sidebar menu={menu} />
      <div id="content-wrapper" className="d-flex flex-column">
        <div className="content">
          <Navbar />
        </div>
        {!user.tenant?.licenceExpired ? (
          <>
            {allowRenderChild() === null ? null : allowRenderChild() ? (
              children
            ) : (
              <div className="container-fluid">
                <div className="card border-bottom-danger shadow mb-2">
                  <div className="card-body">Anda tidak diijinkan mengakses halaman ini</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="container-fluid">
            <AlertLicenseExpired />
          </div>
        )}
      </div>
    </div>
  );
}
