import { useRouterDeviceHooks } from "../../hooks/router/RouterDeviceHooks";

const filter = { showAll: true };

export default function RouterSelect({ value, onChange, error, ...rest }) {
  const { routers } = useRouterDeviceHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value="">-- Pilih Router --</option>
        {routers.map((router) => (
          <option key={router.id} value={router.id}>
            {router.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
