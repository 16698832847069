import { useState, useEffect, useCallback } from "react";
import { getPermissionSchema } from "../api/permission";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";

export function usePermissionSchemaHooks() {
  const [schemas, setSchemas] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchSchema = useCallback(async () => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getPermissionSchema();
      setStatusFetch("success");
      setSchemas(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("failure");
    }
  }, []);

  useEffect(() => {
    fetchSchema();
  }, [fetchSchema]);

  return {
    schemas,
    meta,
    statusFetch,
    errorMsg,
    fetchSchema,
  };
}
