import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Toggle from "react-toggle";
import { updateUserById } from "../../api/account";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useEmployeeByIdHooks } from "../../hooks/employee/EmployeeHooks";
import AlertBox from "../AlertBox";
import { InputPassword, InputText, RequiredAsterisk } from "../FormHelper";
import RoleSelect from "./RoleSelect";

export default function ModalUpdateEmployee({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Update Akun Karyawan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormUpdateEmployee onSuccess={onSuccess} onClose={onClose} id={id} />
      </Modal.Body>
    </Modal>
  );
}

function FormUpdateEmployee({ id, onSuccess, onClose }) {
  const { employee, statusFetch } = useEmployeeByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    roleId: "",
    phoneNumber: "",
    active: false,
  });

  const [errorForm, setErrorForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    roleId: "",
    phoneNumber: "",
    active: "",
  });

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        username: employee.username,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        phoneNumber: employee.phoneNumber,
        roleId: employee.role.id,
        active: employee.active,
      }));
    }
  }, [employee, statusFetch]);

  const onSubmitUpdateEmployee = (event) => {
    event.preventDefault();
    submitUpdate(form);
  };

  const submitUpdate = async (form) => {
    setLoading(true);
    try {
      let { data } = await updateUserById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitUpdateEmployee}>
      <AlertBox {...alert} setAlert={setAlert} />
      {statusFetch === "loading" && <div className="alert alert-warning">Loading...</div>}
      <div className="form-group">
        <label>
          Username <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.username}
          onChange={(event) => setForm({ ...form, username: event.target.value })}
          error={errorForm.username}
        />
      </div>
      <div className="form-group">
        <label>
          Nama Depan
          <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.firstName}
          onChange={(event) => setForm({ ...form, firstName: event.target.value })}
          error={errorForm.firstName}
        />
      </div>
      <div className="form-group">
        <label>
          Nama Belakang
          <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.lastName}
          onChange={(event) => setForm({ ...form, lastName: event.target.value })}
          error={errorForm.lastName}
        />
      </div>
      <div className="form-group">
        <label>
          Nomor Handphone
          <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.phoneNumber}
          onChange={(event) => setForm({ ...form, phoneNumber: event.target.value })}
          error={errorForm.phoneNumber}
        />
      </div>
      <div className="form-group">
        <label>
          Jabatan
          <RequiredAsterisk />
        </label>
        <RoleSelect
          required
          value={form.roleId}
          onChange={(value) => setForm({ ...form, roleId: value })}
          error={errorForm.roleId}
        />
      </div>
      <div className="form-group">
        <label>
          Email <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.email}
          onChange={(event) => setForm({ ...form, email: event.target.value })}
          error={errorForm.email}
        />
      </div>
      <div className="form-group">
        <label className="d-block">Aktif</label>
        <Toggle
          checked={form.active}
          onChange={(event) => setForm({ ...form, active: event.target.checked })}
        />
      </div>
      <div className="form-group">
        <label>Password (hanya untuk ganti password, selain itu abaikan)</label>
        <InputPassword
          value={form.password}
          onChange={(event) => setForm({ ...form, password: event.target.value })}
          error={errorForm.password}
          autoComplete="new-password"
        />
      </div>
      <div className="form-group">
        <button className="btn btn-block btn-primary" disabled={loading}>
          Update Karyawan
        </button>
      </div>
    </form>
  );
}
