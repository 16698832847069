import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateCustomerById } from "../../api/customer";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { InputPassword, InputSelectWrapper, InputText, RequiredAsterisk } from "../FormHelper";
import InternetPackageSelect from "../internetPackages/InternetPackageSelect";
import OdpSelect from "../odp/OdpSelect";
import RegionSelect from "../region/RegionSelect";
import { useCustomerByIdHooks } from "../../hooks/customer/CustomerHooks";
import moment from "moment";
import RouterSecretSelect from "../router/RouterSecretSelect";

export default function ModalUpdateCustomer({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <FormUpdateCustomer id={id} onSuccess={onSuccess} onClose={onClose} />
    </Modal>
  );
}

function FormUpdateCustomer({ id, onSuccess, onClose }) {
  const { customer, statusFetch, errorMsg } = useCustomerByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    phoneNumber: "",
    regionId: "",
    packageId: "",
    odpId: "",
    installationStatus: true,
    installationDate: "",
    address: "",
    routerId: "",
    profileRouter: "",
    useExistingSecrets: false,
    pppoeIpType: "",
    pppoeUsername: "",
    pppoePassword: "",
    pppoeLocalAddress: "",
    pppoeRemoteAddress: "",
  });

  const [errorForm, setErrorForm] = useState({
    name: "",
    phoneNumber: "",
    regionId: "",
    packageId: "",
    odpId: "",
    installationStatus: "",
    installationDate: "",
    address: "",
    useExistingSecrets: "",
    pppoeIpType: "",
    pppoeUsername: "",
    pppoePassword: "",
    pppoeLocalAddress: "",
    pppoeRemoteAddress: "",
  });

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        regionId: customer.region?.id || "",
        packageId: customer.package?.id || "",
        odpId: customer.odp?.id || "",
        installationStatus: customer.installationStatus,
        installationDate: customer.installationDate
          ? moment.unix(customer.installationDate).format("YYYY-MM-DD")
          : "",
        address: customer.address,
        routerId: customer.package?.router?.id || "",
        profileRouter: customer.package?.profile || "",
        useExistingSecrets: !!customer.pppoeUsername,
        pppoeIpType: customer.pppoeIpType || "",
        pppoeUsername: customer.pppoeUsername || "",
        pppoePassword: customer.pppoePassword || "",
        pppoeLocalAddress: customer.pppoeLocalAddress || "",
        pppoeRemoteAddress: customer.pppoeRemoteAddress || "",
      }));
    }
  }, [customer, statusFetch]);

  const onChangePPPSecretsType = (value) => {
    let formData = { ...form };

    // use existing
    formData.useExistingSecrets = value;
    // reset data ppoe
    formData.pppoeUsername = "";
    formData.pppoePassword = "";
    formData.pppoeIpType = "";
    formData.pppoeLocalAddress = "";
    formData.pppoeRemoteAddress = "";

    setForm({ ...formData });
  };

  const onChangeSecrets = (value = {}) => {
    let secretData = {
      pppoeUsername: value.name,
      pppoePassword: value.password,
      pppoeIpType: "",
      pppoeLocalAddress: "",
      pppoeRemoteAddress: "",
    };

    if (value["remote-address"] && value["local-address"]) {
      secretData.pppoeIpType = "remote address";
      secretData.pppoeLocalAddress = value["local-address"];
      secretData.pppoeRemoteAddress = value["remote-address"];
    } else {
      secretData.pppoeIpType = "ip pools";
    }

    setForm({ ...form, ...secretData });
  };

  const onSubmitUpdateCustomer = (event) => {
    event.preventDefault();

    let formData = {
      name: form.name,
      phoneNumber: form.phoneNumber,
      regionId: form.regionId,
      packageId: form.packageId,
      odpId: form.odpId,
      installationStatus: form.installationStatus,
      installationDate: form.installationDate,
      address: form.address,
      useExistingSecrets: form.useExistingSecrets,
      pppoeIpType: form.pppoeIpType,
      pppoeUsername: form.pppoeUsername,
      pppoePassword: form.pppoePassword,
      pppoeLocalAddress: form.pppoeLocalAddress,
      pppoeRemoteAddress: form.pppoeRemoteAddress,
    };

    updateCustomer(formData);
  };

  const updateCustomer = async (form) => {
    setLoading(true);
    try {
      let { data } = await updateCustomerById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Data Pelanggan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {statusFetch === "loading" && <div className="alert alert-warning">Loading ...</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <form onSubmit={onSubmitUpdateCustomer}>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>
              Nama Pelanggan <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Alamat</label>
                <InputText
                  type="text"
                  value={form.address}
                  onChange={(event) => setForm({ ...form, address: event.target.value })}
                  error={errorForm.address}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  Wilayah <RequiredAsterisk />
                </label>
                <RegionSelect
                  value={form.regionId}
                  onChange={(value) => setForm({ ...form, regionId: value })}
                  error={errorForm.regionId}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  No. Telepon <RequiredAsterisk />
                </label>
                <InputText
                  type="text"
                  required
                  value={form.phoneNumber}
                  onChange={(event) => setForm({ ...form, phoneNumber: event.target.value })}
                  error={errorForm.phoneNumber}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  Paket <RequiredAsterisk />
                </label>
                <InternetPackageSelect
                  value={form.packageId}
                  disabled
                  error={errorForm.packageId}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>ODP</label>
                <OdpSelect
                  value={form.odpId}
                  onChange={(value) => setForm({ ...form, odpId: value })}
                  error={errorForm.odpId}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  Status Pemasangan <RequiredAsterisk />
                </label>
                <InputSelectWrapper
                  type="text"
                  required
                  value={form.installationStatus}
                  disabled={customer.installationStatus}
                  onChange={(event) =>
                    setForm({ ...form, installationStatus: event.target.value === "true" })
                  }
                  error={errorForm.installationStatus}
                >
                  <option value="false">Belum Pasang</option>
                  <option value="true">Sudah Pasang</option>
                </InputSelectWrapper>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  Tanggal Pemasangan <RequiredAsterisk />
                </label>
                <InputText
                  type="date"
                  // required
                  value={form.installationDate}
                  disabled={!form.installationStatus || customer.installationStatus}
                  onChange={(event) => setForm({ ...form, installationDate: event.target.value })}
                  error={errorForm.installationDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>PPP Secrets</label>
                <InputSelectWrapper
                  className="form-control"
                  value={form.useExistingSecrets}
                  onChange={(event) => onChangePPPSecretsType(event.target.value === "true")}
                  error={errorForm.useExistingSecrets}
                >
                  <option value="false">Buat Secrets Baru</option>
                  <option value="true">Ambil dari Router</option>
                </InputSelectWrapper>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Username</label>
                {form.useExistingSecrets ? (
                  <RouterSecretSelect
                    value={form.pppoeUsername}
                    selectSecret={(value) => onChangeSecrets(value)}
                    routerId={form.routerId}
                    profileRouter={form.profileRouter}
                  />
                ) : (
                  <InputText
                    type="text"
                    className="form-control"
                    value={form.pppoeUsername}
                    onChange={(event) => setForm({ ...form, pppoeUsername: event.target.value })}
                    error={errorForm.pppoeUsername}
                    disabled={form.useExistingSecrets}
                    autoComplete="off"
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Password</label>
                <InputPassword
                  className="form-control"
                  value={form.pppoePassword}
                  onChange={(event) => setForm({ ...form, pppoePassword: event.target.value })}
                  error={errorForm.pppoePassword}
                  disabled={form.useExistingSecrets}
                  autoComplete="new-password"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Tipe IP</label>
                <InputSelectWrapper
                  className="form-control"
                  value={form.pppoeIpType}
                  onChange={(event) => setForm({ ...form, pppoeIpType: event.target.value })}
                  disabled={form.useExistingSecrets}
                  error={errorForm.pppoeIpType}
                >
                  <option value="">-- Pilih Tipe IP --</option>
                  <option value="ip pools">IP Pools</option>
                  <option value="remote address">Remote Address</option>
                </InputSelectWrapper>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Local Address</label>
                <InputText
                  type="text"
                  value={form.pppoeLocalAddress}
                  onChange={(event) => setForm({ ...form, pppoeLocalAddress: event.target.value })}
                  error={errorForm.pppoeLocalAddress}
                  disabled={form.pppoeIpType === "ip pools" || form.useExistingSecrets}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Remote Address</label>
                <InputText
                  type="text"
                  value={form.pppoeRemoteAddress}
                  onChange={(event) => setForm({ ...form, pppoeRemoteAddress: event.target.value })}
                  error={errorForm.pppoeRemoteAddress}
                  disabled={form.pppoeIpType === "ip pools" || form.useExistingSecrets}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-lg btn-primary" disabled={loading}>
              {loading ? "Menyimpan perubahan pelanggan ..." : "Update Pelanggan"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
