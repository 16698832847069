import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFoundPage extends Component {
  render() {
    return (
      <div
        className="login-page-wrapper bg-gradient-dark"
        style={{ minHeight: "100vh", padding: "100px 0" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card o-hidden border-0 shadow-lg">
                <div className="card-body p-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-5">
                        {/* <img src={image} className="mb-5" style={{maxWidth: "200px"}}/> */}
                        <h1 className="h1 text-gray-900 mb-2">404</h1>
                        <h3 className="h5 text-gray-900 mb-4">Not Found</h3>
                        <Link to="/" className="btn btn-primary">
                          Kembali ke Dashboard
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
