import { useCallback, useEffect, useState } from "react";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { getTicketById, getTickets } from "../../api/ticket";

export function useTicketsHooks({ filter }) {
  const [tickets, setTickets] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchTickets = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getTickets({ ...filter });
      setStatusFetch("success");
      setTickets(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchTickets(filter);
  }, [filter, fetchTickets]);

  return {
    tickets,
    meta,
    statusFetch,
    errorMsg,
    fetchTickets,
  };
}

export function useTicketByIdHooks({ id }) {
  const [ticket, setTicket] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchTicket = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getTicketById(id);
      setTicket(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchTicket(id);
    }
  }, [id, fetchTicket]);

  return {
    ticket,
    statusFetch,
    errorMsg,
    fetchTicket,
  };
}
