import { useState } from "react";
import Box from "../components/Box";
import MainLayout from "../components/layouts/MainLayout";
import { useAuditLogsHook } from "../hooks/AccessLogHooks";
import Pagination from "../components/Pagination";
import moment from "moment";

export default function AccessLogPage() {
  const [filter, setFilter] = useState({ page: 1, limit: 30 });

  const { logs, meta, loading } = useAuditLogsHook({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };
  return (
    <MainLayout permission_key={"log"}>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Access Log</h1>
        </div>
        <Box title="Log" loading={loading}>
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-sm table-striped">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>IP Address</th>
                  <th>User</th>
                  <th>Event Type</th>
                  <th>Event Description</th>
                  <th>UserAgent</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((eventLog) => (
                  <tr key={eventLog.id}>
                    <td className="text-nowrap">
                      {moment.unix(eventLog.createdAt).format("DD MMM YYYY - HH:mm:ss")}
                    </td>
                    <td>{eventLog.ipAddress}</td>
                    <td>{eventLog.user?.username || "-"}</td>
                    <td>{eventLog.eventType}</td>
                    <td>{eventLog.eventDescription}</td>
                    <td>{eventLog.userAgent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>
    </MainLayout>
  );
}
