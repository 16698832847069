import { useCallback, useEffect, useState } from "react";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { getBillingAddOnById, getBillingAddOns } from "../../api/finance";

export function useBillingAddOnsHooks({ filter }) {
  const [addOns, setAddOns] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchBillingAddOns = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getBillingAddOns({ ...filter });
      setStatusFetch("success");
      setAddOns(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchBillingAddOns(filter);
  }, [filter, fetchBillingAddOns]);

  return {
    addOns,
    meta,
    statusFetch,
    errorMsg,
    fetchBillingAddOns,
  };
}

export function useBillingAddOnByIdHooks({ id }) {
  const [addOn, setAddOn] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchBillingAddOnById = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getBillingAddOnById(id);
      setAddOn(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchBillingAddOnById(id);
    }
  }, [id, fetchBillingAddOnById]);

  return {
    addOn,
    statusFetch,
    errorMsg,
    fetchBillingAddOnById,
  };
}
