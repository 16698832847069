import AccessLogPage from "./pages/AccessLogPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import UnderConstrutionPage from "./pages/UnderConstructionPage";
import BankManagePage from "./pages/bank/BankManagePage";
import CustomerManagePage from "./pages/customer/CustomerManagePage";
import EmployeeManagePage from "./pages/employee/EmployeeManagePage";
import RoleManagePage from "./pages/employee/RoleManagePage";
import AdditionalDiscountPage from "./pages/finance/AdditionalDiscountPage";
import BillingPage from "./pages/finance/BillingPage";
import InternetPackagePage from "./pages/internetPackage/InternetPackagePage";
import OdpManagePage from "./pages/odp/OdpManagePage";
import OdpMapPage from "./pages/odp/OdpMapPage";
import RegionPage from "./pages/region/RegionPage";
import RouterDevicePage from "./pages/router/RouterDevicePage";
import RouterTrafficPage from "./pages/router/RouterTrafficPage";
import SettingPage from "./pages/settings/SettingPage";
import SettingProfilePage from "./pages/settings/SettingProfilePage";
import TicketManagePage from "./pages/ticket/TicketManagePage";
import VpnManagePage from "./pages/vpn/VpnManagePage";

export const router = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "Login",
    component: RegisterPage,
  },
  {
    path: "/home",
    name: "Home",
    type: "private",
    component: HomePage,
  },
  {
    path: "/customers",
    name: "customer",
    type: "private",
    component: CustomerManagePage,
  },
  {
    path: "/router/:id/traffic",
    name: "Router",
    type: "private",
    component: RouterTrafficPage,
  },
  {
    path: "/router",
    name: "Router",
    type: "private",
    component: RouterDevicePage,
  },
  {
    path: "/vpn",
    name: "VPN",
    type: "private",
    component: VpnManagePage,
  },
  {
    path: "/odp/map",
    name: "ODP",
    type: "private",
    component: OdpMapPage,
  },
  {
    path: "/odp",
    name: "ODP",
    type: "private",
    component: OdpManagePage,
  },
  {
    path: "/region",
    name: "Wilayah",
    type: "private",
    component: RegionPage,
  },
  {
    path: "/internet-package",
    name: "Paket Internet",
    type: "private",
    component: InternetPackagePage,
  },
  {
    path: "/ticket",
    name: "Tiket Help",
    type: "private",
    component: TicketManagePage,
  },
  {
    path: "/additional-and-discount",
    name: "Biaya Tambahan dan Diskon",
    type: "private",
    component: AdditionalDiscountPage,
  },
  {
    path: "/finance/billing",
    name: "Billing",
    type: "private",
    component: BillingPage,
  },
  {
    path: "/finance/mutation",
    name: "Mutasi Rekening",
    type: "private",
    component: UnderConstrutionPage,
  },
  {
    path: "/employee/manage",
    name: "Karyawan",
    type: "private",
    component: EmployeeManagePage,
  },
  {
    path: "/employee/roles",
    name: "Jabatan dan Hak Akses",
    type: "private",
    component: RoleManagePage,
  },
  {
    path: "/banks",
    name: "Rekening Bank",
    type: "private",
    component: BankManagePage,
  },
  {
    path: "/settings/rbac",
    name: "Setting Akses Kontrol",
    type: "private",
    component: UnderConstrutionPage,
  },
  {
    path: "/settings/profile",
    name: "Setting Profile",
    type: "private",
    component: SettingProfilePage,
  },
  {
    path: "/settings",
    name: "Setting",
    type: "private",
    component: SettingPage,
  },
  {
    path: "/access-log",
    name: "Setting",
    type: "private",
    component: AccessLogPage,
  },
];
