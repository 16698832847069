import { useEffect, useState } from "react";
import { updateAccountPassword, updateProfile } from "../../api/user";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import { InputPassword, InputText, RequiredAsterisk } from "../../components/FormHelper";
import MainLayout from "../../components/layouts/MainLayout";
import { ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useProfileHook } from "../../hooks/ProfileHooks";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function SettingProfilePage() {
  const [tab, setTab] = useState("profile");
  return (
    <MainLayout permission_key={"profile:setting"}>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan Profil</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-8">
            <Box title="Profil Akun">
              <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                  <button
                    className={`nav-link ${tab === "profile" && "active"}`}
                    onClick={() => setTab("profile")}
                  >
                    Profil
                  </button>
                </li>
                <li className="nav-item" onClick={() => setTab("password")}>
                  <button
                    className={`nav-link ${tab === "password" && "active"}`}
                    onClick={() => setTab("password")}
                  >
                    Password
                  </button>
                </li>
              </ul>
              {tab === "profile" ? <TabProfileUpdate /> : null}
              {tab === "password" ? <TabChangePassword /> : null}
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

function TabProfileUpdate() {
  const { allowedByPermission } = useActionControl();
  const { profile, statusFetch } = useProfileHook();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [form, setForm] = useState({ firstName: "", lastName: "", email: "" });
  const [errorForm, setErrorForm] = useState({ firstName: "", lastName: "", email: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
      }));
    }
  }, [profile, statusFetch]);

  const onSubmitProfile = (event) => {
    event.preventDefault();

    let formData = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
    };

    submitProfile(formData);
  };

  const resetForm = () => {
    setErrorForm({ firstName: "", lastName: "", email: "" });
  };

  const submitProfile = async (form) => {
    setLoading(true);
    try {
      let response = await updateProfile(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      resetForm();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={(event) => onSubmitProfile(event)}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>Username</label>
        <input type="text" value={profile.username} disabled={true} className="form-control" />
      </div>
      <div className="form-group">
        <label>Nama Depan</label>
        <InputText
          type="text"
          required
          value={form.firstName}
          onChange={(event) => setForm({ ...form, firstName: event.target.value })}
          error={errorForm.firstName}
        />
      </div>
      <div className="form-group">
        <label>Nama Belakang</label>
        <InputText
          type="text"
          required
          value={form.lastName}
          onChange={(event) => setForm({ ...form, lastName: event.target.value })}
          error={errorForm.lastName}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <InputText
          type="text"
          required
          value={form.email}
          onChange={(event) => setForm({ ...form, email: event.target.value })}
          error={errorForm.email}
        />
      </div>
      <div className="form-group">
        {allowedByPermission("update") && (
          <button className="btn btn-block btn-primary" disabled={loading}>
            Simpan
          </button>
        )}
      </div>
    </form>
  );
}

function TabChangePassword() {
  const { allowedByPermission } = useActionControl();

  const [form, setForm] = useState({ password: "", retypePassword: "", oldPassword: "" });
  const [errorForm, setErrorForm] = useState({
    password: "",
    retypePassword: "",
    oldPassword: "",
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setForm({ password: "", retypePassword: "", oldPassword: "" });
    setErrorForm({ password: "", retypePassword: "", oldPassword: "" });
  };

  const onSubmitPasswordChange = (event) => {
    event.preventDefault();

    let error = false;
    let errorTemp = {};

    if (form.password.length < 6) {
      error = true;
      errorTemp.password = "password must 6 character length";
    }

    if (form.password !== form.retypePassword) {
      error = true;
      errorTemp.retypePassword = "password and retype password not match";
    }

    if (error) {
      setErrorForm({ ...errorTemp });
    } else {
      let formData = {
        password: form.password,
        oldPassword: form.oldPassword,
      };

      submitChangePassword(formData);
    }
  };

  const submitChangePassword = async (form) => {
    setLoading(true);

    try {
      let response = await updateAccountPassword(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      resetForm();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={(event) => onSubmitPasswordChange(event)}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>
          Password Lama <RequiredAsterisk />
        </label>
        <InputPassword
          value={form.oldPassword}
          error={errorForm.oldPassword}
          onChange={(event) => setForm({ ...form, oldPassword: event.target.value })}
        />
      </div>

      <p className="text-sm text-muted">Password harus memiliki 6 character.</p>
      <div className="form-group">
        <label>
          Password Baru <RequiredAsterisk />
        </label>
        <InputPassword
          value={form.password}
          error={errorForm.password}
          onChange={(event) => setForm({ ...form, password: event.target.value })}
        />
      </div>
      <div className="form-group">
        <label>
          Ulangi Password Baru <RequiredAsterisk />
        </label>
        <InputPassword
          value={form.retypePassword}
          error={errorForm.retypePassword}
          onChange={(event) => setForm({ ...form, retypePassword: event.target.value })}
        />
      </div>
      <div className="form-group">
        {allowedByPermission("update") && (
          <button className="btn btn-block btn-primary" disabled={loading}>
            Ubah Password
          </button>
        )}
      </div>
    </form>
  );
}
