import { apiAuth } from "./base";

export function getAllVpn(params) {
  let api = apiAuth();
  return api.get("v1/vpn", { params });
}

export function createVpn(data) {
  let api = apiAuth();
  return api.post("v1/vpn", data);
}

export function updateConnectionVpn(id) {
  let api = apiAuth();
  return api.put(`v1/vpn/${id}/connection-status`);
}

export function deleteConnectionVpnById(id) {
  let api = apiAuth();
  return api.delete(`v1/vpn/${id}`);
}
