import { useState } from "react";
import { updateConnectionVpn } from "../../api/vpn";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";

export default function ButtonUpdateConnection({ id, onSuccess, onFailure }) {
  const [loading, setLoading] = useState(false);

  const onUpdateConn = async () => {
    setLoading(true);
    try {
      let { data } = await updateConnectionVpn(id);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      onFailure(message);
    }

    setLoading(false);
  };

  return (
    <button
      className="btn btn-block text-nowrap btn-custom-purple"
      onClick={() => onUpdateConn()}
      disabled={loading}
    >
      <i className="fa fa-refresh"></i> {loading ? "Memperbarui ..." : "Perbarui Koneksi"}
    </button>
  );
}
