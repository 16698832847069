import { useState } from "react";
import moment from "moment";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ButtonUpdateConnection from "../../components/vpn/ButtonUpdateConnection";
import ModalCreateVpn from "../../components/vpn/ModalCreateVpn";
import ModalScriptVpn from "../../components/vpn/ModalScriptVpn";
import { useVpnListHooks } from "../../hooks/vpn/VpnHooks";
import { useActionControl } from "../../utils/ActionControlUtils";
import ModalDeleteVpn from "../../components/vpn/ModalDeleteVpn";

export default function VpnManagePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const { vpnList, statusFetch, meta, errorMsg } = useVpnListHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModalData = (modalName, data) => {
    setModal(modalName);
    setSelected(data);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const onFailure = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "danger", message });
  };

  return (
    <MainLayout permission_key={"vpn"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola VPN</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("create-vpn")}>
                  <i className="fa fa-plus"></i> Tambah VPN
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="alert alert-info">
          <b>
            <i className="fa fa-circle-info"></i>
          </b>{" "}
          Status koneksi diperbarui secara otomatis dan berkala (tiap 5 menit).
        </div>
        <Box title="Daftar VPN" loading={statusFetch === "loading"}>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nama VPN</th>
                  <th>Alamat IP</th>
                  <th>Status</th>
                  <th>Pengecekan Terakhir</th>
                  <th>Script Koneksi</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vpnList.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {vpnList.map((vpn) => (
                  <tr key={vpn.id}>
                    <td className="text-nowrap">{vpn.name}</td>
                    <td>{vpn.ipAddress || "-"}</td>
                    <td>
                      {vpn.status ? (
                        <span className="badge badge-success">Terhubung</span>
                      ) : (
                        <span className="badge badge-danger">Tidak Terhubung</span>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {vpn.lastCheckConn
                        ? moment.unix(vpn.lastCheckConn).format("DD MMM YYYY HH:mm:ss")
                        : "-"}
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-custom-orange btn-block text-nowrap"
                        onClick={() => selectModalData("script-vpn", vpn)}
                      >
                        <i className="fa fa-terminal"></i> Script Mikrotik
                      </button>
                    </td>
                    <td>
                      {allowedByPermission("update") && (
                        <ButtonUpdateConnection
                          id={vpn.id}
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                        />
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-block btn-danger"
                          onClick={() => selectModalData("delete-vpn", vpn)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {meta.count > meta.limit && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.limit)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalCreateVpn
        show={modal === "create-vpn"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />

      <ModalScriptVpn
        show={modal === "script-vpn"}
        scriptMikrotik={selected.script}
        onClose={() => setModal(null)}
      />

      <ModalDeleteVpn
        show={modal === "delete-vpn"}
        id={modal === "delete-vpn"}
        vpn={selected}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}
