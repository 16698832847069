import { useCallback, useEffect, useState } from "react";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { getCustomerAddonById, getCustomerById, getCustomers } from "../../api/customer";

export function useCustomersHooks({ filter }) {
  const [customers, setCustomers] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchCustomers = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getCustomers({ ...filter });
      setStatusFetch("success");
      setCustomers(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchCustomers(filter);
  }, [filter, fetchCustomers]);

  return {
    customers,
    meta,
    statusFetch,
    errorMsg,
    fetchCustomers,
  };
}

export function useCustomerByIdHooks({ id }) {
  const [customer, setCustomer] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchCustomer = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getCustomerById(id);
      setCustomer(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchCustomer(id);
    }
  }, [id, fetchCustomer]);

  return {
    customer,
    statusFetch,
    errorMsg,
    fetchCustomer,
  };
}

export function useCustomerAddonByIdHooks({ id }) {
  const [customer, setCustomer] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchCustomer = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getCustomerAddonById(id);
      setCustomer(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchCustomer(id);
    }
  }, [id, fetchCustomer]);

  return {
    customer,
    statusFetch,
    errorMsg,
    fetchCustomer,
  };
}
