import { MapContainer, Marker, Popup, TileLayer, useMapEvents, useMap } from "react-leaflet";

const position = [-6.2, 106.816666];

export default function MapSelector({ latitude, longitude, selectLocation, center }) {
  return (
    <MapContainer center={center ? center : position} zoom={10}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {center ? <ChangeMapView center={center} /> : null}
      <AddMarkerToClick latitude={latitude} longitude={longitude} selectLocation={selectLocation} />
    </MapContainer>
  );
}

function ChangeMapView({ center }) {
  const map = useMap();
  map.setView(center, map.getZoom());

  return null;
}

function AddMarkerToClick({ latitude, longitude, selectLocation }) {
  // eslint-disable-next-line no-unused-vars,
  const map = useMapEvents({
    click(e) {
      const newMarker = e.latlng;
      selectLocation(newMarker);
    },
  });

  return (
    <>
      {latitude && longitude && (
        <Marker position={[latitude, longitude]}>
          <Popup>
            Marker is at {latitude} {longitude}
          </Popup>
        </Marker>
      )}
    </>
  );
}
