import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import NotFoundPage from "./pages/NotFound";
import { router } from "./router";
import { ProvideAuth, useAuth } from "./utils/AuthHooks";
import { ProvidePermittedMenu } from "./utils/DynamicMenuUtils";
import { ActionControlProvider } from "./utils/ActionControlUtils";

function App() {
  return (
    <ProvideAuth>
      <ProvidePermittedMenu>
        <ActionControlProvider>
          <Router>
            <Switch>
              {router.map((route, index) => {
                switch (route.type) {
                  case "private":
                    return (
                      // <Route path={route.path} component={route.component} key={index} />
                      <PrivateRoute key={index} path={route.path} component={route.component} />
                    );
                  default:
                    return <Route path={route.path} component={route.component} key={index} />;
                }
              })}
              <Route exact path="/">
                {!localStorage.getItem("token") ? (
                  <Redirect to="/login" />
                ) : (
                  <Redirect to="/home" />
                )}
              </Route>
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </ActionControlProvider>
      </ProvidePermittedMenu>
    </ProvideAuth>
  );
}

// Auth Protected Page
function PrivateRoute({ component: Component, ...rest }) {
  let { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated !== false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

// // RBAC Route Page
// function ProtectedRoute({ children }) {
//   return children;
// }

export default App;
