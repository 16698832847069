import { useState, useEffect, useCallback } from "react";
import { getCustomersStats, getFinancialSummary, getPreCustomersSummary } from "../api/dashboard";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";

export function useCustomersStatsHook() {
  const [statistic, setStatistics] = useState({
    totalCustomer: 0,
    activeCustomer: 0,
    inactiveCustomer: 0,
    preCustomer: 0,
  });
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchStats = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getCustomersStats();
      setStatusFetch("success");
      setStatistics(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  return {
    statistic,
    meta,
    statusFetch,
    errorMsg,
    fetchStats,
  };
}

export function usePreCustomersStatsHook({ filter }) {
  const [customers, setCustomers] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchStats = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getPreCustomersSummary({ ...filter });
      setStatusFetch("success");
      setCustomers(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchStats(filter);
  }, [filter, fetchStats]);

  return {
    customers,
    meta,
    statusFetch,
    errorMsg,
    fetchStats,
  };
}

export function useFinancialSummaryHooks() {
  const [data, setData] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchFinancialSummary = useCallback(async () => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getFinancialSummary();
      setStatusFetch("success");
      setData(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchFinancialSummary();
  }, [fetchFinancialSummary]);

  return {
    data,
    statusFetch,
    errorMsg,
    fetchFinancialSummary,
  };
}
