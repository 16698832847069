import { useState } from "react";
import { downloadCustomer } from "../../api/customer";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalCreateCustomer from "../../components/customer/ModalCreateCustomer";
import ModalDeleteCustomer from "../../components/customer/ModalDeleteCustomer";
import ModalDetailCustomer from "../../components/customer/ModalDetailCustomer";
import ModalUpdateCustomer from "../../components/customer/ModalUpdateCustomer";
import InternetPackageSelect from "../../components/internetPackages/InternetPackageSelect";
import MainLayout from "../../components/layouts/MainLayout";
import OdpSelect from "../../components/odp/OdpSelect";
import RegionSelect from "../../components/region/RegionSelect";
import { useCustomersHooks } from "../../hooks/customer/CustomerHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { useActionControl } from "../../utils/ActionControlUtils";
import moment from "moment";
import { ServiceStatusConstant } from "../../constant/CustomerConstant";

function CustomerManagePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [formFilter, setFormFilter] = useState({
    q: "",
    regionId: "",
    packageId: "",
    odpId: "",
    activationStatus: "",
    serviceStatus: "",
  });
  const [filter, setFilter] = useState({
    q: "",
    regionId: "",
    packageId: "",
    odpId: "",
    activationStatus: "",
    serviceStatus: "",
    page: 1,
    limit: 10,
  });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { customers, statusFetch, meta, errorMsg } = useCustomersHooks({ filter });

  const applyFilter = (event) => {
    event.preventDefault();

    setFilter({ ...filter, ...formFilter });
  };

  const resetFilter = () => {
    setFormFilter({
      q: "",
      regionId: "",
      packageId: "",
      odpId: "",
      activationStatus: "",
      serviceStatus: "",
    });
    setFilter({
      q: "",
      regionId: "",
      packageId: "",
      odpId: "",
      activationStatus: "",
      serviceStatus: "",
      page: 1,
      limit: 10,
    });
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const onFailure = (message) => {
    setAlert({ show: true, type: "danger", message });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout permission_key={"customer"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Pelanggan</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-customer")}>
                  <i className="fa fa-plus"></i> Tambah Pelanggan Baru
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Data Pelanggan" loading={statusFetch === "loading"}>
          <form onSubmit={applyFilter}>
            <div className="row">
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama / No. Pelanggan / Alamat / No. Telepon"
                    value={formFilter.q}
                    onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Wilayah</label>
                  <RegionSelect
                    value={formFilter.regionId}
                    onChange={(value) => setFormFilter({ ...formFilter, regionId: value })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>ODP</label>
                  <OdpSelect
                    value={formFilter.odpId}
                    onChange={(value) => setFormFilter({ ...formFilter, odpId: value })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Paket</label>
                  <InternetPackageSelect
                    value={formFilter.packageId}
                    onChange={(value) => setFormFilter({ ...formFilter, packageId: value })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Status Aktivasi</label>
                  <select
                    className="form-control"
                    value={formFilter.activationStatus}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, activationStatus: event.target.value })
                    }
                  >
                    <option value="">-- Pilih Status --</option>
                    <option value="true">Aktif</option>
                    <option value="false">Belum Aktif</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Status Layanan</label>
                  <select
                    className="form-control"
                    value={formFilter.serviceStatus}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, serviceStatus: event.target.value })
                    }
                  >
                    <option value="">-- Pilih Status --</option>
                    {Object.keys(ServiceStatusConstant).map((key) => (
                      <option value={key} key={key}>
                        {ServiceStatusConstant[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12">
                <div className="form-group" style={{ marginTop: "0px" }}>
                  <button type="submit" className="btn btn-primary mr-2">
                    Filter
                  </button>
                  <DownloadByFilter
                    filter={formFilter}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                  />
                  <button type="button" className="btn btn-light" onClick={() => resetFilter()}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Nomor Pelanggan</th>
                  <th>Nama Pelanggan</th>
                  <th>Telp</th>
                  <th>Alamat</th>
                  <th>Wilayah</th>
                  <th>ODP</th>
                  <th>Paket</th>
                  <th>Periode Layanan</th>
                  <th>Harga Paket</th>
                  <th>Status Aktivasi</th>
                  <th>Status Layanan</th>
                </tr>
              </thead>
              <tbody>
                {customers.length === 0 && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>
                      {allowedByPermission("read") && (
                        <button
                          className="btn btn-sm btn-block btn-primary text-nowrap"
                          onClick={() => selectModal("detail-customer", customer.id)}
                        >
                          <i className="fa fa-eye"></i> Detail
                        </button>
                      )}
                      {/* <button className="btn btn-sm btn-block btn-warning text-nowrap">
                        <i className="fa fa-ticket"></i> Buat Tiket
                      </button> */}
                      {allowedByPermission("update") && (
                        <button
                          className="btn btn-sm btn-block btn-info text-nowrap"
                          onClick={() => selectModal("update-customer", customer.id)}
                        >
                          <i className="fa fa-pencil"></i> Ubah Data
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-sm btn-block btn-danger text-nowrap"
                          onClick={() => selectModal("delete-customer", customer.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                    <td>{customer.customerNumber}</td>
                    <td className="text-nowrap">{customer.name}</td>
                    <td>{customer.phoneNumber}</td>
                    <td>{customer.address || "-"}</td>
                    <td className="text-nowrap">{customer.region?.name || "-"}</td>
                    <td className="text-nowrap">{customer.odp?.name || "-"}</td>
                    <td className="text-nowrap">{customer.package?.name || "-"}</td>
                    <td className="text-nowrap">
                      {customer.planStartDate && customer.planEndDate ? (
                        <>
                          {moment.unix(customer.planStartDate).format("DD MMM YYYY")} -{" "}
                          {moment.unix(customer.planEndDate).format("DD MMM YYYY")}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-nowrap">
                      Rp {convertToRupiah(customer.package?.price || 0)}
                    </td>
                    <td>
                      {customer.activationStatus ? (
                        <span className="badge badge-success">Aktif</span>
                      ) : (
                        <span className="badge badge-danger">Belum Aktif</span>
                      )}
                    </td>
                    <td>{ServiceStatusConstant[customer.serviceStatus] || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDetailCustomer
        show={modal === "detail-customer"}
        onSuccess={onSuccess}
        id={modal === "detail-customer" ? selectedId : null}
        onClose={() => setModal(null)}
      />
      <ModalCreateCustomer
        show={modal === "new-customer"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />
      <ModalUpdateCustomer
        show={modal === "update-customer"}
        onSuccess={onSuccess}
        id={modal === "update-customer" ? selectedId : null}
        onClose={() => setModal(null)}
      />
      <ModalDeleteCustomer
        show={modal === "delete-customer"}
        onSuccess={onSuccess}
        id={modal === "delete-customer" ? selectedId : null}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}

function DownloadByFilter({ filter, onSuccess, onFailure }) {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadCustomer({ ...filter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Pelanggan.xlsx");
      document.body.appendChild(link);
      link.click();
      onSuccess("Berhasil mengunduh Data Pelanggan.xlsx");
      // handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.message) {
        message = error.message;
      }

      onFailure(message);
    }
    setLoading(false);
  };

  return (
    <button
      type="button"
      disabled={loading}
      className="btn btn-custom-orange mr-2"
      onClick={downloadFile}
    >
      {loading ? "Mengunduh data ..." : "Download Data"}
    </button>
  );
}
export default CustomerManagePage;
