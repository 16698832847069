import { apiAuth, apiDownloadAuth } from "./base";

export function getBillings(params) {
  let api = apiAuth();
  return api.get("v1/billings", { params });
}

export function getBillingById(id) {
  let api = apiAuth();
  return api.get(`v1/billings/${id}`);
}

export function createBilling(data) {
  let api = apiAuth();
  return api.post("v1/billings", data);
}

export function confirmBillingById(id, data) {
  let api = apiAuth();
  return api.put(`v1/billings/${id}/confirm`, data);
}

export function downloadInvoiceByBillingId(id) {
  let api = apiDownloadAuth();
  return api.get(`v1/billings/${id}/invoice`);
}

export function downloadBillings(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v1/billings/exportTo/xlsx", { params });
}

export function getBillingAddOns(params) {
  let api = apiAuth();
  return api.get("v1/billing-addons", { params });
}

export function createBillingAddOn(data) {
  let api = apiAuth();
  return api.post("v1/billing-addons", data);
}

export function getBillingAddOnById(id) {
  let api = apiAuth();
  return api.get(`v1/billing-addons/${id}`);
}

export function deleteBillingAddOnById(id) {
  let api = apiAuth();
  return api.delete(`v1/billing-addons/${id}`);
}
