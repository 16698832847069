import moment from "moment";
import { Modal } from "react-bootstrap";
import { useCustomerByIdHooks } from "../../hooks/customer/CustomerHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { ServiceStatusConstant } from "../../constant/CustomerConstant";

export default function ModalDetailCustomer({ show, id, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Detail Pelanggan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailCustomer id={id} />
      </Modal.Body>
    </Modal>
  );
}

function DetailCustomer({ id }) {
  const { customer, statusFetch, errorMsg } = useCustomerByIdHooks({ id });
  return (
    <div className="row">
      <div className="col-12">
        {statusFetch === "loading" && <div className="alert alert-warning">Loading ...</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      </div>
      <div className="col-12 col-lg-6">
        <h4>Pelanggan</h4>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>No. Pelanggan</td>
              <td>{customer.customerNumber}</td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>{customer.name}</td>
            </tr>
            <tr>
              <td>No. Telepon</td>
              <td>{customer.phoneNumber}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>{customer.address || "-"}</td>
            </tr>
            <tr>
              <td>Wilayah</td>
              <td>{customer.region?.name || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-lg-6">
        <h4>Detail Paket</h4>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Paket</td>
              <td>{customer.package?.name || "-"}</td>
            </tr>
            <tr>
              <td>Harga Paket</td>
              <td>
                Rp {customer.package?.price ? convertToRupiah(customer.package?.price || 0) : "-"}
              </td>
            </tr>
            <tr>
              <td>ODP</td>
              <td>{customer.odp?.name || "-"}</td>
            </tr>
            <tr>
              <td>Status Pemasangan</td>
              <td>{customer.installationStatus ? "Sudah Terpasang" : "Belum Terpasang"}</td>
            </tr>
            <tr>
              <td>Tanggal Pemasangan</td>
              <td>
                {customer.installationDate
                  ? moment.unix(customer.installationDate).format("DD MMM YYYY")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Status Aktivasi</td>
              <td>{customer.activationStatus ? "Aktif" : "Belum Aktif"}</td>
            </tr>
            <tr>
              <td>Tanggal Aktivasi</td>
              <td>
                {customer.activationDate
                  ? moment.unix(customer.activationDate).format("DD MMM YYYY")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Status Layanan</td>
              <td>{ServiceStatusConstant[customer.serviceStatus] || "-"}</td>
            </tr>
            <tr>
              <td>Periode Layanan</td>
              <td className="text-nowrap">
                {customer.planStartDate && customer.planEndDate ? (
                  <>
                    {moment.unix(customer.planStartDate).format("DD MMM YYYY")} -{" "}
                    {moment.unix(customer.planEndDate).format("DD MMM YYYY")}
                  </>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Detail Koneksi</h4>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Username</td>
              <td>{customer.pppoeUsername || "-"}</td>
            </tr>
            <tr>
              <td>Password</td>
              <td>{customer.pppoePassword || "-"}</td>
            </tr>
            <tr>
              <td>Tipe IP</td>
              <td>{customer.pppoeIpType || "-"}</td>
            </tr>
            <tr>
              <td>Local Address</td>
              <td>{customer.pppoeLocalAddress || "-"}</td>
            </tr>
            <tr>
              <td>Remote Address</td>
              <td>{customer.pppoeRemoteAddress || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
