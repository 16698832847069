import moment from "moment";
import { useState } from "react";
import { downloadBillings, downloadInvoiceByBillingId } from "../../api/finance";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalConfirmBilling from "../../components/finance/ModalConfirmBilling";
import ModalCreateBilling from "../../components/finance/ModalCreateBilling";
import ModalDetailBilling from "../../components/finance/ModalDetailBilling";
import InternetPackageSelect from "../../components/internetPackages/InternetPackageSelect";
import MainLayout from "../../components/layouts/MainLayout";
import RegionSelect from "../../components/region/RegionSelect";
import { BillingStatusConstant, PaymentMethodConstant } from "../../constant/BillingConstant";
import { useBillingsHooks } from "../../hooks/finance/BillingHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function BillingPage() {
  const { allowedByPermission } = useActionControl();

  const [formFilter, setFormFilter] = useState({
    q: "",
    regionId: "",
    packageId: "",
    paymentMethod: "",
    status: "",
  });
  const [filter, setFilter] = useState({
    q: "",
    regionId: "",
    packageId: "",
    paymentMethod: "",
    status: "",
    page: 1,
    limit: 10,
  });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { billings, statusFetch, meta, errorMsg } = useBillingsHooks({ filter });

  const applyFilter = (event) => {
    event.preventDefault();

    setFilter({ ...filter, ...formFilter });
  };

  const resetFilter = () => {
    setFormFilter({ q: "", regionId: "", packageId: "", paymentMethod: "", status: "" });
    setFilter({
      q: "",
      regionId: "",
      packageId: "",
      paymentMethod: "",
      status: "",
      page: 1,
      limit: 10,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const onFailure = (message) => {
    setAlert({ show: true, type: "danger", message });
  };

  return (
    <MainLayout permission_key={"billing"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Tagihan</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("create-billing")}>
                  <i className="fa fa-plus"></i> Buat Tagihan Manual
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Tagihan" loading={statusFetch === "loading"}>
          <form onSubmit={applyFilter}>
            <div className="row">
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama/No. Pelanggan/Alamat/No. Telepon"
                    value={formFilter.q}
                    onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Metode Pembayaran</label>
                  <select
                    className="form-control"
                    value={formFilter.paymentMethod}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, paymentMethod: event.target.value })
                    }
                  >
                    <option value="">-- Pilih Metode --</option>
                    <option value="cash">Tunai</option>
                    <option value="transfer">Transfer</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    value={formFilter.status}
                    className="form-control"
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, status: event.target.value })
                    }
                  >
                    <option value="">-- Pilih Status --</option>
                    <option value="paid">Sudah Bayar</option>
                    <option value="unpaid">Belum Bayar</option>
                    <option value="cancelled">Dibatalkan</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Wilayah</label>
                  <RegionSelect
                    value={formFilter.regionId}
                    onChange={(value) => setFormFilter({ ...formFilter, regionId: value })}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Paket</label>
                  <InternetPackageSelect
                    value={formFilter.packageId}
                    onChange={(value) => setFormFilter({ ...formFilter, packageId: value })}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group" style={{ marginTop: "0px" }}>
                  <button type="submit" className="btn btn-primary mr-2">
                    Filter
                  </button>
                  <DownloadByFilter
                    filter={formFilter}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                  />
                  <button type="button" className="btn btn-light" onClick={() => resetFilter()}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Tagihan</th>
                  <th>Status</th>
                  <th>No. Pelanggan</th>
                  <th>Nama Pelanggan</th>
                  <th>Telp</th>
                  <th>Alamat</th>
                  <th>Area</th>
                  <th>Paket</th>
                  <th>Periode Paket</th>
                  <th>Harga Paket</th>
                  <th>Total Pembayaran</th>
                  <th>Tgl. Konfirmasi</th>
                  <th>Metode Pembayaran</th>
                  <th>Periode Pemakaian</th>
                  <th>Tgl. Buat Tagihan</th>
                </tr>
              </thead>
              <tbody>
                {billings.length === 0 && (
                  <tr>
                    <td colSpan={16} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {billings.map((billing) => (
                  <tr key={billing.id}>
                    <td>
                      {allowedByPermission("update") && billing.status === "unpaid" && (
                        <button
                          className="btn btn-sm btn-block text-nowrap btn-success"
                          onClick={() => selectModal("confirm-billing", billing.id)}
                        >
                          <i className="fa fa-square-check"></i> Konfirmasi
                        </button>
                      )}
                      {allowedByPermission("read") && (
                        <button
                          className="btn btn-sm btn-block btn-primary text-nowrap"
                          onClick={() => selectModal("detail-billing", billing.id)}
                        >
                          <i className="fa fa-eye"></i> Detail
                        </button>
                      )}
                      {allowedByPermission("read") && (
                        <ButtonDownloadInvoice
                          billing={billing}
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                        />
                      )}
                    </td>
                    <td className="text-nowrap">{billing.billingNumber}</td>
                    <td className="text-nowrap">{BillingStatusConstant[billing.status] || "-"}</td>
                    <td>{billing.customer?.customerNumber}</td>
                    <td className="text-nowrap">{billing.customer?.name}</td>
                    <td className="text-nowrap">{billing.customer?.phoneNumber}</td>
                    <td>{billing.customer?.address || "-"}</td>
                    <td className="text-nowrap">{billing.customer?.region?.name}</td>
                    <td className="text-nowrap">{billing.package?.name}</td>
                    <td className="text-nowrap">{billing.period} hari</td>
                    <td className="text-nowrap">
                      Rp {convertToRupiah(billing.package?.price || 0)}
                    </td>
                    <td className="text-nowrap">Rp {convertToRupiah(billing.finalAmount)}</td>
                    <td className="text-nowrap">
                      {billing.confirmedAt
                        ? moment.unix(billing.confirmedAt).format("DD MMM YYYY HH:mm")
                        : "-"}
                    </td>
                    <td>
                      {billing.payments && (
                        <>{PaymentMethodConstant[billing.payments[0]?.method || ""] || "-"}</>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {billing.startPeriod && billing.endPeriod ? (
                        <>
                          {moment(billing.startPeriod, "YYYY-MM-DD").format("DD MMM YYYY")} -{" "}
                          {moment(billing.endPeriod, "YYYY-MM-DD").format("DD MMM YYYY")}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-nowrap">
                      {moment.unix(billing.createdAt).format("DD MMM YYYY - HH:mm")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}

          <ModalDetailBilling
            show={modal === "detail-billing"}
            onSuccess={onSuccess}
            id={modal === "detail-billing" ? selectedId : null}
            onClose={() => setModal(null)}
          />
          <ModalCreateBilling
            show={modal === "create-billing"}
            onSuccess={onSuccess}
            onClose={() => setModal(null)}
          />
          <ModalConfirmBilling
            show={modal === "confirm-billing"}
            onSuccess={onSuccess}
            id={modal === "confirm-billing" ? selectedId : null}
            onClose={() => setModal(null)}
          />
        </Box>
      </div>
    </MainLayout>
  );
}

function DownloadByFilter({ filter, onSuccess, onFailure }) {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadBillings({ ...filter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Tagihan.xlsx");
      document.body.appendChild(link);
      link.click();
      onSuccess("Berhasil mengunduh Data Tagihan.xlsx");
      // handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.message) {
        message = error.message;
      }

      onFailure(message);
    }
    setLoading(false);
  };

  return (
    <button
      type="button"
      disabled={loading}
      className="btn btn-custom-orange mr-2"
      onClick={downloadFile}
    >
      {loading ? "Mengunduh data ..." : "Download Data"}
    </button>
  );
}

function ButtonDownloadInvoice({ billing, onSuccess, onFailure }) {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadInvoiceByBillingId(billing.id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice-${billing.billingNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      onSuccess(`Berhasil mengunduh invoice-${billing.billingNumber}.pdf`);
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.message) {
        message = error.message;
      }

      onFailure(message);
    }
    setLoading(false);
  };

  return (
    <button
      className="btn btn-sm btn-block btn-custom-purple text-nowrap"
      onClick={downloadFile}
      disabled={loading}
    >
      <i className="fa fa-file-invoice"></i> {loading ? "Mengunduh ..." : "Invoice"}
    </button>
  );
}
