import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { InputPassword, InputText } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { createUser } from "../../api/account";
import RoleSelect from "./RoleSelect";

export default function ModalCreateEmployee({ show, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Akun Karyawan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCreateEmployee onSuccess={onSuccess} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

function FormCreateEmployee({ onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    roleId: "",
    phoneNumber: "",
  });

  const [errorForm, setErrorForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    roleId: "",
    phoneNumber: "",
  });

  const onSubmitNewEmployee = (event) => {
    event.preventDefault();
    submitProfile(form);
  };

  const submitProfile = async (form) => {
    setLoading(true);
    try {
      let { data } = await createUser(form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitNewEmployee}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>Username</label>
        <InputText
          type="text"
          required
          value={form.username}
          onChange={(event) => setForm({ ...form, username: event.target.value })}
          error={errorForm.username}
        />
      </div>
      <div className="form-group">
        <label>Nama Depan</label>
        <InputText
          type="text"
          required
          value={form.firstName}
          onChange={(event) => setForm({ ...form, firstName: event.target.value })}
          error={errorForm.firstName}
        />
      </div>
      <div className="form-group">
        <label>Nama Belakang</label>
        <InputText
          type="text"
          required
          value={form.lastName}
          onChange={(event) => setForm({ ...form, lastName: event.target.value })}
          error={errorForm.lastName}
        />
      </div>
      <div className="form-group">
        <label>Nomor Handphone</label>
        <InputText
          type="text"
          required
          value={form.phoneNumber}
          onChange={(event) => setForm({ ...form, phoneNumber: event.target.value })}
          error={errorForm.phoneNumber}
        />
      </div>
      <div className="form-group">
        <label>Jabatan</label>
        <RoleSelect
          value={form.roleId}
          onChange={(value) => setForm({ ...form, roleId: value })}
          error={errorForm.roleId}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <InputText
          type="email"
          required
          value={form.email}
          onChange={(event) => setForm({ ...form, email: event.target.value })}
          error={errorForm.email}
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <InputPassword
          required
          value={form.password}
          onChange={(event) => setForm({ ...form, password: event.target.value })}
          error={errorForm.password}
          autoComplete="new-password"
        />
      </div>
      <div className="form-group">
        <button className="btn btn-block btn-primary" disabled={loading}>
          Buat Karyawan Baru
        </button>
      </div>
    </form>
  );
}
