import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { createTicket } from "../../api/ticket";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { useCustomerByIdHooks, useCustomersHooks } from "../../hooks/customer/CustomerHooks";
import useDebounce from "../../hooks/useDebounceHooks";

export default function ModalCreateTicket({ show, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Tiket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCreateTicket onSuccess={onSuccess} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

function FormCreateTicket({ onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    customerId: "",
    description: "",
  });

  const [errorForm, setErrorForm] = useState({
    customerId: "",
    description: "",
  });

  const {
    customer,
    statusFetch: customerStatusFetch,
    errorMsg: customerErrorMsg,
  } = useCustomerByIdHooks({ id: form.customerId });

  const onSubmitTicket = (event) => {
    event.preventDefault();
    submitTicket(form);
  };

  const submitTicket = async (form) => {
    setLoading(true);
    try {
      let { data } = await createTicket(form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitTicket}>
      <AlertBox {...alert} setAlert={setAlert} />

      <div className="form-group">
        <label>Pelanggan</label>
        <AutocompleteCustomer
          value={form.customerId}
          onChange={(value) => setForm({ ...form, customerId: value })}
          error={errorForm.customerId}
        />
      </div>
      {form.customerId && customerStatusFetch === "success" && (
        <>
          <div className="form-group">
            <label>Nomor Telepon</label>
            <input type="text" className="form-control" value={customer.phoneNumber} disabled />
          </div>
          <div className="form-group">
            <label>Alamat</label>
            <textarea className="form-control" value={customer.address} disabled />
          </div>
        </>
      )}
      {form.customerId && customerErrorMsg && (
        <div className="alert alert-danger">{customerErrorMsg}</div>
      )}
      <div className="form-group">
        <label>Detail Permasalahan</label>
        <textarea
          className={`form-control ${errorForm.description && "is-invalid"}`}
          rows={3}
          value={form.description}
          onChange={(event) => setForm({ ...form, description: event.target.value })}
        />
        {errorForm.description && <div className="invalid-feedback">{errorForm.description}</div>}
      </div>
      <div className="form-group">
        <button className="btn btn-block btn-primary" disabled={loading}>
          Buat Tiket Baru
        </button>
      </div>
    </form>
  );
}

function AutocompleteCustomer({ value, error, onChange }) {
  const [filter, setFilter] = useState({ q: "", limit: 5 });
  const wrapperRef = useRef(null);

  const { customers, statusFetch } = useCustomersHooks({ filter });
  const [selected, setSelected] = useState(null);
  const [focus, setFocus] = useState(false);
  const [listVisible, setListVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilter((prev) => ({
        ...prev,
        q: debouncedSearchTerm,
      }));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setListVisible(false);
    }
  };

  const handleFocus = () => {
    setFocus(!focus);
    setListVisible(!listVisible);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const onSelectList = (value) => {
    setSelected(value);
    onChange(value.id);
    setListVisible(false);
  };

  const onCancelSelected = () => {
    setSelected(null);
    onChange("");
    setSearchTerm("");
  };

  return (
    <div className="select-autocomplete--wrapper" ref={wrapperRef}>
      {selected === null ? (
        <input
          type="text"
          className={`form-control ${error && "is-invalid"}`}
          placeholder="Ketik nama pelanggan ..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              disabled
              value={`${selected.customerNumber} ${selected.name}`}
            />
          </div>
          <div className="col-auto">
            <button className="btn btn-danger" onClick={() => onCancelSelected()}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      )}
      {error && <span className="d-block invalid-feedback">{error}</span>}
      {listVisible && (
        <ul className="select-autocomplete--list">
          {statusFetch === "success" && customers.length === 0 && (
            <li className="text-gray-700 text-center">Tidak ada data</li>
          )}
          {statusFetch === "loading" && <li className="text-gray-700 text-center">Loading ...</li>}
          {statusFetch === "success" &&
            customers.map((customer) => (
              <li
                className="clickable"
                onClick={() => onSelectList(customer)}
                role="button"
                key={customer.id}
              >
                {customer.customerNumber} {customer.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
