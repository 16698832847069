import { useState, useEffect, useCallback } from "react";
import { generateCaptcha } from "../api/base";

export default function useGenerateCaptcha() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchCaptcha = useCallback(async () => {
    setLoading(true);
    try {
      const response = await generateCaptcha(); // Replace with your backend API endpoint
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching CAPTCHA:", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    // Fetch the CAPTCHA image when the component mounts
    fetchCaptcha();
  }, [fetchCaptcha]);

  return {
    data,
    loading,
    fetchCaptcha,
  };
}
