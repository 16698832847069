import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createBilling } from "../../api/finance";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useCustomerAddonByIdHooks } from "../../hooks/customer/CustomerHooks";
import { convertToRupiah } from "../../tools/numerictools";
import AlertBox from "../AlertBox";
import { InputText } from "../FormHelper";
import CustomerAutoComplete from "../customer/CustomerAutoComplete";
import InternetPackageSelect from "../internetPackages/InternetPackageSelect";
import { AddOnsTypeConstant } from "../../constant/BillingConstant";
import { useInternetPackageByIdHooks } from "../../hooks/internetPackage/InternetPackageHooks";

export default function ModalCreateBilling({ show, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Buat Tagihan Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCreateBilling onSuccess={onSuccess} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

function FormCreateBilling({ onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    customerId: "",
    packageId: "",
    price: "",
    startPeriod: "",
    endPeriod: "",
  });
  const [errorForm, setErrorForm] = useState({
    customerId: "",
    packageId: "",
    price: "",
    startPeriod: "",
    endPeriod: "",
  });

  const {
    customer,
    statusFetch: customerStatusFetch,
    errorMsg: customerErrorMsg,
  } = useCustomerAddonByIdHooks({ id: form.customerId });

  const { internetPackage } = useInternetPackageByIdHooks({ id: form.packageId });

  const resetForm = () => {
    setForm({
      customerId: "",
      packageId: "",
      price: "",
      startPeriod: "",
      endPeriod: "",
    });
  };

  const onSubmitNewBilling = (event) => {
    event.preventDefault();
    let formData = {
      customerId: form.customerId,
      startPeriod: form.startPeriod,
      endPeriod: form.endPeriod,
      package: {
        id: form.packageId,
        price: form.price,
      },
    };

    submitBilling(formData);
  };

  const submitBilling = async (formData) => {
    setLoading(true);
    try {
      let { data } = await createBilling(formData);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const internetPackPriceTier = (priceForm, selectedPackPrice, customerPackPrice) => {
    if (priceForm) {
      return priceForm;
    }

    if (selectedPackPrice) {
      return selectedPackPrice;
    }

    if (customerPackPrice) {
      return customerPackPrice;
    }

    return 0;
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <div className="form-group">
          <label>Pelanggan</label>
          <CustomerAutoComplete
            value={form.customerId}
            onChange={(value) => setForm({ ...form, customerId: value })}
            error={errorForm.customerId}
            onCancelInput={() => resetForm()}
            activeOnly
          />
        </div>

        {form.customerId && customerErrorMsg && (
          <div className="alert alert-danger">{customerErrorMsg}</div>
        )}

        {form.customerId && customerStatusFetch === "success" ? (
          <>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Paket</td>
                  <td>{customer.package?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Harga Paket</td>
                  <td>
                    Rp{" "}
                    {customer.package?.price ? convertToRupiah(customer.package?.price || 0) : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Status Pemasangan</td>
                  <td>{customer.installationStatus ? "Sudah Terpasang" : "Belum Terpasang"}</td>
                </tr>
                <tr>
                  <td>Tanggal Pemasangan</td>
                  <td>
                    {customer.installationDate
                      ? moment.unix(customer.installationDate).format("DD MMM YYYY")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Status Layanan</td>
                  <td>{customer.serviceStatus ? "Sudah Aktivasi" : "Belum Teraktivasi"}</td>
                </tr>
                <tr>
                  <td>Periode Layanan</td>
                  <td className="text-nowrap">
                    {customer.planStartDate && customer.planEndDate ? (
                      <>
                        {moment.unix(customer.planStartDate).format("DD MMM YYYY")} -{" "}
                        {moment.unix(customer.planEndDate).format("DD MMM YYYY")}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>Biaya dan Diskon</h5>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Deskripsi</th>
                  <th>Tipe</th>
                  <th>Biaya</th>
                </tr>
              </thead>
              <tbody>
                {customer.addons.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      Tidak ada tambahan
                    </td>
                  </tr>
                )}
                {customer.addons.map((additional) => (
                  <tr key={additional.id}>
                    <td>{additional.description}</td>
                    <td>{AddOnsTypeConstant[additional.type]}</td>
                    {additional.type === "discount" ? (
                      <td className="text-right">- Rp {convertToRupiah(additional.amount)}</td>
                    ) : (
                      <td className="text-right">Rp {convertToRupiah(additional.amount)}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div className="card">
            <div className="card-body">Belum ada data pelanggan</div>
          </div>
        )}
      </div>
      <div className="col-12 col-lg-6">
        <form onSubmit={onSubmitNewBilling}>
          <AlertBox {...alert} setAlert={setAlert} />
          <h4>Form Pengubahan</h4>
          <div className="form-group">
            <label>Paket</label>
            <InternetPackageSelect
              withPrice={true}
              value={form.packageId}
              onChange={(value) => setForm({ ...form, packageId: value })}
              error={errorForm.packageId}
            />
            <small>Hanya digunakan untuk mengganti paket internet</small>
          </div>
          <div className="form-group">
            <label>Harga Paket yang Berlaku</label>
            <InputText
              type="number"
              value={form.price}
              onChange={(event) => setForm({ ...form, price: event.target.value })}
              error={errorForm.price}
            />
            <small>
              Hanya diisi untuk mengganti harga paket pada tagihan. Kosongkan jika tidak ingin
              mengubah.
            </small>
          </div>
          <div className="form-group">
            <label>Mulai Periode Layanan</label>
            <InputText
              type="date"
              value={form.startPeriod}
              required={!!form.endPeriod}
              onChange={(event) => setForm({ ...form, startPeriod: event.target.value })}
              error={errorForm.startPeriod}
            />
            <small>Hanya diisi untuk mengganti awal periode layanan internet</small>
          </div>
          <div className="form-group">
            <label>Akhir Periode Layanan</label>
            <InputText
              type="date"
              value={form.endPeriod}
              required={!!form.startPeriod}
              onChange={(event) => setForm({ ...form, endPeriod: event.target.value })}
              error={errorForm.endPeriod}
            />
            <small>Hanya diisi untuk mengganti akhir periode layanan internet</small>
          </div>
          <TableBillingPreview
            addons={customer.addons || []}
            packagePrice={internetPackPriceTier(
              Number(form.price),
              form.packageId ? internetPackage.price : 0,
              customer.package?.price
            )}
          />
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Sedang Membuat .. " : "Buat Tagihan Baru"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function TableBillingPreview({ addons, packagePrice }) {
  let totalDiscount = 0;
  let totalAdditional = 0;

  addons.forEach((additional) => {
    if (additional.type === "discount") {
      totalDiscount += additional.amount;
    } else {
      totalAdditional += additional.amount;
    }
  });

  let totalAmount = packagePrice + totalAdditional - totalDiscount;

  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr>
          <td colSpan={2}>
            <b>Tagihan</b>
          </td>
        </tr>
        <tr>
          <td>Harga Paket</td>
          <td className="text-right">Rp. {convertToRupiah(packagePrice)}</td>
        </tr>
        <tr>
          <td>Total Tambahan Biaya</td>
          <td className="text-right">Rp. {convertToRupiah(totalAdditional)}</td>
        </tr>
        <tr>
          <td>Total Diskon</td>
          <td className="text-right">-Rp. {convertToRupiah(totalDiscount)}</td>
        </tr>
        <tr>
          <td>
            <big>
              <b>Total Pembayaran</b>
            </big>
          </td>
          <td className="text-right">
            {totalAmount < 0 ? (
              <big> - Rp. {convertToRupiah(Math.abs(totalAmount))}</big>
            ) : (
              <big>Rp. {convertToRupiah(totalAmount)}</big>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
