import { apiAuth, apiDownloadAuth } from "./base";

export function getCustomers(params) {
  let api = apiAuth();
  return api.get("v1/customers", { params });
}

export function createCustomer(data) {
  let api = apiAuth();
  return api.post("v1/customers", data);
}

export function getCustomerById(id) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}`);
}

export function getCustomerAddonById(id) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/addons`);
}

export function updateCustomerById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${id}`, data);
}

export function deleteCustomerById(id) {
  let api = apiAuth();
  return api.delete(`v1/customers/${id}`);
}

export function downloadCustomer(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v1/customers/exportTo/xlsx", { params });
}
