import { apiAuth } from "./base";

export function getRegions(params) {
  let api = apiAuth();
  return api.get("v1/regions", { params });
}

export function createRegion(data) {
  let api = apiAuth();
  return api.post("v1/regions", data);
}

export function getRegionById(id) {
  let api = apiAuth();
  return api.get(`v1/regions/${id}`);
}

export function updateRegionById(id, data) {
  let api = apiAuth();
  return api.put(`v1/regions/${id}`, data);
}

export function deleteRegionById(id) {
  let api = apiAuth();
  return api.delete(`v1/regions/${id}`);
}
