import { useEffect, useState, useContext, createContext } from "react";
import * as api from "../api/base";
import { ClientErrorResponseMessage, ErrorResponse } from "../constant/ErrorResponseConstants";

export function useProvideAuth() {
  const [user, setUser] = useState({});
  const [isAuthenticated, setAuthenticated] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const login = async (form) => {
    setErrorMsg("");
    try {
      const { data } = await api.login(form);
      if (data.data.token) {
        localStorage.setItem("token", data.data.token);
        setAuthenticated(true);
        loadUser();
      } else {
        // this.setState({error: data.message})
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthenticated(false);
    setUser({});
    setErrorMsg("");
  };

  const loadUser = async () => {
    try {
      let { data } = await api.getProfile();
      setUser(data.data);
    } catch (error) {
      if (error.response) {
        if (error.response?.data.code === 401 && error.response?.data.message === "token expired") {
          logout();
          setErrorMsg("Sesi Telah berakhir!");
        }
      }

      if (
        error.response?.data.code === 403 &&
        error.response?.data.error.type === ErrorResponse.InactiveAccountError
      ) {
        logout();
        setErrorMsg(ClientErrorResponseMessage.InactiveAccountError);
      }

      if (
        error.response?.data.code === 403 &&
        error.response?.data.error.type === ErrorResponse.AuthenticationError &&
        error.response?.data.message === "Account not found"
      ) {
        logout();
        setErrorMsg(ClientErrorResponseMessage.NotFoundAccountError);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      loadUser();
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);

  return {
    user,
    loadUser,
    errorMsg,
    isAuthenticated,
    login,
    logout,
  };
}

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
