export const menu = [
  {
    path: "/home",
    name: "Dashboard",
    icon: "fas fa-gauge-high",
    permission_key: "dashboard",
  },
  {
    path: "/customers",
    name: "Pelanggan",
    icon: "fas fa-users",
    permission_key: "customer",
  },
  {
    path: "/finance/billing",
    name: "Tagihan",
    icon: "fas fa-cash-register",
    permission_key: "billing",
  },
  {
    path: "/additional-and-discount",
    name: "Biaya dan Diskon",
    icon: "fas fa-hand-holding-dollar",
    permission_key: "billing:addon",
  },
  {
    path: "/internet-package",
    name: "Paket Internet",
    icon: "fas fa-box-open",
    permission_key: "package",
  },
  {
    path: "/odp",
    name: "ODP",
    icon: "fas fa-network-wired",
    permission_key: "odp",
  },
  {
    path: "/router",
    name: "Router",
    icon: "fas fa-circle-nodes",
    permission_key: "router",
  },
  {
    path: "/vpn",
    name: "VPN",
    icon: "fas fa-shield-halved",
    permission_key: "router",
  },
  {
    path: "/region",
    name: "Wilayah",
    icon: "fas fa-tower-cell",
    permission_key: "region",
  },
  {
    path: "/ticket",
    name: "Tiket Bantuan",
    icon: "fas fa-headset",
    permission_key: "ticket",
  },
  {
    path: "/employee",
    name: "Kelola Karyawan",
    icon: "fas fa-people-roof",
    permission_key: "employee",
    children: [
      {
        path: "/employee/manage",
        name: "Data Karyawan",
        permission_key: "employee:data",
      },
      {
        path: "/employee/roles",
        name: "Akses dan Jabatan",
        permission_key: "role:permission",
      },
    ],
  },
  {
    path: "/banks",
    name: "Rekening Bank",
    icon: "fa fa-building-columns",
    permission_key: "bank",
  },
  {
    path: "/access-log",
    name: "Access Log",
    icon: "fas fa-traffic-light",
    permission_key: "log",
  },
  {
    path: "/settings",
    name: "Pengaturan",
    icon: "fas fa-cog",
    permission_key: "setting",
    children: [
      {
        path: "/settings",
        name: "Pengaturan Umum",
        permission_key: "general:setting",
      },
      {
        path: "/settings/profile",
        name: "Pengaturan Profil",
        permission_key: "profile:setting",
      },
    ],
  },
];
