import { useState, useEffect } from "react";
import { getProfile } from "../api/user";

export function useProfileHook() {
  const [profile, setProfile] = useState({ username: "", firstName: "", lastName: "", email: "" });
  const [statusFetch, setStatusFetch] = useState(null); // loading, fetched, error
  const [error, setErrorMsg] = useState("");

  const fetchProfile = async () => {
    setStatusFetch("loading");
    try {
      let { data } = await getProfile();
      setProfile(data.data);
      setStatusFetch("success");
    } catch (error) {
      setStatusFetch("failed");
      if (error.response) {
        if (error.response?.data.message) {
          setErrorMsg(error.response?.data?.message);
        }
      } else {
        setErrorMsg("Terjadi kesalahan meload");
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return {
    profile,
    statusFetch,
    fetchProfile,
    error,
  };
}
