import { useCallback, useEffect, useState } from "react";
import { getRegionById, getRegions } from "../../api/region";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";

export function useRegionHooks({ filter }) {
  const [regions, setRegions] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRegions = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRegions({ ...filter });
      setStatusFetch("success");
      setRegions(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchRegions(filter);
  }, [filter, fetchRegions]);

  return {
    regions,
    meta,
    statusFetch,
    errorMsg,
    fetchRegions,
  };
}

export function useRegionByIdHooks({ id }) {
  const [region, setRegion] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRegion = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRegionById(id);
      setRegion(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchRegion(id);
    }
  }, [id, fetchRegion]);

  return {
    region,
    statusFetch,
    errorMsg,
    fetchRegion,
  };
}
