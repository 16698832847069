import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../utils/AuthHooks";
import useGenerateCaptcha from "../utils/CaptchaUtils";

function LoginPage() {
  const { isAuthenticated, errorMsg, login } = useAuth();
  let { data: captchaData, loading: captchaLoading, fetchCaptcha } = useGenerateCaptcha();

  const [form, setForm] = useState({ username: "", password: "" });
  const [solving, setSolving] = useState("");

  let history = useHistory();

  const changeField = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  const formLoginSubmit = async (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      captcha: {
        id: captchaData.id,
        solution: solving.trim(),
      },
    };
    login(formData);
  };

  useEffect(() => {
    if (errorMsg) {
      setSolving("");
      fetchCaptcha();
    }
  }, [errorMsg, fetchCaptcha]);

  useEffect(() => {
    if (isAuthenticated === true) {
      history.push("/home");
    }
  }, [isAuthenticated, history]);

  return (
    <div
      className="login-page-wrapper bg-gradient-custom-purple"
      style={{ minHeight: "100vh", padding: "100px 0" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card o-hidden border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      {/* <img src={image} className="mb-3" style={{maxWidth: "200px"}}/> */}
                      <h1 className="h4 text-gray-900 mb-4">Login Dashboard</h1>
                    </div>
                  </div>
                  <div className="col-12">
                    {errorMsg && (
                      <div className="alert alert-danger">
                        <b>Terjadi kesalahan.</b> {errorMsg}
                      </div>
                    )}
                    <form className="user" onSubmit={(event) => formLoginSubmit(event)}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          id="username"
                          aria-describedby="usernameHelp"
                          value={form.username}
                          onChange={(event) => changeField("username", event.target.value)}
                          placeholder="Email atau Username ..."
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="password"
                          value={form.password}
                          onChange={(event) => changeField("password", event.target.value)}
                          placeholder="Password"
                        />
                      </div>
                      <div className="form-group">
                        <label>Captcha</label>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-2">
                            {captchaData.captcha && (
                              <img
                                src={`data:image/svg+xml;base64,${captchaData.captcha}`}
                                alt="captcha"
                                className="img-fluid"
                              />
                            )}
                            <button
                              className="btn btn-sm btn-block"
                              type="button"
                              disabled={captchaLoading}
                              onClick={() => fetchCaptcha()}
                            >
                              <i className="fa fa-arrows-rotate"></i>{" "}
                              {captchaLoading ? "Loading Captcha ..." : "Reset Captcha"}
                            </button>
                          </div>
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Captcha ..."
                              value={solving}
                              onChange={(event) => setSolving(event.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary btn-user btn-block">
                        Login
                      </button>
                    </form>
                    <div className="text-muted text-center py-3">
                      Belum punya akun? <Link to="/register">Daftar</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
