import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function ModalScriptVpn({ show, scriptMikrotik, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Script</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <textarea className="form-control" rows={5} disabled value={scriptMikrotik} />
        </div>
        <div className="form-group">
          <BtnCopy value={scriptMikrotik} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function BtnCopy({ value }) {
  let [copied, setCopied] = useState(false);

  const copyToClipboard = (content) => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <button className="btn btn-primary" onClick={() => copyToClipboard(value)}>
      <i className="fa fa-copy"></i> {copied ? "Script Copied" : "Copy Script"}
    </button>
  );
}
