import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import { useFinancialSummaryHooks } from "../../hooks/DashboardHooks";
import Box from "../Box";
import { convertToRupiah } from "../../tools/numerictools";

const formatter = (value) => `Rp${convertToRupiah(value)}`;
export default function BoxFinanceSummary() {
  const { data, statusFetch, errorMsg } = useFinancialSummaryHooks();

  return (
    <Box title="Keuangan" loading={statusFetch === "loading"}>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      {statusFetch === "success" && data.length === 0 && (
        <div className="card">
          <div className="card-body text-center">Belum ada data keuangan</div>
        </div>
      )}

      {statusFetch === "success" && data.length > 0 && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 10, left: 40, right: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis tickFormatter={formatter} tick={{ fontSize: 11 }} />
            <Tooltip formatter={formatter} />
            <Legend />
            <Bar dataKey="revenue" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
