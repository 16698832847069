import { useEffect, useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useTenantHook } from "../../hooks/TenantHooks";
import { InputFileUploader, InputText } from "../../components/FormHelper";
import AlertBox from "../../components/AlertBox";
import { updateTenant } from "../../api/user";
import { ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useActionControl } from "../../utils/ActionControlUtils";
import moment from "moment";

export default function SettingPage() {
  return (
    <MainLayout permission_key={"general:setting"}>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan</h1>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <FormTenantBox />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

function FormTenantBox() {
  const { allowedByPermission } = useActionControl();
  const { tenant, statusFetch } = useTenantHook();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [form, setForm] = useState({
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: "",
    companyCity: "",
    companyLogo: "",
    generateBillAuto: true,
    generateBillBefore: 0,
  });

  const [errorForm, setErrorForm] = useState({
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: "",
    companyCity: "",
    companyLogo: "",
    generateBillAuto: "",
    generateBillBefore: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        companyName: tenant.companyName || "",
        companyPhone: tenant.companyPhone || "",
        companyEmail: tenant.companyEmail || "",
        companyWebsite: tenant.companyWebsite || "",
        companyLogo: tenant.companyLogo || "",
        companyAddress: tenant.companyAddress || "",
        companyCity: tenant.companyCity || "",
        generateBillAuto: tenant.generateBillAuto,
        generateBillBefore: tenant.generateBillBefore || 0,
      }));
    }
  }, [tenant, statusFetch]);

  const resetForm = () => {
    setErrorForm({
      companyName: "",
      companyPhone: "",
      companyEmail: "",
      companyWebsite: "",
      companyAddress: "",
      companyCity: "",
      companyLogo: "",
      generateBillAuto: "",
      generateBillBefore: "",
    });
  };

  const onSubmitTenant = (event) => {
    event.preventDefault();

    submitTenant(form);
  };

  const submitTenant = async (form) => {
    setLoading(true);
    try {
      let response = await updateTenant(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      resetForm();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Box title="Data Perusahaan" loading={statusFetch === "loading" || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <h5 className="font-weight-bold">Lisensi Dashboard</h5>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>No. Pelanggan</td>
            <td>{tenant.accountNumber}</td>
          </tr>
          <tr>
            <td>Tanggal Akhir Lisensi</td>
            <td>
              {tenant.licenceEndDate
                ? moment.unix(tenant.licenceEndDate).format("DD MMMM YYYY")
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="font-weight-bold">Data Perusahaan</h5>
      <form onSubmit={(event) => onSubmitTenant(event)}>
        <div className="form-group">
          <InputFileUploader
            value={form.companyLogo}
            label={"Logo Perusahaan"}
            error={errorForm.companyLogo}
            onChange={(value) => setForm({ ...form, companyLogo: value })}
          />
        </div>
        <div className="form-group">
          <label>Nama Perusahaan</label>
          <InputText
            type="text"
            required
            value={form.companyName}
            onChange={(event) => setForm({ ...form, companyName: event.target.value })}
            error={errorForm.companyName}
          />
        </div>
        <div className="form-group">
          <label>Nomor Telepon</label>
          <InputText
            type="text"
            required
            value={form.companyPhone}
            onChange={(event) => setForm({ ...form, companyPhone: event.target.value })}
            error={errorForm.companyPhone}
          />
        </div>
        <div className="form-group">
          <label>Website</label>
          <InputText
            type="text"
            value={form.companyWebsite}
            onChange={(event) => setForm({ ...form, companyWebsite: event.target.value })}
            error={errorForm.companyWebsite}
          />
        </div>
        <div className="form-group">
          <label>Alamat Email</label>
          <InputText
            type="email"
            required
            value={form.companyEmail}
            onChange={(event) => setForm({ ...form, companyEmail: event.target.value })}
            error={errorForm.companyEmail}
          />
        </div>
        <div className="form-group">
          <label>Alamat Perusahaan</label>
          <InputText
            type="text"
            required
            value={form.companyAddress}
            onChange={(event) => setForm({ ...form, companyAddress: event.target.value })}
            error={errorForm.companyAddress}
          />
        </div>
        <div className="form-group">
          <label>Kota</label>
          <InputText
            type="text"
            required
            value={form.companyCity}
            onChange={(event) => setForm({ ...form, companyCity: event.target.value })}
            error={errorForm.companyCity}
          />
        </div>
        <div className="form-group">
          <label>Buat Tagihan Paket Otomatis</label>
          <select
            className="form-control"
            value={String(form.generateBillAuto)}
            onChange={(event) =>
              setForm({ ...form, generateBillAuto: event.target.value === "true" })
            }
          >
            <option value="true">Otomatis</option>
            <option value="false">Tidak Otomatis</option>
          </select>
        </div>
        <div className="form-group">
          <label>Buat Tagihan Otomatis Sebelum Jatuh Tempo</label>
          <InputText
            type="number"
            required
            value={form.generateBillBefore}
            onChange={(event) => setForm({ ...form, generateBillBefore: event.target.value })}
            error={errorForm.generateBillBefore}
          />
          <small>Hitungan hari sebelum jatuh tempo</small>
        </div>
        <div className="form-group">
          {allowedByPermission("update") && (
            <button className="btn btn-block btn-primary" disabled={loading}>
              Simpan
            </button>
          )}
        </div>
      </form>
    </Box>
  );
}
