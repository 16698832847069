import { useState } from "react";
import { Link } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useODPListHooks } from "../../hooks/odp/OdpHooks";

let filter = { showAll: true };

function BackToOdpLink() {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/odp`}>
            <i className="fa-regular fa-circle-left"></i> Kembali ke Halaman ODP
          </Link>
        </li>
      </ol>
    </nav>
  );
}

export default function OdpMapPage() {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const { odpList, statusFetch, errorMsg } = useODPListHooks({ filter });

  return (
    <MainLayout permission_key={"odp"}>
      <div className="container-fluid">
        <BackToOdpLink />
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Peta ODP</h1>
            </div>
          </div>
        </div>
        <Box title="Peta ODP" loading={statusFetch === "loading"}>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <OdpMap odpList={odpList} />
        </Box>
      </div>
    </MainLayout>
  );
}

const position = [-6.2, 106.816666];

function OdpMap({ odpList }) {
  return (
    <MapContainer center={position} zoom={9} style={{ height: "500px" }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <OdpMapBounds odpList={odpList} />
      {odpList.map((odp) =>
        odp.latitude && odp.longitude ? (
          <Marker position={[odp.latitude, odp.longitude]} key={odp.id}>
            <Popup>
              {odp.name} - Tersedia {odp.remainingCapacity}/{odp.capacity}
            </Popup>
          </Marker>
        ) : null
      )}
    </MapContainer>
  );
}

function OdpMapBounds({ odpList }) {
  let bounds = [];

  odpList.forEach((odp) => {
    if (odp.latitude && odp.longitude) {
      bounds.push([odp.latitude, odp.longitude]);
    }
  });

  const map = useMap();

  if (bounds.length !== 0) {
    map.fitBounds(bounds);
  }
  return null;
}
