import { useRolesHooks } from "../../hooks/employee/RoleHooks";

export default function RoleSelect({ value, onChange, error, ...rest }) {
  const { roles } = useRolesHooks();
  return (
    <select
      className={`form-control ${!!error && "is-invalid"}`}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    >
      <option value="">-- Pilih Role --</option>
      {roles.map((role) => (
        <option key={role.id} value={role.id}>
          {role.name}
        </option>
      ))}
    </select>
  );
}
