import { useState } from "react";
import { Link } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalCreateOdp from "../../components/odp/ModalCreateOdp";
import ModalDeleteOdp from "../../components/odp/ModalDeleteOdp";
import ModalUpdateOdp from "../../components/odp/ModalUpdateOdp";
import { useODPListHooks } from "../../hooks/odp/OdpHooks";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function OdpManagePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { odpList, statusFetch, meta, errorMsg } = useODPListHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  return (
    <MainLayout permission_key={"odp"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola ODP</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-odp")}>
                  <i className="fa fa-plus"></i> Tambah ODP
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Daftar ODP" loading={statusFetch === "loading"}>
          <div className="row justify-content-end">
            <div className="col-auto">
              <Link to="/odp/map" className="btn btn-custom-purple mb-2">
                <i className="fa fa-map-location-dot"></i> Lihat Peta ODP
              </Link>
            </div>
          </div>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nama ODP</th>
                  <th>Kapasitas</th>
                  <th>Kapasitas Tersedia</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Router</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {odpList.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {odpList.map((device) => (
                  <tr key={device.id}>
                    <td>{device.name}</td>
                    <td>{device.capacity}</td>
                    <td>{device.remainingCapacity}</td>
                    <td>{device.latitude || "-"}</td>
                    <td>{device.longitude || "-"}</td>
                    <td>{device.router?.name || "-"}</td>
                    <td>
                      {allowedByPermission("update") && (
                        <button
                          className="btn btn-info btn-sm mx-2"
                          onClick={() => selectModal("update-odp", device.id)}
                        >
                          <i className="fa fa-pencil"></i> Ubah
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => selectModal("delete-odp", device.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {meta.count > meta.limit && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.limit)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>

          <ModalCreateOdp
            show={modal === "new-odp"}
            onSuccess={onSuccess}
            onClose={() => setModal(null)}
          />
          <ModalUpdateOdp
            show={modal === "update-odp"}
            onSuccess={onSuccess}
            id={modal === "update-odp" ? selectedId : null}
            onClose={() => setModal(null)}
          />
          <ModalDeleteOdp
            show={modal === "delete-odp"}
            onSuccess={onSuccess}
            id={modal === "delete-odp" ? selectedId : null}
            onClose={() => setModal(null)}
          />
        </Box>
      </div>
    </MainLayout>
  );
}
