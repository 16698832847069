import { useState } from "react";
import { Modal } from "react-bootstrap";
import { deleteBillingAddOnById } from "../../api/finance";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { useBillingAddOnByIdHooks } from "../../hooks/finance/AddonBillingHooks";
import AlertBox from "../AlertBox";
import moment from "moment";
import { convertToRupiah } from "../../tools/numerictools";
import { AddOnsTypeConstant } from "../../constant/BillingConstant";

export default function ModalDeleteAddon({ show, id, onSuccess, onClose }) {
  const { addOn, statusFetch, errorMsg } = useBillingAddOnByIdHooks({ id });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleClose = () => {
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const deleteAddonBilling = async () => {
    setLoading(true);
    try {
      let { data } = await deleteBillingAddOnById(id);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Tambahan Biaya</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <AlertBox {...alert} setAlert={setAlert} />
        <p>Apakah Anda yakin menghapus Biaya Tambahan ini?</p>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Tgl Dibuat</td>
              <td>
                {addOn.createdAt ? moment.unix(addOn.createdAt).format("DD MMM YYYY - HH:mm") : "-"}
              </td>
            </tr>
            <tr>
              <td>Tipe</td>
              <td>{AddOnsTypeConstant[addOn.type] || "-"}</td>
            </tr>
            <tr>
              <td>Jumlah</td>
              <td>{addOn.amount ? `Rp ${convertToRupiah(addOn.amount || 0)}` : "-"}</td>
            </tr>
            <tr>
              <td>No. Pelanggan</td>
              <td>{addOn.customer?.customerNumber || "-"}</td>
            </tr>
            <tr>
              <td>Nama Pelanggan</td>
              <td>{addOn.customer?.name || "-"}</td>
            </tr>
            <tr>
              <td>Deskripsi</td>
              <td>{addOn.description || "-"}</td>
            </tr>
          </tbody>
        </table>
        <button
          className="btn btn-danger"
          disabled={loading || statusFetch === "loading"}
          onClick={() => deleteAddonBilling()}
        >
          {loading ? "Sedang Menghapus ..." : "Ya, Saya Yakin"}
        </button>
        <button className="btn btn-light" onClick={() => onClose()}>
          Batalkan
        </button>
      </Modal.Body>
    </Modal>
  );
}
