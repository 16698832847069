import moment from "moment";
import { Modal } from "react-bootstrap";
import { useBillingByIdHooks } from "../../hooks/finance/BillingHooks";
import { convertToRupiah } from "../../tools/numerictools";
import {
  AddOnsTypeConstant,
  BillingStatusConstant,
  PaymentMethodConstant,
} from "../../constant/BillingConstant";

export default function ModalDetailBilling({ show, id, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Tagihan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailBilling id={id} />
      </Modal.Body>
    </Modal>
  );
}

function paymentMethodValue(payments = []) {
  if (payments[0]?.method === "cash") {
    return "Tunai";
  } else if (payments[0]?.method === "transfer") {
    const bank = payments[0]?.bank || {};
    return `${bank.name} ${bank.accountNumber} a.n. ${bank.accountName}`;
  }
}
function DetailBilling({ id }) {
  const { billing, statusFetch, errorMsg } = useBillingByIdHooks({ id });
  return (
    <div className="row">
      <div className="col-12">
        {statusFetch === "loading" && <div className="alert alert-warning">Loading ...</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      </div>
      <div className="col-12 col-md-6">
        <h5>Informasi Tagihan</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Nomor Tagihan</td>
              <td className="text-nowrap">{billing.billingNumber}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{BillingStatusConstant[billing.status] || "-"}</td>
            </tr>
            {billing.confirmedAt && (
              <tr>
                <td>Tgl. Konfirmasi</td>
                <td>{moment.unix(billing.confirmedAt).format("DD MMM YYYY HH:mm")}</td>
              </tr>
            )}
            <tr>
              <td>Tgl. Buat Tagihan</td>
              <td>
                {billing.createdAt && moment.unix(billing.createdAt).format("DD MMM YYYY HH:mm")}
              </td>
            </tr>
            <tr>
              <td>Dibuat Oleh</td>
              <td>
                {billing.createdBy
                  ? billing.createdBy.firstName + " " + billing.createdBy.lastName
                  : "SISTEM"}
              </td>
            </tr>
            <tr>
              <td>Biaya Paket</td>
              <td className="text-right">Rp {convertToRupiah(billing.totalAmount || 0)}</td>
            </tr>
            <tr>
              <td>Total Biaya Tambahan</td>
              <td className="text-right">
                Rp {convertToRupiah(billing.totalAdditionalAmount || 0)}
              </td>
            </tr>
            <tr>
              <td>Total Diskon</td>
              <td className="text-right">
                - Rp {convertToRupiah(billing.totalDiscountAmount || 0)}
              </td>
            </tr>
            <tr>
              <td>Total Akhir</td>
              <td className="text-right">Rp {convertToRupiah(billing.finalAmount || 0)}</td>
            </tr>
            <tr>
              <td>Metode Pembayaran</td>
              <td>
                {billing.payments && (
                  <>{PaymentMethodConstant[billing.payments[0]?.method || ""] || "-"}</>
                )}
              </td>
            </tr>
            <tr>
              <td>Diterima Oleh</td>
              <td>
                {billing.status === "paid" ? (
                  <>
                    {billing.receivedBy
                      ? billing.receivedBy.firstName + " " + billing.receivedBy.lastName
                      : paymentMethodValue(billing.payments)}
                  </>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <h5>Informasi Pelanggan</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>No. Pelanggan</td>
              <td>{billing.customer?.customerNumber || "-"}</td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>{billing.customer?.name}</td>
            </tr>
            <tr>
              <td>No. Telepon</td>
              <td>{billing.customer?.phoneNumber}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>{billing.customer?.address || "-"}</td>
            </tr>
            <tr>
              <td>Wilayah</td>
              <td>{billing.customer?.region?.name || "-"}</td>
            </tr>
            <tr>
              <td>Paket Internet</td>
              <td>{billing.package?.name || "-"}</td>
            </tr>
            <tr>
              <td>Biaya Paket</td>
              <td>Rp {convertToRupiah(billing.package?.price || 0)}</td>
            </tr>
          </tbody>
        </table>

        {billing.addons && (
          <>
            <h5>Biaya dan Diskon</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr>
                  <th>Deskripsi</th>
                  <th>Tipe</th>
                  <th>Biaya</th>
                </tr>
              </thead>
              <tbody>
                {billing.addons.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {billing.addons.map((addon) => (
                  <tr key={addon.id}>
                    <td>{addon.description}</td>
                    <td>{AddOnsTypeConstant[addon.type]}</td>
                    {addon.type === "discount" ? (
                      <td className="text-right">- Rp {convertToRupiah(addon.amount)}</td>
                    ) : (
                      <td className="text-right">Rp {convertToRupiah(addon.amount)}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
