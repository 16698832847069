import { useCallback, useEffect, useState } from "react";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { getODPById, getODPList } from "../../api/odp";

export function useODPListHooks({ filter }) {
  const [odpList, setOdpList] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchODPList = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getODPList({ ...filter });
      setStatusFetch("success");
      setOdpList(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchODPList(filter);
  }, [filter, fetchODPList]);

  return {
    odpList,
    meta,
    statusFetch,
    errorMsg,
    fetchODPList,
  };
}

export function useODPByIdHooks({ id }) {
  const [odp, setOdp] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchODP = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getODPById(id);
      setOdp(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchODP(id);
    }
  }, [id, fetchODP]);

  return {
    odp,
    statusFetch,
    errorMsg,
    fetchODP,
  };
}
