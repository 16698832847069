import { apiAuth } from "./base";

export function getTickets(params) {
  let api = apiAuth();
  return api.get("v1/tickets", { params });
}

export function createTicket(data) {
  let api = apiAuth();
  return api.post("v1/tickets", data);
}

export function getTicketById(id) {
  let api = apiAuth();
  return api.get(`v1/tickets/${id}`);
}

export function updateProgressByTicketId(id, data) {
  let api = apiAuth();
  return api.post(`v1/tickets/${id}/progress`, data);
}

export function deleteTicketById(id) {
  let api = apiAuth();
  return api.delete(`v1/tickets/${id}`);
}
