import axios from "axios";
import moment from "moment/moment";

const base_api = process.env.REACT_APP_API_URL;

export const apiBasic = () =>
  axios.create({
    baseURL: base_api,
    timeout: 600000,
  });

export const apiAuth = () =>
  axios.create({
    baseURL: base_api,
    timeout: 600000,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-CLIENT-ID": localStorage.getItem("clientUserId"),
      "X-TIMESTAMP": moment().format(),
    },
  });

export const apiUploader = () =>
  axios.create({
    baseURL: base_api,
    timeout: 600000,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-CLIENT-ID": localStorage.getItem("clientUserId"),
      "X-TIMESTAMP": moment().format(),
      "Content-Type": "multipart/form-data",
    },
  });

export const apiDownloadAuth = () =>
  axios.create({
    baseURL: base_api,
    timeout: 600000,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-CLIENT-ID": localStorage.getItem("clientUserId"),
      "X-TIMESTAMP": moment().format(),
    },
  });

export function login(action) {
  let params = { ...action };
  let api = apiBasic();
  return api.post("v1/auth/signin", params);
}

export function register(data) {
  let api = apiBasic();
  return api.post("v1/auth/register", data);
}

export function generateCaptcha(action) {
  let params = { ...action };
  let api = apiBasic();
  return api.post("v1/auth/captcha", params);
}

export function getProfile() {
  let api = apiAuth();
  return api.get("v1/user/profile");
}
