import { useODPListHooks } from "../../hooks/odp/OdpHooks";

const filter = { showAll: true };

export default function OdpSelect({ value, onChange, disabled, error, ...rest }) {
  const { odpList, statusFetch } = useODPListHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChange(event.target.value)}
        {...rest}
      >
        <option value="">Tanpa ODP</option>
        {odpList.map((odp) => (
          <option key={odp.id} value={odp.id}>
            {odp.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
