import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useBillingByIdHooks } from "../../hooks/finance/BillingHooks";
import { convertToRupiah } from "../../tools/numerictools";
import AlertBox from "../AlertBox";
import { InputSelectWrapper, RequiredAsterisk } from "../FormHelper";
import BankSelect from "../bank/BankSelect";
import EmployeeSelect from "../employee/EmployeeSelect";
import { confirmBillingById } from "../../api/finance";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";

export default function ModalConfirmBilling({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <BodyConfirmBilling id={id} onSuccess={onSuccess} onClose={onClose} />
    </Modal>
  );
}

function BodyConfirmBilling({ id, onSuccess, onClose }) {
  const { billing, statusFetch, errorMsg } = useBillingByIdHooks({ id });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({ method: "cash", bankId: "", receivedBy: "" });
  const [errorForm, setErrorForm] = useState({ method: "", bankId: "", receivedBy: "" });

  const onChangeMethod = (value) => {
    let tempForm = { ...form };

    tempForm.method = value;

    if (value === "cash") {
      tempForm.bankId = "";
    } else if (value === "transfer") {
      tempForm.receivedBy = "";
    }

    setForm({ ...tempForm });
  };
  const onSubmitConfirm = (event) => {
    event.preventDefault();
    let formData = {
      payment: {
        ...form,
      },
    };
    submitConfirm(formData);
  };

  const submitConfirm = async (form) => {
    setLoading(true);
    try {
      let { data } = await confirmBillingById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Konfirmasi Tagihan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {statusFetch === "loading" && <div className="alert alert-warning">Loading ...</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Tgl. Tagihan</td>
              <td>
                {billing.createdAt && moment.unix(billing.createdAt).format("DD MMM YYYY HH:mm")}
              </td>
            </tr>
            <tr>
              <td>No. Pelanggan</td>
              <td>{billing.customer?.customerNumber || "-"}</td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>{billing.customer?.name}</td>
            </tr>
            <tr>
              <td>Total Akhir</td>
              <td>Rp {convertToRupiah(billing.finalAmount || 0)}</td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-12">
            <AlertBox {...alert} setAlert={setAlert} />
            <form onSubmit={onSubmitConfirm}>
              <div className="form-group">
                <label>
                  Metode Pembayaran <RequiredAsterisk />
                </label>
                <InputSelectWrapper
                  type="text"
                  required
                  value={form.method}
                  onChange={(event) => onChangeMethod(event.target.value)}
                  error={errorForm.method}
                >
                  <option value="cash">Tunai</option>
                  <option value="transfer">Transfer Bank</option>
                </InputSelectWrapper>
              </div>
              {form.method === "transfer" && (
                <div className="form-group">
                  <label>
                    Pilih Rekening <RequiredAsterisk />
                  </label>
                  <BankSelect
                    required
                    value={form.bankId}
                    onChange={(value) => setForm({ ...form, bankId: value })}
                    error={errorForm.bankId}
                  />
                </div>
              )}

              {form.method === "cash" && (
                <div className="form-group">
                  <label>
                    Penerima Pembayaran <RequiredAsterisk />
                  </label>
                  <EmployeeSelect
                    required
                    value={form.receivedBy}
                    onChange={(value) => setForm({ ...form, receivedBy: value })}
                    error={errorForm.receivedBy}
                  />
                </div>
              )}
              <div className="form-group">
                <button className="btn btn-block btn-primary">
                  {loading ? "Mengkonfirmasi Tagihasi ..." : "Konfirmasi Tagihan"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}
