import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateProgressByTicketId } from "../../api/ticket";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useTicketByIdHooks } from "../../hooks/ticket/TicketHooks";
import AlertBox from "../AlertBox";
import { InputSelectWrapper } from "../FormHelper";
import { LoadingInsideBox } from "../Loading";

export default function ModalProgressTicket({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  const onSuccessClosingTicket = (message) => {
    onSuccess(message);
    handleClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <DetailProgressTicket id={id} onClose={onClose} onSuccessClose={onSuccessClosingTicket} />
    </Modal>
  );
}

function DetailProgressTicket({ id, onClose, onSuccessClose }) {
  const { ticket, statusFetch, errorMsg, fetchTicket } = useTicketByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const onSuccessUpdate = (message) => {
    fetchTicket(id);
    setAlert({ show: true, type: "success", message: message });
  };

  const onErrorUpdate = (message) => {
    fetchTicket(id);
    setAlert({ show: true, type: "danger", message: message });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Update Progress Tiket{" "}
          {ticket.ticketNumber && <span className="badge badge-info">{ticket.ticketNumber}</span>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <div className="loading-data-div">
          {statusFetch === "loading" && <LoadingInsideBox />}

          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-12 col-md-4">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      {ticket.status === "open" && <span className="badge badge-danger">open</span>}
                      {ticket.status === "working" && (
                        <span className="badge badge-warning">working</span>
                      )}
                      {ticket.status === "closed" && (
                        <span className="badge badge-success">closed</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>No. Tiket</td>
                    <td>{ticket.ticketNumber || "-"}</td>
                  </tr>
                  <tr>
                    <td>Waktu Dibuat</td>
                    <td>
                      {ticket.createdAt
                        ? moment.unix(ticket.createdAt).format("DD MMMM YYYY HH:mm")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Dibuat Oleh</td>
                    <td>
                      {ticket.createdBy &&
                        `${ticket.createdBy.firstName} ${ticket.createdBy.lastName}`}
                    </td>
                  </tr>
                  <tr>
                    <td>No. Pelanggan</td>
                    <td>{ticket.customer?.customerNumber || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Pelanggan</td>
                    <td>{ticket.customer?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nomor Telepon</td>
                    <td>{ticket.customer?.phoneNumber || "-"}</td>
                  </tr>
                  <tr>
                    <td>Detail Masalah</td>
                    <td>{ticket.description || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 col-md-8">
              {ticket.status !== "closed" ? (
                <>
                  <h5>Update Progress Aksi</h5>
                  <FormUpdateProgress id={id} onSuccessUpdate={onSuccessUpdate} />
                </>
              ) : null}
              <h4>Riwayat Aksi</h4>
              {ticket.ticketHistories && (
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Tanggal</th>
                      <th>User</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket.ticketHistories.length === 0 && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          Belum ada progress
                        </td>
                      </tr>
                    )}
                    {ticket.ticketHistories?.map((history) => (
                      <tr key={history.id}>
                        <td>
                          {history.createdAt
                            ? moment.unix(history.createdAt).format("DD MMMM YYYY HH:mm")
                            : "-"}
                        </td>
                        <td>
                          {history.createdBy &&
                            `${history.createdBy.firstName} ${history.createdBy.lastName}`}
                        </td>
                        <td>{history.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {ticket.status !== "closed" ? (
                <ButtonTicketDone
                  id={id}
                  onSuccessClose={onSuccessClose}
                  onErrorUpdate={onErrorUpdate}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

function ButtonTicketDone({ id, onSuccessClose, onErrorUpdate }) {
  const [loading, setLoading] = useState(false);

  const onDoneTicket = () => {
    const formData = {
      resolved: true,
    };

    updateProgress(formData);
  };

  const updateProgress = async (formData) => {
    setLoading(true);
    try {
      let { data } = await updateProgressByTicketId(id, formData);
      onSuccessClose(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      onErrorUpdate(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-block btn-success" onClick={() => onDoneTicket()}>
      {loading ? (
        <>Menyelesaikan Tiket ...</>
      ) : (
        <>
          <i className="fa fa-check"></i> Tandai Tiket Sudah Selesai
        </>
      )}
    </button>
  );
}

function FormUpdateProgress({ id, onSuccessUpdate }) {
  const [form, setForm] = useState({ type: "note", description: "" });
  const [errorForm, setErrorForm] = useState({ type: "", description: "" });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const onChangeType = (value) => {
    let formTemp = { ...form };

    if (value === "technician") {
      formTemp.description = "";
    }

    setForm({ ...formTemp, type: value });
  };

  const onSubmitProgress = (event) => {
    event.preventDefault();

    const formData = {
      resolved: false,
      detail: {
        ...form,
      },
    };

    updateProgress(formData);
  };

  const resetForm = () => {
    setForm({ type: "note", description: "" });
    setErrorForm({ type: "", description: "" });
  };

  const updateProgress = async (formData) => {
    setLoading(true);
    try {
      let { data } = await updateProgressByTicketId(id, formData);
      resetForm();
      onSuccessUpdate(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  return (
    <form onSubmit={onSubmitProgress}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>Tipe Aksi</label>
        <InputSelectWrapper
          value={form.type}
          error={errorForm.type}
          onChange={(event) => onChangeType(event.target.value)}
        >
          <option value="note">Catatan Baru</option>
          <option value="technician">Kirim Teknisi</option>
        </InputSelectWrapper>
      </div>
      {form.type !== "technician" && (
        <div className="form-group">
          <label>Keterangan Aksi</label>
          <textarea
            className={`form-control ${errorForm.description && "is-invalid"}`}
            rows={3}
            value={form.description}
            required
            onChange={(event) => setForm({ ...form, description: event.target.value })}
          />
          {errorForm.description && <div className="invalid-feedback">{errorForm.description}</div>}
        </div>
      )}
      <div className="form-group">
        <button className="btn btn-primary">
          {loading ? (
            <>Harap Tunggu ...</>
          ) : (
            <>
              <i className="fa fa-save"></i> Update Progress
            </>
          )}
        </button>
      </div>
    </form>
  );
}
