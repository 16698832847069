import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import CustomerAutoComplete from "../customer/CustomerAutoComplete";
import { InputSelectWrapper, InputText } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { createBillingAddOn } from "../../api/finance";

export default function ModalCreateAddon({ show, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Tambahan Tagihan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCreateAddon onSuccess={onSuccess} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

function FormCreateAddon({ onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({ customerId: "", type: "", amount: 0, description: "" });

  const [errorForm, setErrorForm] = useState({
    customerId: "",
    type: "",
    amount: "",
    description: "",
  });

  const onSubmitNewAddon = (event) => {
    event.preventDefault();
    submitAddon(form);
  };

  const submitAddon = async (form) => {
    setLoading(true);
    try {
      let { data } = await createBillingAddOn(form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={onSubmitNewAddon}>
        <AlertBox {...alert} setAlert={setAlert} />

        <div className="form-group">
          <label>Pelanggan</label>
          <CustomerAutoComplete
            value={form.customerId}
            onChange={(value) => setForm({ ...form, customerId: value })}
            error={errorForm.customerId}
            onCancelInput={() => {}}
            activeOnly
          />
        </div>
        <div className="form-group">
          <label>Tipe Tambahan</label>
          <InputSelectWrapper
            type="text"
            required
            value={form.type}
            onChange={(event) => setForm({ ...form, type: event.target.value })}
            error={errorForm.type}
          >
            <option value="" disabled>
              -- Pilih Jenis Tambahan --
            </option>
            <option value="additional">Tambahan Biaya</option>
            <option value="discount">Diskon Biaya</option>
          </InputSelectWrapper>
        </div>
        <div className="form-group">
          <label>Biaya</label>
          <InputText
            type="number"
            required
            value={form.amount}
            onChange={(event) => setForm({ ...form, amount: event.target.value })}
            error={errorForm.amount}
          />
        </div>
        <div className="form-group">
          <label>Deskripsi</label>
          <textarea
            className={`form-control ${errorForm.description && "is-invalid"}`}
            rows={3}
            value={form.description}
            onChange={(event) => setForm({ ...form, description: event.target.value })}
          />
          {errorForm.description && <div className="invalid-feedback">{errorForm.description}</div>}
        </div>
        <div className="form-group">
          <button className="btn btn-block btn-primary" disabled={loading}>
            {loading ? "Sedang Membuat" : "Buat Tambahan Biaya Baru"}
          </button>
        </div>
      </form>
    </div>
  );
}
