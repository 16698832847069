import { useInternetPackagesHooks } from "../../hooks/internetPackage/InternetPackageHooks";
import { convertToRupiah } from "../../tools/numerictools";

const filter = { showAll: true };

export default function InternetPackageWithRouterSelect({
  value,
  onChange,
  withPrice,
  disabled,
  error,
  ...rest
}) {
  const { internetPackages, statusFetch } = useInternetPackagesHooks({ filter });

  const onChangePackage = (id) => {
    let selected = internetPackages.find((arr) => arr.id === id);
    onChange(id, selected.router?.id || "", selected.profile || "");
  };

  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChangePackage(event.target.value)}
        {...rest}
      >
        <option value="">-- Pilih Paket --</option>
        {internetPackages.map((internetPkg) => (
          <option key={internetPkg.id} value={internetPkg.id}>
            {internetPkg.name} {withPrice ? `Rp ${convertToRupiah(internetPkg.price)}` : ""}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
