import { useEffect, useState } from "react";
import { getAccessLog } from "../api/setting";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";

export function useAuditLogsHook({ filter }) {
  const [logs, setLogs] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getLogs = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAccessLog({ ...filter });
      setLogs(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs(filter);
  }, [filter]);

  return {
    logs,
    meta,
    loading,
    errorMsg,
    getLogs,
  };
}
