import { useEffect, useState } from "react";
import { usePermissionSchemaHooks } from "../../hooks/PermissionsHooks";
import { useRoleByIdHooks } from "../../hooks/employee/RoleHooks";
import ModalDeleteRole from "./ModalDeleteRole";
import AlertBox from "../AlertBox";
import { LoadingInsideBox } from "../Loading";
import { updateRoleById } from "../../api/account";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function FormUpdateRolePermission({ id, onSuccessDelete, onSuccessUpdate }) {
  const { allowedByPermission } = useActionControl();
  const { role, fetchRole, statusFetch: roleStatusFetch } = useRoleByIdHooks({ id });

  const { schemas, statusFetch: schemaStatusFetch } = usePermissionSchemaHooks();
  const [mode, setMode] = useState(null); //edit
  const [form, setForm] = useState({ name: "" });
  const [permissionForm, setPermissionForm] = useState({});

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (schemaStatusFetch === "success" && roleStatusFetch === "success") {
      let formData = {};

      schemas.forEach((schema) => {
        formData[schema.id] = { ...schema.operation };
      });

      role.permissions.forEach((permission) => {
        if (formData[permission.id]) {
          formData[permission.id] = { ...permission.operation };
        }
      });

      setForm((prev) => ({
        ...prev,
        name: role.name,
      }));

      setPermissionForm({ ...formData });
    }
  }, [schemas, schemaStatusFetch, role, roleStatusFetch]);

  const onSuccess = (message) => {
    fetchRole(id);
    onSuccessUpdate(message);
  };

  const onCheckAllow = (id, operator) => {
    let formData = { ...permissionForm };
    formData[id][operator] = !formData[id][operator];
    setPermissionForm({ ...formData });
  };

  const onCheckAllowAll = () => {
    let formData = { ...permissionForm };

    Object.keys(formData).forEach((keys) => {
      Object.keys(formData[keys]).forEach((opKeys) => {
        formData[keys][opKeys] = true;
      });
    });
    setPermissionForm({ ...formData });
  };

  const onDisallowAll = () => {
    let formData = { ...permissionForm };

    Object.keys(formData).forEach((keys) => {
      Object.keys(formData[keys]).forEach((opKeys) => {
        formData[keys][opKeys] = false;
      });
    });
    setPermissionForm({ ...formData });
  };

  const onSubmitData = (event) => {
    event.preventDefault();

    let formData = {
      name: form.name,
      permissions: [],
    };

    Object.keys(permissionForm).forEach((permission) => {
      formData.permissions.push({
        id: permission,
        ...permissionForm[permission],
      });
    });

    sendUpdateRole(formData);
  };

  const sendUpdateRole = async (formData) => {
    setLoading(true);
    try {
      let { data } = await updateRoleById(id, formData);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <div className="loading-data-div">
      <form onSubmit={onSubmitData}>
        <AlertBox {...alert} setAlert={setAlert} />
        {(roleStatusFetch === "loading" || loading) && <LoadingInsideBox />}
        <div className="row justify-content-end mb-3">
          <div className="col-12 col-md">
            {mode === "edit-name" ? (
              <input
                type="text"
                className="form-control"
                value={form.name}
                onChange={(event) => setForm({ ...form, name: event.target.value })}
              />
            ) : (
              <h3>{role.name}</h3>
            )}
          </div>
          <div className="col-12 col-md-auto">
            {allowedByPermission("update") ? (
              mode === "edit-name" ? (
                <button
                  className="btn btn-warning mr-1"
                  onClick={() => setMode(null)}
                  type="button"
                >
                  <i className="fa fa-times"></i> Tutup
                </button>
              ) : (
                <button
                  className="btn btn-info mr-1"
                  onClick={() => setMode("edit-name")}
                  type="button"
                >
                  <i className="fa fa-pencil"></i> Edit Nama
                </button>
              )
            ) : null}
            {!role.superuser && allowedByPermission("delete") && (
              <button
                className="btn btn-danger"
                onClick={() => setModal("delete-role")}
                type="button"
              >
                <i className="fa fa-trash"></i> Hapus Jabatan
              </button>
            )}
          </div>
        </div>
        {!role.superuser ? (
          <>
            <div className="row mb-2">
              <div className="col">
                <h5>Hak Akses</h5>
              </div>
              {allowedByPermission("update") && (
                <div className="col-auto">
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    type="button"
                    onClick={() => onCheckAllowAll()}
                  >
                    Centang Semua
                  </button>
                  <button
                    className="btn btn-sm btn-light"
                    type="button"
                    onClick={() => onDisallowAll()}
                  >
                    Hapus Semua Centang
                  </button>
                </div>
              )}
            </div>
            <table className="table table-bordered table-hover  ">
              <thead>
                <tr>
                  <th>Menu</th>
                  <th>Lihat Data</th>
                  <th>Buat Data</th>
                  <th>Sunting Data</th>
                  <th>Hapus Data</th>
                </tr>
              </thead>
              <tbody>
                {schemas.map((schema) => (
                  <tr key={schema.id}>
                    <td>{schema.name}</td>
                    <td>
                      {schema.operation.hasOwnProperty("read") && (
                        <label>
                          <input
                            type="checkbox"
                            checked={permissionForm[schema.id]?.read || false}
                            onChange={() => onCheckAllow(schema.id, "read")}
                            disabled={!allowedByPermission("update")}
                          />{" "}
                          Izinkan
                        </label>
                      )}
                    </td>
                    <td>
                      {schema.operation.hasOwnProperty("create") && (
                        <label>
                          <input
                            type="checkbox"
                            checked={permissionForm[schema.id]?.create || false}
                            onChange={() => onCheckAllow(schema.id, "create")}
                            disabled={!allowedByPermission("update")}
                          />{" "}
                          Izinkan
                        </label>
                      )}
                    </td>
                    <td>
                      {schema.operation.hasOwnProperty("update") && (
                        <label>
                          <input
                            type="checkbox"
                            checked={permissionForm[schema.id]?.update || false}
                            onChange={() => onCheckAllow(schema.id, "update")}
                            disabled={!allowedByPermission("update")}
                          />{" "}
                          Izinkan
                        </label>
                      )}
                    </td>
                    <td>
                      {schema.operation.hasOwnProperty("delete") && (
                        <label>
                          <input
                            type="checkbox"
                            checked={permissionForm[schema.id]?.delete || false}
                            onChange={() => onCheckAllow(schema.id, "delete")}
                            disabled={!allowedByPermission("update")}
                          />{" "}
                          Izinkan
                        </label>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div className="alert alert-secondary">
            {role.name} adalah <b>superuser</b>
          </div>
        )}
        <div className="form-group text-right">
          {allowedByPermission("update") && (
            <button className="btn btn-lg btn-primary">
              <i className="fa fa-save"></i> Simpan Perubahan
            </button>
          )}
        </div>
      </form>

      <ModalDeleteRole
        show={modal === "delete-role"}
        onSuccess={onSuccessDelete}
        id={modal === "delete-role" ? id : null}
        onClose={() => setModal(null)}
      />
    </div>
  );
}
