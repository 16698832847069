import { useState, useEffect } from "react";
import { usePermissionSchemaHooks } from "../../hooks/PermissionsHooks";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import { createRole } from "../../api/account";
import AlertBox from "../AlertBox";

export default function FormNewPermission({ onSuccessCreate }) {
  const { schemas, statusFetch: schemaStatusFetch } = usePermissionSchemaHooks();
  const [form, setForm] = useState({ name: "" });
  const [permissionForm, setPermissionForm] = useState({});

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (schemaStatusFetch === "success") {
      let formData = {};

      schemas.forEach((schema) => {
        formData[schema.id] = { ...schema.operation };
      });

      setPermissionForm({ ...formData });
    }
  }, [schemas, schemaStatusFetch]);

  const onCheckAllow = (id, operator) => {
    let formData = { ...permissionForm };
    formData[id][operator] = !formData[id][operator];
    setPermissionForm({ ...formData });
  };

  const onCheckAllowAll = () => {
    let formData = { ...permissionForm };

    Object.keys(formData).forEach((keys) => {
      Object.keys(formData[keys]).forEach((opKeys) => {
        formData[keys][opKeys] = true;
      });
    });
    setPermissionForm({ ...formData });
  };

  const onDisallowAll = () => {
    let formData = { ...permissionForm };

    Object.keys(formData).forEach((keys) => {
      Object.keys(formData[keys]).forEach((opKeys) => {
        formData[keys][opKeys] = false;
      });
    });
    setPermissionForm({ ...formData });
  };

  const onSubmitData = (event) => {
    event.preventDefault();

    let formData = {
      name: form.name,
      permissions: [],
    };

    Object.keys(permissionForm).forEach((permission) => {
      formData.permissions.push({
        id: permission,
        ...permissionForm[permission],
      });
    });

    sendNewRole(formData);
  };

  const sendNewRole = async (formData) => {
    setLoading(true);
    try {
      let { data } = await createRole(formData);
      onSuccessCreate(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmitData}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="row justify-content-end mb-3">
        <div className="col-12 col-md">
          <div className="form-group">
            <label>Jabatan Baru</label>
            <input
              type="text"
              className="form-control"
              placeholder="Silahkan input jabatan baru"
              value={form.name}
              required
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h5>Hak Akses</h5>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-sm btn-primary mr-2"
            type="button"
            onClick={() => onCheckAllowAll()}
          >
            Centang Semua
          </button>
          <button className="btn btn-sm btn-light" type="button" onClick={() => onDisallowAll()}>
            Hapus Semua Centang
          </button>
        </div>
      </div>
      <table className="table table-bordered table-hovered">
        <thead>
          <tr>
            <th>Menu</th>
            <th>Lihat Data</th>
            <th>Buat Data</th>
            <th>Sunting Data</th>
            <th>Hapus Data</th>
          </tr>
        </thead>
        <tbody>
          {schemas.map((schema) => (
            <tr key={schema.id}>
              <td>{schema.name}</td>
              <td>
                {schema.operation.hasOwnProperty("read") && (
                  <label>
                    <input
                      type="checkbox"
                      checked={permissionForm[schema.id]?.read || false}
                      onChange={() => onCheckAllow(schema.id, "read")}
                    />{" "}
                    Izinkan
                  </label>
                )}
              </td>
              <td>
                {schema.operation.hasOwnProperty("create") && (
                  <label>
                    <input
                      type="checkbox"
                      checked={permissionForm[schema.id]?.create || false}
                      onChange={() => onCheckAllow(schema.id, "create")}
                    />{" "}
                    Izinkan
                  </label>
                )}
              </td>
              <td>
                {schema.operation.hasOwnProperty("update") && (
                  <label>
                    <input
                      type="checkbox"
                      checked={permissionForm[schema.id]?.update || false}
                      onChange={() => onCheckAllow(schema.id, "update")}
                    />{" "}
                    Izinkan
                  </label>
                )}
              </td>
              <td>
                {schema.operation.hasOwnProperty("delete") && (
                  <label>
                    <input
                      type="checkbox"
                      checked={permissionForm[schema.id]?.delete || false}
                      onChange={() => onCheckAllow(schema.id, "delete")}
                    />{" "}
                    Izinkan
                  </label>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="form-group text-right">
        <button className="btn btn-primary" disabled={loading}>
          <i className="fa fa-save"></i> Buat Jabatan Baru
        </button>
      </div>
    </form>
  );
}
