import { useState } from "react";
import { Link } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalCreateRouter from "../../components/router/ModalCreateRouter";
import { useRouterDeviceHooks } from "../../hooks/router/RouterDeviceHooks";
import ModalUpdateRouter from "../../components/router/ModalUpdateRouter";
import ModalDeleteRouter from "../../components/router/ModalDeleteRouter";
import { useActionControl } from "../../utils/ActionControlUtils";
import ModalDetailRouter from "../../components/router/ModalDetailRouter";
import ButtonUpdateRouterConnection from "../../components/router/ButtonUpdateRouterConnection";
import moment from "moment";

export default function RouterDevicePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { routers, statusFetch, meta, errorMsg } = useRouterDeviceHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const onFailure = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "danger", message });
  };

  return (
    <MainLayout permission_key={"router"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola Router</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-router")}>
                  <i className="fa fa-plus"></i> Tambah Router
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="alert alert-info">
          <b>
            <i className="fa fa-circle-info"></i>
          </b>{" "}
          Status koneksi diperbarui secara otomatis dan berkala (tiap 5 menit).
        </div>
        <Box title="Daftar Router" loading={statusFetch === "loading"}>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Router</th>
                  <th>IP Address</th>
                  <th>port</th>
                  <th>status</th>
                  <th>Pengecekan Terakhir</th>
                  <th>Auto Isolir</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {routers.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {routers.map((device) => (
                  <tr key={device.id}>
                    <td>{device.name || "-"}</td>
                    <td>
                      {device.vpn ? (
                        <>
                          {device.vpn?.name || "-"}{" "}
                          <span
                            className={`badge badge-${
                              device.vpn?.status ? "success" : "secondary"
                            }`}
                          >
                            vpn
                          </span>
                        </>
                      ) : (
                        device.ip
                      )}
                    </td>
                    <td>{device.port}</td>
                    <td>
                      {device.status ? (
                        <span className="badge badge-success">Terhubung</span>
                      ) : (
                        <span className="badge badge-danger">Tidak Terhubung</span>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {device.lastCheckConn
                        ? moment.unix(device.lastCheckConn).format("DD MMM YYYY HH:mm:ss")
                        : "-"}
                    </td>
                    <td>{device.autoIsolate ? "Ya" : "Tidak"}</td>
                    <td width={250}>
                      {allowedByPermission("read") && (
                        <button
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => selectModal("detail-router", device.id)}
                        >
                          <i className="fa fa-eye"></i> Detail
                        </button>
                      )}
                      <Link
                        to={`/router/${device.id}/traffic`}
                        className="btn btn-custom-orange btn-block btn-sm"
                      >
                        <i className="fa fa-traffic-light"></i> Traffic
                      </Link>
                      {allowedByPermission("update") && (
                        <>
                          <ButtonUpdateRouterConnection
                            id={device.id}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                          />
                          <button
                            className="btn btn-info btn-block btn-sm"
                            onClick={() => selectModal("update-router", device.id)}
                          >
                            <i className="fa fa-pencil"></i> Ubah
                          </button>
                        </>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-danger btn-block btn-sm"
                          onClick={() => selectModal("delete-router", device.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {meta.count > meta.limit && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.limit)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>

          <ModalCreateRouter
            show={modal === "new-router"}
            onSuccess={onSuccess}
            onClose={() => setModal(null)}
          />
          <ModalDetailRouter
            show={modal === "detail-router"}
            onSuccess={onSuccess}
            id={modal === "detail-router" ? selectedId : null}
            onClose={() => setModal(null)}
          />
          <ModalUpdateRouter
            show={modal === "update-router"}
            onSuccess={onSuccess}
            id={modal === "update-router" ? selectedId : null}
            onClose={() => setModal(null)}
          />

          <ModalDeleteRouter
            show={modal === "delete-router"}
            onSuccess={onSuccess}
            id={modal === "delete-router" ? selectedId : null}
            onClose={() => setModal(null)}
          />
        </Box>
      </div>
    </MainLayout>
  );
}
