import { Modal } from "react-bootstrap";
import { useRouterDeviceByIdHooks } from "../../hooks/router/RouterDeviceHooks";
import { useState } from "react";
import { RouterOsVersionConstant } from "../../constant/RouterConstant";

export default function ModalDetailRouter({ show, id, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Router</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailRouter id={id} />
      </Modal.Body>
    </Modal>
  );
}

function DetailRouter({ id }) {
  const { router } = useRouterDeviceByIdHooks({ id });

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <h5>Router</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Nama</td>
              <td>{router.name || "-"}</td>
            </tr>
            <tr>
              <td>IP</td>
              <td>
                {router.vpn ? (
                  <>
                    {router.vpn?.ipAddress}{" "}
                    <span className={`badge badge-${router.vpn?.status ? "success" : "secondary"}`}>
                      vpn
                    </span>
                  </>
                ) : (
                  router.ip || "-"
                )}
              </td>
            </tr>
            <tr>
              <td>Username Mikrotik</td>
              <td>{router.username || "-"}</td>
            </tr>
            <tr>
              <td>Password Mikrotik</td>
              <td>
                <PasswordText password={router.password || "-"} />
              </td>
            </tr>
            <tr>
              <td>Port Mikrotik</td>
              <td>{router.port || "-"}</td>
            </tr>
            <tr>
              <td>Versi RouterOS</td>
              <td>{RouterOsVersionConstant[router.osVersion] || "-"}</td>
            </tr>
            <tr>
              <td>Status Koneksi</td>
              <td>
                {router.status ? (
                  <span className="badge badge-success">Terhubung</span>
                ) : (
                  <span className="badge badge-danger">Tidak Terhubung</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <h5>Sistem Isolir</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Metode Isolir</td>
              <td>{router.autoIsolate ? "Auto Isolir" : "Tidak Auto Isolir"}</td>
            </tr>
            <tr>
              <td>Metode Isolir</td>
              <td>{router.isolateAction || "-"}</td>
            </tr>
            <tr>
              <td>Profil Isolir</td>
              <td>{router.isolateProfile || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-lg-6">
        <h5>VPN</h5>
        {router.vpn ? (
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama VPN</td>
                <td>{router.vpn?.name || "-"}</td>
              </tr>
              <tr>
                <td>IP Address</td>
                <td>{router.vpn?.ipAddress || "-"}</td>
              </tr>
              <tr>
                <td>Status Koneksi VPN</td>
                <td>
                  {router.vpn?.status ? (
                    <span className="badge badge-success">Terhubung</span>
                  ) : (
                    <span className="badge badge-danger">Tidak Terhubung</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="card">
            <div className="card-body">Tidak Menggunakan VPN</div>
          </div>
        )}
      </div>
    </div>
  );
}

function PasswordText({ password }) {
  let [visibility, setVisibility] = useState(false);

  let newString = "";
  for (let i = 0; i < password.length; i++) {
    newString += "*";
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setVisibility(!visibility)}
        className="btn btn-sm btn-secondary mr-2"
      >
        {visibility ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
      </button>
      <span>{visibility ? password : newString}</span>
    </>
  );
}
