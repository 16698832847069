import { useCallback, useEffect, useState } from "react";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";
import {
  getRouterById,
  getRouterInterfacesById,
  getRouterSecretsById,
  getRouters,
} from "../../api/routerDevice";

export function useRouterDeviceHooks({ filter }) {
  const [routers, setRouters] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRouters = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRouters({ ...filter });
      setStatusFetch("success");
      setRouters(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchRouters(filter);
  }, [filter, fetchRouters]);

  return {
    routers,
    meta,
    statusFetch,
    errorMsg,
    fetchRouters,
  };
}

export function useRouterDeviceByIdHooks({ id }) {
  const [router, setRouter] = useState({});
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRouter = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRouterById(id);
      setRouter(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchRouter(id);
    }
  }, [id, fetchRouter]);

  return {
    router,
    statusFetch,
    errorMsg,
    fetchRouter,
  };
}

export function useRouterInterfaceByIdHooks({ id }) {
  const [routerInterfaces, setRouterInterfaces] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRouterInterfaces = useCallback(async (id) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRouterInterfacesById(id);
      setRouterInterfaces(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchRouterInterfaces(id);
    }
  }, [id, fetchRouterInterfaces]);

  return {
    routerInterfaces,
    statusFetch,
    errorMsg,
    fetchRouterInterfaces,
  };
}

export function useRouterSecretsByIdHooks({ id, profile }) {
  const [routerSecrets, setRouterSecrets] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchRouterSecrets = useCallback(async (id, profile) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getRouterSecretsById(id, { profile: profile });
      setRouterSecrets(data.data);
      setStatusFetch("success");
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    if (id && profile) {
      fetchRouterSecrets(id, profile);
    }
  }, [id, fetchRouterSecrets, profile]);

  return {
    routerSecrets,
    statusFetch,
    errorMsg,
    fetchRouterSecrets,
  };
}
