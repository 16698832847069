import moment from "moment";
import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalCreateAddon from "../../components/finance/ModalCreateAddon";
import ModalDeleteAddon from "../../components/finance/ModalDeleteAddon";
import ModalDetailAddon from "../../components/finance/ModalDetailAddon";
import MainLayout from "../../components/layouts/MainLayout";
import { useBillingAddOnsHooks } from "../../hooks/finance/AddonBillingHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { useActionControl } from "../../utils/ActionControlUtils";
import { AddOnsTypeConstant } from "../../constant/BillingConstant";

export default function AdditionalDiscountPage() {
  const { allowedByPermission } = useActionControl();

  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const { addOns, statusFetch, meta, errorMsg } = useBillingAddOnsHooks({ filter });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  return (
    <MainLayout permission_key={"billing:addon"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Biaya Tambahan dan Diskon</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("create-addon")}>
                  <i className="fa fa-plus"></i> Tambah Pembiayaan Baru
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Biaya dan Diskon" loading={statusFetch === "loading"}>
          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Tgl. Dibuat</th>
                  <th>No Pelanggan</th>
                  <th>Nama</th>
                  <th>Keterangan</th>
                  <th>Nominal</th>
                  <th>Tipe</th>
                  <th>Status</th>
                  <th>No. Tagihan</th>
                  <th>Tgl. Konfirmasi Pembayaran</th>
                  <th>Dibuat oleh</th>
                </tr>
              </thead>
              <tbody>
                {addOns.length === 0 && (
                  <tr>
                    <td colSpan={11} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {addOns.map((additional) => (
                  <tr key={additional.id}>
                    <td>
                      {allowedByPermission("read") && (
                        <button
                          className="btn btn-sm btn-primary btn-block text-nowrap"
                          onClick={() => selectModal("detail-addon", additional.id)}
                        >
                          <i className="fa fa-eye"></i> Detail
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-sm btn-danger btn-block text-nowrap"
                          onClick={() => selectModal("delete-addon", additional.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {moment.unix(additional.createdAt).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td>{additional.customer?.customerNumber}</td>
                    <td className="text-nowrap">{additional.customer?.name}</td>
                    <td>{additional.description}</td>
                    <td className="text-nowrap">Rp {convertToRupiah(additional.amount || 0)}</td>
                    <td>{AddOnsTypeConstant[additional.type] || "-"}</td>
                    <td>
                      {additional.status ? (
                        <span className="badge badge-primary">Open</span>
                      ) : (
                        <span className="badge badge-dark">Closed</span>
                      )}
                    </td>
                    <td className="text-nowrap">{additional.billing?.billingNumber || "-"}</td>
                    <td className="text-nowrap">
                      {additional.billing?.confirmedAt
                        ? moment.unix(additional.billing.confirmedAt).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                    <td className="text-nowrap">
                      {additional.createdBy?.firstName} {additional.createdBy?.lastName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreateAddon
        show={modal === "create-addon"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />

      <ModalDetailAddon
        show={modal === "detail-addon"}
        onSuccess={onSuccess}
        id={modal === "detail-addon" ? selectedId : null}
        onClose={() => setModal(null)}
      />
      <ModalDeleteAddon
        show={modal === "delete-addon"}
        onSuccess={onSuccess}
        id={modal === "delete-addon" ? selectedId : null}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}
