import { useCallback, useEffect, useState } from "react";
import { getAllVpn } from "../../api/vpn";
import { ClientErrorResponseMessage } from "../../constant/ErrorResponseConstants";

export function useVpnListHooks({ filter }) {
  const [vpnList, setVpnList] = useState([]);
  const [statusFetch, setStatusFetch] = useState("");
  const [meta, setMeta] = useState({ count: 0, page: 1, limit: 10 });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchVpnList = useCallback(async (filter = {}) => {
    setStatusFetch("loading");
    setErrorMsg("");
    try {
      const { data } = await getAllVpn({ ...filter });
      setStatusFetch("success");
      setVpnList(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("error");
    }
  }, []);

  useEffect(() => {
    fetchVpnList(filter);
  }, [filter, fetchVpnList]);

  return {
    vpnList,
    meta,
    statusFetch,
    errorMsg,
    fetchVpnList,
  };
}
