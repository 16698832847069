import moment from "moment";
import { Modal } from "react-bootstrap";
import { convertToRupiah } from "../../tools/numerictools";
import { useBillingAddOnByIdHooks } from "../../hooks/finance/AddonBillingHooks";
import { AddOnsTypeConstant } from "../../constant/BillingConstant";

export default function ModalDetailAddon({ show, id, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Tambahan Tagihan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailBilling id={id} />
      </Modal.Body>
    </Modal>
  );
}

function DetailBilling({ id }) {
  const { addOn, statusFetch, errorMsg } = useBillingAddOnByIdHooks({ id });
  return (
    <div className="row">
      <div className="col-12">
        {statusFetch === "loading" && <div className="alert alert-warning">Loading ...</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      </div>
      <div className="col-12 col-lg-6">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Tgl Dibuat</td>
              <td>
                {addOn.createdAt ? moment.unix(addOn.createdAt).format("DD MMM YYYY - HH:mm") : "-"}
              </td>
            </tr>
            <tr>
              <td>Dibuat Oleh</td>
              <td>
                {addOn.createdBy ? addOn.createdBy.firstName + " " + addOn.createdBy.lastName : "-"}
              </td>
            </tr>
            <tr>
              <td>Tipe</td>
              <td>{AddOnsTypeConstant[addOn.type] || "-"}</td>
            </tr>
            <tr>
              <td>Jumlah</td>
              <td>{addOn.amount ? `Rp ${convertToRupiah(addOn.amount || 0)}` : "-"}</td>
            </tr>
            <tr>
              <td>Deskripsi</td>
              <td>{addOn.description || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-lg-6">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>No. Pelanggan</td>
              <td>{addOn.customer?.customerNumber || "-"}</td>
            </tr>
            <tr>
              <td>Nama Pelanggan</td>
              <td>{addOn.customer?.name || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
