import { useEmployeeHooks } from "../../hooks/employee/EmployeeHooks";

const filter = { showAll: true };

export default function EmployeeSelect({ value, onChange, disabled, error, ...rest }) {
  const { employees, statusFetch } = useEmployeeHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChange(event.target.value)}
        {...rest}
      >
        <option value="">-- Pilih Karyawan --</option>
        {employees.map((employee) => (
          <option key={employee.id} value={employee.id}>
            {employee.firstName} {employee.lastName}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
