import { apiAuth } from "./base";

export function getCustomersStats() {
  let api = apiAuth();
  return api.get("v1/dashboard/customer-summary");
}

export function getPreCustomersSummary(params) {
  let api = apiAuth();
  return api.get("v1/dashboard/pre-customer-summary", { params });
}

export function getFinancialSummary(params) {
  let api = apiAuth();
  return api.get("v1/dashboard/financial-summary", { params });
}

export function syncLicence() {
  let api = apiAuth();
  return api.post("v1/dashboard/sync-licence");
}
