import { useEffect, useRef, useState } from "react";
import { useCustomersHooks } from "../../hooks/customer/CustomerHooks";
import useDebounce from "../../hooks/useDebounceHooks";

export default function CustomerAutoComplete({
  value,
  error,
  onCancelInput,
  activeOnly,
  onChange,
}) {
  const [filter, setFilter] = useState({ q: "", limit: 5, activationStatus: activeOnly || "" });
  const wrapperRef = useRef(null);

  const { customers, statusFetch } = useCustomersHooks({ filter });
  const [selected, setSelected] = useState(null);
  const [focus, setFocus] = useState(false);
  const [listVisible, setListVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilter((prev) => ({
        ...prev,
        q: debouncedSearchTerm,
      }));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setListVisible(false);
    }
  };

  const handleFocus = () => {
    setFocus(!focus);
    setListVisible(!listVisible);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const onSelectList = (value) => {
    setSelected(value);
    onChange(value.id);
    setListVisible(false);
  };

  const onCancelSelected = () => {
    setSelected(null);
    onChange("");
    setSearchTerm("");
    onCancelInput();
  };

  return (
    <div className="select-autocomplete--wrapper" ref={wrapperRef}>
      {selected === null ? (
        <input
          type="text"
          className={`form-control ${error && "is-invalid"}`}
          placeholder="Ketik nama pelanggan ..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              disabled
              value={`${selected.customerNumber} ${selected.name}`}
            />
          </div>
          <div className="col-auto">
            <button className="btn btn-danger" onClick={() => onCancelSelected()}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      )}
      {error && <span className="d-block invalid-feedback">{error}</span>}
      {listVisible && (
        <ul className="select-autocomplete--list">
          {statusFetch === "success" && customers.length === 0 && (
            <li className="text-gray-700 text-center">Tidak ada data</li>
          )}
          {statusFetch === "loading" && <li className="text-gray-700 text-center">Loading ...</li>}
          {statusFetch === "success" &&
            customers.map((customer) => (
              <li
                className="clickable"
                onClick={() => onSelectList(customer)}
                role="button"
                key={customer.id}
              >
                {customer.customerNumber} {customer.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
