import { useRegionHooks } from "../../hooks/region/RegionHooks";

const filter = { showAll: true };

export default function RegionSelect({ value, onChange, disabled, error, ...rest }) {
  const { regions, statusFetch } = useRegionHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChange(event.target.value)}
        {...rest}
      >
        <option value="">-- Pilih Wilayah --</option>
        {regions.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
