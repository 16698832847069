import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Toggle from "react-toggle";
import { updateRouterById } from "../../api/routerDevice";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useRouterDeviceByIdHooks } from "../../hooks/router/RouterDeviceHooks";
import AlertBox from "../AlertBox";
import { InputSelectWrapper, InputText, RequiredAsterisk } from "../FormHelper";
import VpnSelect from "../vpn/VpnSelect";
import ProfileSelect from "../internetPackages/ProfileSelect";
import { RouterOsVersionConstant } from "../../constant/RouterConstant";

export default function ModalUpdateRouter({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <FormUpdateRouter onSuccess={onSuccess} onClose={onClose} id={id} />
    </Modal>
  );
}

function FormUpdateRouter({ id, onSuccess, onClose }) {
  const { router, statusFetch } = useRouterDeviceByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    ip: "",
    vpnId: "",
    username: "",
    password: "",
    port: "",
    osVersion: "",
    autoIsolate: false,
    isolateAction: "",
    isolateProfile: "",
  });
  const [errorForm, setErrorForm] = useState({
    name: "",
    ip: "",
    vpnId: "",
    username: "",
    password: "",
    port: "",
    osVersion: "",
    autoIsolate: "",
    isolateAction: "",
    isolateProfile: "",
  });
  const [usingVpn, setUsingVpn] = useState(false);

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        name: router.name,
        ip: router.ip,
        vpnId: router.vpn?.id || "",
        username: router.username,
        password: router.password,
        port: router.port,
        osVersion: router.osVersion || "",
        autoIsolate: router.autoIsolate || false,
        isolateAction: router.isolateAction || "",
        isolateProfile: router.isolateProfile || "",
      }));
    }

    if (!!router.vpn) {
      setUsingVpn(true);
    }
  }, [router, statusFetch]);

  const toggleVpn = (event) => {
    if (event.target.checked) {
      setForm({ ...form, ip: "" });
    } else {
      setForm({ ...form, vpnId: "" });
    }
    setUsingVpn(event.target.checked);
  };

  const onSubmitUpdateRegion = (event) => {
    event.preventDefault();
    submitUpdate(form);
  };

  const submitUpdate = async (form) => {
    setLoading(true);
    try {
      let { data } = await updateRouterById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Router</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitUpdateRegion}>
          <AlertBox {...alert} setAlert={setAlert} />
          {statusFetch === "loading" && <div className="alert alert-warning">Loading...</div>}
          <div className="form-group">
            <label>
              Nama Router <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="form-group d-flex items-center">
            <label>Gunakan IP VPN</label>
            <div className="ml-2">
              <Toggle checked={usingVpn} onChange={(event) => toggleVpn(event)} />
            </div>
          </div>
          {!usingVpn ? (
            <div className="form-group">
              <label>
                IP Publik Router <RequiredAsterisk />
              </label>
              <InputText
                type="text"
                required
                value={form.ip}
                onChange={(event) => setForm({ ...form, ip: event.target.value })}
                error={errorForm.ip}
              />
            </div>
          ) : (
            <div className="form-group">
              <label>
                IP VPN <RequiredAsterisk />
              </label>
              <VpnSelect
                value={form.vpnId}
                onChange={(value) => setForm({ ...form, vpnId: value })}
              />
            </div>
          )}
          <div className="form-group">
            <label>
              Username Mikrotik <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.username}
              onChange={(event) => setForm({ ...form, username: event.target.value })}
              error={errorForm.username}
            />
          </div>
          <div className="form-group">
            <label>
              Password Mikrotik
              <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.password}
              onChange={(event) => setForm({ ...form, password: event.target.value })}
              error={errorForm.password}
            />
          </div>
          <div className="form-group">
            <label>
              Port Mikrotik <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.port}
              onChange={(event) => setForm({ ...form, port: event.target.value })}
              error={errorForm.port}
            />
          </div>
          <div className="form-group">
            <label>
              Mikrotik RouterOS Version <RequiredAsterisk />
            </label>
            <InputSelectWrapper
              type="text"
              required
              value={form.osVersion}
              onChange={(event) => setForm({ ...form, osVersion: event.target.value })}
              error={errorForm.osVersion}
            >
              <option value="">-- Pilih Versi Router OS --</option>
              <option value=">=7.10">{RouterOsVersionConstant[">=7.10"]}</option>
              <option value="<7.10">{RouterOsVersionConstant["<7.10"]}</option>
            </InputSelectWrapper>
          </div>
          <div className="form-group">
            <label>
              Auto Isolir <RequiredAsterisk />
            </label>
            <InputSelectWrapper
              type="text"
              required
              value={form.autoIsolate}
              onChange={(event) =>
                setForm({
                  ...form,
                  autoIsolate: event.target.value === "true",
                  isolateAction: "",
                  isolateProfile: "",
                })
              }
              error={errorForm.autoIsolate}
            >
              <option value="true">Aktif</option>
              <option value="false">Tidak Aktif</option>
            </InputSelectWrapper>
          </div>
          <div className="form-group">
            <label>Metode Isolir</label>
            <InputSelectWrapper
              type="text"
              required
              value={form.isolateAction}
              onChange={(event) =>
                setForm({ ...form, isolateAction: event.target.value, isolateProfile: "" })
              }
              error={errorForm.isolateAction}
              disabled={form.autoIsolate === false}
            >
              <option value="">-- Pilih Metode --</option>
              <option value="disable">Disable Secret</option>
              <option value="change profile">Change Profile</option>
            </InputSelectWrapper>
          </div>
          <div className="form-group">
            <label>Profile Isolir</label>
            <ProfileSelect
              value={form.isolateProfile}
              routerId={id}
              onChange={(value) => setForm({ ...form, isolateProfile: value })}
              error={errorForm.isolateProfile}
              disabled={form.isolateAction !== "change profile"}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-block btn-primary"
              disabled={loading || statusFetch === "loading"}
            >
              {loading ? "Menyimpan perubahan ..." : "Simpan Perubahan Router"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
