import { apiAuth } from "./base";

export function getODPList(params) {
  let api = apiAuth();
  return api.get("v1/odps", { params });
}

export function createODP(data) {
  let api = apiAuth();
  return api.post("v1/odps", data);
}

export function getODPById(id) {
  let api = apiAuth();
  return api.get(`v1/odps/${id}`);
}

export function updateODPById(id, data) {
  let api = apiAuth();
  return api.put(`v1/odps/${id}`, data);
}

export function deleteODPById(id) {
  let api = apiAuth();
  return api.delete(`v1/odps/${id}`);
}
