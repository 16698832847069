import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import { useActionControl } from "../../utils/ActionControlUtils";
import { useTicketsHooks } from "../../hooks/ticket/TicketHooks";
import Pagination from "../../components/Pagination";
import ModalDeleteTicket from "../../components/ticket/ModalDeleteTicket";
import moment from "moment";
import ModalProgressTicket from "../../components/ticket/ModalProgressTicket";
import ModalCreateTicket from "../../components/ticket/ModalCreateTicket";

export default function TicketManagePage() {
  const { allowedByPermission } = useActionControl();

  const [formFilter, setFormFilter] = useState({ q: "", status: "" });
  const [filter, setFilter] = useState({ q: "", status: "", page: 1, limit: 10 });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { tickets, meta, statusFetch, errorMsg } = useTicketsHooks({ filter });

  const applyFilter = (event) => {
    event.preventDefault();

    setFilter({ ...filter, ...formFilter });
  };

  const resetFilter = () => {
    setFormFilter({ q: "", status: "" });
    setFilter({ q: "", status: "", page: 1, limit: 10 });
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const onSuccessUpdate = (message) => {
    setAlert({ show: true, type: "success", message });
  };

  const onCloseModalUpdate = () => {
    setModal(null);
    setFilter({ ...filter });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout permission_key={"ticket"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Tiket Bantuan</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-ticket")}>
                  <i className="fa fa-plus"></i> Buat Tiket Baru
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Tiket Bantuan" loading={statusFetch === "loading"}>
          <form onSubmit={applyFilter}>
            <div className="row">
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama / No. Pelanggan / No. Telepon"
                    value={formFilter.q}
                    onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    value={formFilter.status}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, status: event.target.value })
                    }
                  >
                    <option value="">Semua</option>
                    <option value="open">Open</option>
                    <option value="working">Working</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-2">
                <div className="form-group" style={{ marginTop: "32px" }}>
                  <button type="submit" className="btn btn-primary mr-2">
                    Filter
                  </button>
                  <button type="button" className="btn btn-light" onClick={() => resetFilter()}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Tiket</th>
                  <th>Status</th>
                  <th>Tanggal Dibuat</th>
                  <th>Nama Pelanggan</th>
                  <th>No Telp</th>
                  <th>Detail Masalah</th>
                </tr>
              </thead>
              <tbody>
                {tickets.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada Data
                    </td>
                  </tr>
                )}
                {tickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td>
                      {allowedByPermission("update") && (
                        <button
                          className="btn btn-sm btn-custom-purple btn-block text-nowrap"
                          onClick={() => selectModal("update-ticket", ticket.id)}
                        >
                          <i className="fa fa-screwdriver-wrench"></i> Aksi Tiket
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-sm btn-block btn-danger text-nowrap"
                          onClick={() => selectModal("delete-ticket", ticket.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                    <td>{ticket.ticketNumber}</td>
                    <td>
                      {ticket.status === "open" && <span className="badge badge-danger">open</span>}
                      {ticket.status === "working" && (
                        <span className="badge badge-warning">working</span>
                      )}
                      {ticket.status === "closed" && (
                        <span className="badge badge-success">closed</span>
                      )}
                    </td>
                    <td>{moment.unix(ticket.createdAt).format("DD MMMM YYYY HH:mm")}</td>
                    <td>{ticket.customer?.name || "-"}</td>
                    <td>{ticket.customer?.phoneNumber || "-"}</td>
                    <td>{ticket.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreateTicket
        show={modal === "new-ticket"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />

      <ModalProgressTicket
        show={modal === "update-ticket"}
        onSuccess={onSuccessUpdate}
        id={modal === "update-ticket" ? selectedId : null}
        onClose={() => onCloseModalUpdate()}
      />

      <ModalDeleteTicket
        show={modal === "delete-ticket"}
        onSuccess={onSuccess}
        id={modal === "delete-ticket" ? selectedId : null}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}
