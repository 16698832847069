import { LoadingInsideBox } from "./Loading";

function Box(props) {
  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">{props.title}</h6>
        </div>
        <div className="card-body" style={{ minHeight: "100px" }}>
          {props.loading && <LoadingInsideBox />}
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Box;
