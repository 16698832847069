import { useVpnListHooks } from "../../hooks/vpn/VpnHooks";

const filter = { showAll: true };

export default function VpnSelect({ value, onChange, disabled, error, ...rest }) {
  const { vpnList, statusFetch } = useVpnListHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChange(event.target.value)}
        {...rest}
      >
        <option value="">-- Pilih IP VPN --</option>
        {vpnList.map((vpn) => (
          <option key={vpn.id} value={vpn.id}>
            {vpn.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
