import { useBankListHooks } from "../../hooks/bank/BankHooks";

const filter = { showAll: true };

export default function BankSelect({ value, onChange, disabled, error, ...rest }) {
  const { banks, statusFetch } = useBankListHooks({ filter });
  return (
    <>
      <select
        className={`form-control ${!!error && "is-invalid"}`}
        value={value}
        disabled={!!disabled || statusFetch === "loading"}
        onChange={(event) => onChange(event.target.value)}
        {...rest}
      >
        <option value="">-- Pilih Rekening Bank --</option>
        {banks.map((bank) => (
          <option key={bank.id} value={bank.id}>
            {bank.name} - {bank.accountNumber} - {bank.accountName}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
