import { useState } from "react";
import { fileUploader } from "../api/uploader";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";

export function RequiredAsterisk() {
  return <span className="text-danger">*</span>;
}

export function InputText({ type, value, onChange, required, error, ...rest }) {
  return (
    <>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={`form-control ${error ? "is-invalid" : ""}`}
        required={required}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}

export function InputPassword({ value, onChange, error, ...rest }) {
  let [visibility, setVisibility] = useState(false);

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <button
          type="button"
          onClick={() => setVisibility(!visibility)}
          className="btn btn-primary"
        >
          {visibility ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
        </button>
      </div>
      <input
        type={visibility === false ? "password" : "text"}
        value={value}
        onChange={onChange}
        className={`form-control ${error ? "is-invalid" : ""}`}
        {...rest}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

export function InputFileUploader({ value, label, disabled, onChange, error }) {
  const [file, setFile] = useState("");
  const [statusUpload, setStatusUplaod] = useState(null);
  const [errorUpload, setErrorUpload] = useState("");

  const uploadFile = async (formData) => {
    setStatusUplaod("uploading");
    setErrorUpload("");
    try {
      let { data } = await fileUploader(formData);
      onChange(data.data.url);
      setStatusUplaod("success");
    } catch (error) {
      let message = ClientErrorResponseMessage.SomethingWrong;

      if (error.response) {
        let { data } = error.response;
        message = data.message;
      }

      setStatusUplaod("failed");
      setErrorUpload(message);
    }
  };

  const onChangeImage = (event) => {
    let formData = new FormData();
    setFile(event.target.files[0]);
    formData.append("file", event.target.files[0]);
    uploadFile(formData);
  };

  return (
    <div className="row">
      <div className="col-auto">
        <img
          src={value ? value : "https://via.placeholder.com/600x400.jpg?text=Tidak+Ada+Gambar"}
          className="img-thumbnail"
          style={{ width: "200px" }}
          alt={label || "image"}
        />
      </div>
      <div className="col d-flex flex-column justify-content-end">
        {label && <label>{label}</label>}
        <div className={`custom-file ${errorUpload ? "is-invalid" : ""}`}>
          <input
            type="file"
            className={`custom-file-input ${errorUpload ? "is-invalid" : ""} ${
              statusUpload === "success" ? "is-valid" : ""
            }`}
            disabled={disabled || statusUpload === "uploading"}
            onChange={(event) => onChangeImage(event)}
          />
          <label className="custom-file-label">{file ? file.name : "Pilih file ..."}</label>
        </div>
        {statusUpload === "uploading" && (
          <div className="alert alert-warning">Menunggah gambar</div>
        )}
        {statusUpload === "success" && (
          <span className="text-sm text-success">
            <i className="fa fa-check"></i> Sukses mengunggah gambar
          </span>
        )}
        {errorUpload && <span className="invalid-feedback">{errorUpload}</span>}
      </div>
    </div>
  );
}

export function InputSelectWrapper({ type, value, onChange, required, error, children, ...rest }) {
  return (
    <>
      <select
        type={type}
        value={value}
        onChange={onChange}
        className={`form-control ${error ? "is-invalid" : ""}`}
        required={required}
        {...rest}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
