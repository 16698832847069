import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createCustomer } from "../../api/customer";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import AlertBox from "../AlertBox";
import { InputPassword, InputSelectWrapper, InputText, RequiredAsterisk } from "../FormHelper";
import InternetPackageWithRouterSelect from "../internetPackages/InternetPackageWithRouterSelect";
import OdpSelect from "../odp/OdpSelect";
import RegionSelect from "../region/RegionSelect";
import RouterSecretSelect from "../router/RouterSecretSelect";

export default function ModalCreateCustomer({ show, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Daftarkan Pelanggan Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCreateCustomer onSuccess={onSuccess} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

function FormCreateCustomer({ onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    phoneNumber: "",
    regionId: "",
    packageId: "",
    odpId: "",
    installationStatus: true,
    installationDate: "",
    address: "",
    routerId: "",
    profileRouter: "",
    useExistingSecrets: false,
    pppoeIpType: "",
    pppoeUsername: "",
    pppoePassword: "",
    pppoeLocalAddress: "",
    pppoeRemoteAddress: "",
  });

  const [errorForm, setErrorForm] = useState({
    name: "",
    phoneNumber: "",
    regionId: "",
    packageId: "",
    odpId: "",
    installationStatus: "",
    installationDate: "",
    address: "",
    useExistingSecrets: "",
    pppoeUsername: "",
    pppoePassword: "",
    pppoeIpType: "",
    pppoeLocalAddress: "",
    pppoeRemoteAddress: "",
  });

  const onChangePPPSecretsType = (value) => {
    let formData = { ...form };

    // use existing
    formData.useExistingSecrets = value;
    // reset data ppoe
    formData.pppoeUsername = "";
    formData.pppoePassword = "";
    formData.pppoeIpType = "";
    formData.pppoeLocalAddress = "";
    formData.pppoeRemoteAddress = "";

    setForm({ ...formData });
  };

  const onChangeSecrets = (value = {}) => {
    let secretData = {
      pppoeUsername: value.name,
      pppoePassword: value.password,
      pppoeIpType: "",
      pppoeLocalAddress: "",
      pppoeRemoteAddress: "",
    };

    if (value["remote-address"] && value["local-address"]) {
      secretData.pppoeIpType = "remote address";
      secretData.pppoeLocalAddress = value["local-address"];
      secretData.pppoeRemoteAddress = value["remote-address"];
    } else {
      secretData.pppoeIpType = "ip pools";
    }

    setForm({ ...form, ...secretData });
  };

  const onSubmitNewCustomer = (event) => {
    event.preventDefault();

    let formData = {
      name: form.name,
      phoneNumber: form.phoneNumber,
      regionId: form.regionId,
      packageId: form.packageId,
      odpId: form.odpId,
      installationStatus: form.installationStatus,
      installationDate: form.installationDate,
      address: form.address,
      useExistingSecrets: form.useExistingSecrets,
      pppoeIpType: form.pppoeIpType,
      pppoeUsername: form.pppoeUsername,
      pppoePassword: form.pppoePassword,
      pppoeLocalAddress: form.pppoeLocalAddress,
      pppoeRemoteAddress: form.pppoeRemoteAddress,
    };

    submitCustomer(formData);
  };

  const submitCustomer = async (form) => {
    setLoading(true);
    try {
      let { data } = await createCustomer(form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });
          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitNewCustomer}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>
          Nama Pelanggan <RequiredAsterisk />
        </label>
        <InputText
          type="text"
          required
          value={form.name}
          onChange={(event) => setForm({ ...form, name: event.target.value })}
          error={errorForm.name}
        />
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Alamat</label>
            <InputText
              type="text"
              value={form.address}
              onChange={(event) => setForm({ ...form, address: event.target.value })}
              error={errorForm.address}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              Wilayah <RequiredAsterisk />
            </label>
            <RegionSelect
              value={form.regionId}
              onChange={(value) => setForm({ ...form, regionId: value })}
              error={errorForm.regionId}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              No. Telepon <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.phoneNumber}
              onChange={(event) => setForm({ ...form, phoneNumber: event.target.value })}
              error={errorForm.phoneNumber}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              Paket <RequiredAsterisk />
            </label>
            <InternetPackageWithRouterSelect
              value={form.packageId}
              onChange={(value, routerId, profileRouter) =>
                setForm({
                  ...form,
                  packageId: value,
                  routerId: routerId,
                  profileRouter: profileRouter,
                  pppoeUsername: "",
                })
              }
              error={errorForm.packageId}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>ODP</label>
            <OdpSelect
              value={form.odpId}
              onChange={(value) => setForm({ ...form, odpId: value })}
              error={errorForm.odpId}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              Status Pemasangan <RequiredAsterisk />
            </label>
            <InputSelectWrapper
              type="text"
              required
              value={form.installationStatus}
              onChange={(event) =>
                setForm({ ...form, installationStatus: event.target.value === "true" })
              }
              error={errorForm.installationStatus}
            >
              <option value="false">Belum Pasang</option>
              <option value="true">Sudah Pasang</option>
            </InputSelectWrapper>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              Tanggal Pemasangan <RequiredAsterisk />
            </label>
            <InputText
              type="date"
              // required
              value={form.installationDate}
              disabled={!form.installationStatus}
              onChange={(event) => setForm({ ...form, installationDate: event.target.value })}
              error={errorForm.installationDate}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>PPP Secrets</label>
            <InputSelectWrapper
              className="form-control"
              value={form.useExistingSecrets}
              onChange={(event) => onChangePPPSecretsType(event.target.value === "true")}
              error={errorForm.useExistingSecrets}
            >
              <option value="false">Buat Secrets Baru</option>
              <option value="true">Ambil dari Router</option>
            </InputSelectWrapper>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Username</label>
            {form.useExistingSecrets ? (
              <RouterSecretSelect
                value={form.pppoeUsername}
                selectSecret={(value) => onChangeSecrets(value)}
                routerId={form.routerId}
                profileRouter={form.profileRouter}
              />
            ) : (
              <InputText
                type="text"
                className="form-control"
                value={form.pppoeUsername}
                onChange={(event) => setForm({ ...form, pppoeUsername: event.target.value })}
                error={errorForm.pppoeUsername}
                disabled={form.useExistingSecrets}
                autoComplete="off"
              />
            )}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Password</label>
            <InputPassword
              className="form-control"
              value={form.pppoePassword}
              onChange={(event) => setForm({ ...form, pppoePassword: event.target.value })}
              error={errorForm.pppoePassword}
              disabled={form.useExistingSecrets}
              autoComplete="new-password"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Tipe IP</label>
            <InputSelectWrapper
              className="form-control"
              value={form.pppoeIpType}
              onChange={(event) => setForm({ ...form, pppoeIpType: event.target.value })}
              disabled={form.useExistingSecrets}
              error={errorForm.pppoeIpType}
            >
              <option value="">-- Pilih Tipe IP --</option>
              <option value="ip pools">IP Pools</option>
              <option value="remote address">Remote Address</option>
            </InputSelectWrapper>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Local Address</label>
            <InputText
              type="text"
              value={form.pppoeLocalAddress}
              onChange={(event) => setForm({ ...form, pppoeLocalAddress: event.target.value })}
              disabled={form.pppoeIpType === "ip pools" || form.useExistingSecrets}
              error={errorForm.pppoeLocalAddress}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>Remote Address</label>
            <InputText
              type="text"
              value={form.pppoeRemoteAddress}
              onChange={(event) => setForm({ ...form, pppoeRemoteAddress: event.target.value })}
              disabled={form.pppoeIpType === "ip pools" || form.useExistingSecrets}
              error={errorForm.pppoeRemoteAddress}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <button className="btn btn-block btn-lg btn-primary" disabled={loading}>
          {loading ? "Membuat pelanggan baru ..." : "Daftarkan Pelanggan Baru"}
        </button>
      </div>
    </form>
  );
}
