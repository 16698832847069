import { apiAuth } from "./base";

export function getProfile() {
  let api = apiAuth();
  return api.get("v1/user/profile");
}

export function updateProfile(data) {
  let api = apiAuth();
  return api.put("v1/user/profile", data);
}

export function updateAccountPassword(data) {
  let api = apiAuth();
  return api.put("v1/user/setting/password", data);
}

export function getTenant() {
  let api = apiAuth();
  return api.get("v1/user/tenant");
}

export function updateTenant(data) {
  let api = apiAuth();
  return api.put("v1/user/setting/tenant", data);
}

export function getPermissionAction(params) {
  let api = apiAuth();
  return api.get("v1/user/permissions/menu", { params });
}
