import { useContext, createContext, useState, useCallback } from "react";
import { getPermissionAction } from "../api/user";
import { ClientErrorResponseMessage } from "../constant/ErrorResponseConstants";
import { useAuth } from "./AuthHooks";

export const ActionControlContext = createContext(null);

export function ActionControlProvider({ children }) {
  const [permissionKey, setPermissionKey] = useState("");
  const [permission, setPermission] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [statusFetch, setStatusFetch] = useState("");

  const { user } = useAuth();

  const allowedByPermission = useCallback(
    (operation) => {
      if (user.role?.superuser) {
        return true;
      }

      if (statusFetch === "success") {
        // console.log(
        //   `key: ${permissionKey}:${operation}`,
        //   permission[permissionKey + ":" + operation]
        // );
        return permission[permissionKey + ":" + operation] || false;
      } else {
        return null;
      }
    },
    [permission, permissionKey, statusFetch, user.role?.superuser]
  );

  const fetchPermission = useCallback(async (permission_key) => {
    setStatusFetch("loading");
    setPermission({});
    setPermissionKey(permission_key);
    setErrorMsg("");
    try {
      const { data } = await getPermissionAction({ key: permission_key });
      let operation = {};

      data.data.forEach((permissionData) => {
        Object.keys(permissionData.operation).forEach((key) => {
          operation[permission_key + ":" + key] = permissionData.operation[key];
        });
      });

      setPermission(operation);
      setStatusFetch("success");
    } catch (error) {
      console.log(error);
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg(ClientErrorResponseMessage.SomethingWrong);
      }
      setStatusFetch("failure");
    }
  }, []);

  const ctxValue = {
    permission,
    statusFetch,
    errorMsg,
    fetchPermission,
    allowedByPermission,
  };

  return <ActionControlContext.Provider value={ctxValue}>{children}</ActionControlContext.Provider>;
}

export function useActionControl() {
  return useContext(ActionControlContext);
}
