import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { InputText, RequiredAsterisk } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { createODP } from "../../api/odp";
import RouterSelect from "./RouterSelect";
import MapSelector from "./MapSelector";

export default function ModalCreateOdp({ show, onSuccess, onClose }) {
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    capacity: "",
    routerId: "",
    latitude: "",
    longitude: "",
  });
  const [errorForm, setErrorForm] = useState({
    name: "",
    capacity: "",
    routerId: "",
    latitude: "",
    longitude: "",
  });

  const handleClose = () => {
    setForm({ name: "", capacity: "", routerId: "", latitude: "", longitude: "" });
    setErrorForm({ name: "", capacity: "", routerId: "", latitude: "", longitude: "" });
    setAlert({ show: false, type: "", message: "" });
    onClose();
  };

  const selectLocation = (marker) => {
    setForm({ ...form, latitude: marker.lat, longitude: marker.lng });
  };

  const onSubmitNewOdp = (event) => {
    event.preventDefault();
    submitOdp(form);
  };

  const submitOdp = async (form) => {
    setLoading(true);
    try {
      let { data } = await createODP(form);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Buat ODP Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={onSubmitNewOdp}>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>
                  Nama ODP <RequiredAsterisk />
                </label>
                <InputText
                  type="text"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  error={errorForm.name}
                />
              </div>
              <div className="form-group">
                <label>
                  Kapasitas <RequiredAsterisk />
                </label>
                <InputText
                  type="text"
                  required
                  value={form.capacity}
                  onChange={(event) => setForm({ ...form, capacity: event.target.value })}
                  error={errorForm.capacity}
                />
              </div>
              <div className="form-group">
                <label>
                  Router <RequiredAsterisk />
                </label>
                <RouterSelect
                  value={form.routerId}
                  onChange={(value) => setForm({ ...form, routerId: value })}
                  error={errorForm.routerId}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>Latitude</label>
                <InputText
                  type="text"
                  value={form.latitude}
                  // onChange={(event) => setForm({ ...form, latitude: event.target.value })}
                  disabled
                  error={errorForm.latitude}
                />
              </div>
              <div className="form-group">
                <label>Longitude</label>
                <InputText
                  type="text"
                  value={form.longitude}
                  // onChange={(event) => setForm({ ...form, longitude: event.target.value })}
                  disabled
                  error={errorForm.longitude}
                />
              </div>
              <div className="form-group">
                <MapSelector
                  latitude={form.latitude}
                  longitude={form.longitude}
                  selectLocation={(value) => selectLocation(value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Membuat odp baru ..." : "Buat ODP Baru"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
