import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export function formatterBps(number) {
  if (number >= 1000000) {
    // If the value is sufficient for conversion to Gbps
    return (number / 1000000).toFixed(2) + " G";
  } else if (number >= 1000) {
    // If the value is sufficient for conversion to Mbps
    return (number / 1000).toFixed(2) + " M";
  } else {
    // If the value remains in kbps
    return number + " k";
  }
}

export default function RouterTrafficGraph({ data }) {
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis tickFormatter={formatterBps} />
          <Tooltip />
          <Legend />
          <Line
            isAnimationActive={false}
            dataKey="rxKbps"
            stroke="#4e73df"
            dot={{ r: 2, strokeWidth: 4, fill: "#4e73df" }}
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
          <Line
            isAnimationActive={false}
            dataKey="txKbps"
            stroke="#e74a3b"
            dot={{ r: 2, strokeWidth: 4, fill: "#e74a3b" }}
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
