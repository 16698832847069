import { apiAuth } from "./base";

export function getPermissionSchema() {
  let api = apiAuth();
  return api.get("v1/permissions/default-schema");
}

export function getPermissionMenu(params) {
  let api = apiAuth();
  return api.get("v1/user/permissions/menu", { params });
}
