import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { InputText, RequiredAsterisk } from "../FormHelper";
import { ClientErrorResponseMessage, ErrorResponse } from "../../constant/ErrorResponseConstants";
import { useRegionByIdHooks } from "../../hooks/region/RegionHooks";
import { updateRegionById } from "../../api/region";

export default function ModalUpdateRegion({ show, id, onSuccess, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <FormUpdateRegion onSuccess={onSuccess} onClose={onClose} id={id} />
    </Modal>
  );
}

function FormUpdateRegion({ id, onSuccess, onClose }) {
  const { region, statusFetch } = useRegionByIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({ name: "" });

  const [errorForm, setErrorForm] = useState({ name: "" });

  useEffect(() => {
    if (statusFetch === "success") {
      setForm((prev) => ({
        ...prev,
        name: region.name,
      }));
    }
  }, [region, statusFetch]);

  const onSubmitUpdateRegion = (event) => {
    event.preventDefault();
    submitUpdate(form);
  };

  const submitUpdate = async (form) => {
    setLoading(true);
    try {
      let { data } = await updateRegionById(id, form);
      onSuccess(data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;

        if (data.error.type === ErrorResponse.ValidationError) {
          let errorTemp = {};
          data.error.details.forEach((field) => {
            errorTemp[field.field] = field.message;
          });

          setErrorForm({ ...errorTemp });
        }

        message = data.message;
      } else {
        message = ClientErrorResponseMessage.SomethingWrong;
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Wilayah {region.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitUpdateRegion}>
          <AlertBox {...alert} setAlert={setAlert} />
          {statusFetch === "loading" && <div className="alert alert-warning">Loading...</div>}
          <div className="form-group">
            <label>
              Nama Wilayah <RequiredAsterisk />
            </label>
            <InputText
              type="text"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
              error={errorForm.name}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" disabled={loading}>
              {loading ? "Menyimpan perubahan ..." : "Simpan Perubahan Wilayah"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
