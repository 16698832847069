import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useEmployeeHooks } from "../../hooks/employee/EmployeeHooks";
import ModalCreateEmployee from "../../components/employee/ModalCreateEmployee";
import ModalUpdateEmployee from "../../components/employee/ModalUpdateEmployee";
import Pagination from "../../components/Pagination";
import ModalDeleteEmployee from "../../components/employee/ModalDeleteEmployee";
import { useActionControl } from "../../utils/ActionControlUtils";

export default function EmployeeManagePage() {
  const { allowedByPermission } = useActionControl();

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const { employees, statusFetch, meta, errorMsg } = useEmployeeHooks({ filter });

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, type: "success", message });
  };

  const selectModal = (modalName, id) => {
    setModal(modalName);
    setSelectedId(id);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout permission_key={"employee:data"}>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Kelola Karyawan</h1>
            </div>
            <div className="col-auto">
              {allowedByPermission("create") && (
                <button className="btn btn-primary" onClick={() => setModal("new-employee")}>
                  <i className="fa fa-plus"></i> Tambah Karyawan
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Data Karyawan" loading={statusFetch === "loading"}>
          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Nama</th>
                  <th>No. HP</th>
                  <th>Jabatan</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {employees.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {employees.map((account) => (
                  <tr key={account.id}>
                    <td>{account.username}</td>
                    <td>
                      {account.firstName} {account.lastName}
                    </td>
                    <td>{account.phoneNumber}</td>
                    <td>{account.role?.name || "-"}</td>
                    <td>{account.email}</td>
                    <td>
                      {account.active && <span className="badge badge-success">aktif</span>}
                      {!account.active && <span className="badge badge-danger">TIdak Aktif</span>}
                    </td>
                    <td>
                      {allowedByPermission("update") && (
                        <button
                          className="btn btn-sm btn-block btn-info"
                          onClick={() => selectModal("update-employee", account.id)}
                        >
                          <i className="fa fa-pencil"></i> Edit
                        </button>
                      )}
                      {allowedByPermission("delete") && (
                        <button
                          className="btn btn-sm btn-block btn-danger text-nowrap"
                          onClick={() => selectModal("delete-employee", account.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {meta.count > meta.limit && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.limit)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreateEmployee
        show={modal === "new-employee"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />
      <ModalUpdateEmployee
        show={modal === "update-employee"}
        onSuccess={onSuccess}
        id={modal === "update-employee" ? selectedId : null}
        onClose={() => setModal(null)}
      />
      <ModalDeleteEmployee
        show={modal === "delete-employee"}
        onSuccess={onSuccess}
        id={modal === "delete-employee" ? selectedId : null}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}
