import { useState } from "react";
import { usePreCustomersStatsHook } from "../../hooks/DashboardHooks";
import { convertToRupiah } from "../../tools/numerictools";
import Box from "../Box";
import Pagination from "../Pagination";

const PRECOSTUMER_TYPE = {
  "pre-customer": "Belum Pasang & Aktif",
  "not-installed": "Belum Pasang",
  "not-activated": "Belum aktif",
};

export default function BoxPreCustomers() {
  const [filter, setFilter] = useState({ page: 1, limit: 5 });
  const { customers, statusFetch, meta, errorMsg } = usePreCustomersStatsHook({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <Box title="Pelanggan yang belum terpasang dan belum aktif" loading={statusFetch === "loading"}>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      <div style={{ overflow: "auto" }}>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Status</th>
              <th>Nama</th>
              <th>Alamat</th>
              <th>No. Telp</th>
              <th>Wilayah</th>
              <th>Paket</th>
              <th>Tarif</th>
            </tr>
          </thead>
          <tbody>
            {customers.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
            {customers.map((customer, index) => (
              <tr key={index}>
                <td className="text-nowrap">
                  <BadgeStatus
                    status={customer.status}
                    value={PRECOSTUMER_TYPE[customer.status] || "-"}
                  />
                </td>
                <td>{customer.name}</td>
                <td>{customer.address || "-"}</td>
                <td>{customer.phoneNumber}</td>
                <td className="text-nowrap">{customer.region?.name || "-"}</td>
                <td className="text-nowrap">{customer.package?.name || "-"}</td>
                <td className="text-nowrap">Rp {convertToRupiah(customer.package?.price || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {meta.count > meta.limit && (
        <Pagination
          totalpage={Math.ceil(meta.count / meta.limit)}
          selectedpage={meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(meta.page + 1)}
          prev={() => gotoPage(meta.page - 1)}
        />
      )}
    </Box>
  );
}

function BadgeStatus({ status, value }) {
  const PRECOSTUMER_BADGE_COLOR = {
    "pre-customer": "danger",
    "not-installed": "light",
    "not-activated": "warning",
  };

  return <span className={`badge badge-${PRECOSTUMER_BADGE_COLOR[status]}`}>{value}</span>;
}
