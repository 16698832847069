import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import {
  useRouterDeviceByIdHooks,
  useRouterInterfaceByIdHooks,
} from "../../hooks/router/RouterDeviceHooks";
import { getRouterInterfacesTrafficById } from "../../api/routerDevice";
import RouterTrafficGraph from "../../components/router/RouterTrafficGraph";
import { convertSpeedWithUnit } from "../../tools/numerictools";

function BackToRouterLink() {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/router`}>
            <i className="fa-regular fa-circle-left"></i> Kembali ke Halaman Router
          </Link>
        </li>
      </ol>
    </nav>
  );
}

export default function RouterTrafficPage() {
  const { id } = useParams();
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const [iface, setIface] = useState(null);
  const { router, statusFetch, errorMsg } = useRouterDeviceByIdHooks({ id });

  return (
    <MainLayout permission_key={"router"}>
      <div className="container-fluid">
        <BackToRouterLink />
        <Box title="Traffic Router" loading={statusFetch === "loading"}>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Nama Router</th>
                <th>IP Router</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{router.name || "-"}</td>
                <td>
                  {router.vpn ? (
                    <>
                      {router.vpn?.ipAddress}{" "}
                      <span
                        className={`badge badge-${router.vpn?.status ? "success" : "secondary"}`}
                      >
                        vpn
                      </span>
                    </>
                  ) : (
                    router.ip || "-"
                  )}
                </td>
                <td>
                  {router.status ? (
                    <span className="badge badge-success">Terhubung</span>
                  ) : (
                    <span className="badge badge-danger">Tidak Terhubung</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          {/* Interface Selector */}
          <InterfaceSelector id={id} selectIface={setIface} />
          {iface && <TrafficInterfaceGraph id={id} iface={iface} key={iface} />}
        </Box>
      </div>
    </MainLayout>
  );
}

function InterfaceSelector({ id, selectIface }) {
  const { routerInterfaces, statusFetch } = useRouterInterfaceByIdHooks({ id });

  const [input, setInput] = useState("");
  const onSubmitChangeIface = (event) => {
    event.preventDefault();
    selectIface(input);
  };

  return (
    <form onSubmit={onSubmitChangeIface}>
      <div className="row">
        <div className="col col-lg-3">
          <div className="form-group">
            <label className="font-weight-bold">Interfaces</label>
            <select
              className="form-control"
              disabled={statusFetch !== "success"}
              value={input}
              onChange={(event) => setInput(event.target.value)}
            >
              <option value="">-- Pilih Interface --</option>
              {routerInterfaces.map((routerIf, index) => (
                <option value={routerIf} key={index}>
                  {routerIf}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-auto col-lg" style={{ paddingTop: "32px" }}>
          <button className="btn btn-primary">Pilih Interface</button>
        </div>
      </div>
    </form>
  );
}

function TrafficInterfaceGraph({ id, iface }) {
  const [current, setCurrent] = useState({});
  const [traffic, setTraffic] = useState([]);

  useEffect(() => {
    const fetchTraffic = async () => {
      try {
        const { data } = await getRouterInterfacesTrafficById(id, { interfaces: iface });
        setCurrent(data.data);
        setTraffic((prevTraffic) => {
          if (prevTraffic.length < 20) {
            return [...prevTraffic, { ...data.data }];
          } else {
            let newArray = [...prevTraffic];
            newArray.shift();
            newArray.push(data.data);
            return newArray;
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const fetchWithInterval = setInterval(() => {
      if (iface) {
        fetchTraffic(); // <-- (3) invoke in interval callback
      }
    }, 5000);

    fetchTraffic(); // <-- (2) invoke on mount

    return () => clearInterval(fetchWithInterval);
  }, [id, iface]);

  return (
    <>
      <div className="row mb-4">
        <div className="col col-lg-auto">
          <div className="card">
            <div className="card-body">
              <div className="h5 mb-0 font-weight-bold text-gray-800 mb-2">
                <i className="fa-solid fa-ethernet text-info"></i> Interface
              </div>
              <span className="h4 font-weight-bold mb-1">{iface}</span>
            </div>
          </div>
        </div>
        <div className="col col-lg-auto">
          <div className="card">
            <div className="card-body">
              <div className="h5 mb-0 font-weight-bold text-gray-800 mb-2">
                {" "}
                <i className="fa-solid fa-angle-down text-primary"></i> Receive
              </div>
              <span className="h4 font-weight-bold mb-1">
                {convertSpeedWithUnit(current.rxKbps || 0)}
              </span>
            </div>
          </div>
        </div>
        <div className="col col-lg-auto">
          <div className="card">
            <div className="card-body">
              <div className="h5 mb-0 font-weight-bold text-gray-800 mb-2">
                <i className="fa-solid fa-angle-up text-danger"></i> Transfer
              </div>
              <span className="h4 font-weight-bold mb-1">
                {convertSpeedWithUnit(current.txKbps || 0)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <RouterTrafficGraph data={traffic} />
    </>
  );
}
